import sanitizeHtml from 'sanitize-html';

import { ITextOptions } from '../../utils/interfaces.ts';
import FotoscapeLink from '../../../../components/FotoscapeLink/FotoscapeLink.tsx';

export const postfixAndTrim = (text: string, start: number) => {
  return text.substring(start).trim();
};

export const processBlock = (text: string) => {
  let result = '';
  let anchorText = '';
  let anchorHRef = '';
  let resultOffset = 0;
  let state = 'normal';
  const len = text.length;

  for (let i = 0; i < len; i++) {
    const symbol = text[i];

    switch (state) {
      case 'normal':
        if (symbol === '^') {
          state = 'sawCaret';
        } else {
          result += symbol;
        }
        break;
      case 'sawCaret':
        if (symbol === '*') {
          result += '<b>';
          state = 'inCaret';
        } else if (symbol === '&') {
          result += '<i>';
          state = 'inCaret';
        } else if (symbol === '[') {
          anchorText = '';
          anchorHRef = '';
          resultOffset = result.length; // Start location for text.
          state = 'anchorText';
        } else {
          result += symbol;
          state = 'normal';
        }
        break;
      case 'inCaret':
        if (symbol === '^') {
          state = 'inCaretSawCaret';
        } else {
          result += symbol;
        }
        break;
      case 'inCaretSawCaret':
        if (symbol === '*') {
          result += '</b>';
          state = 'normal';
        } else if (symbol === '&') {
          result += '</i>';
          state = 'normal';
        } else {
          result += symbol;
          state = 'inCaret';
        }
        break;
      case 'anchorText':
        if (symbol === '^') {
          state = 'anchorTextSawCaret';
        } else {
          result += symbol;
        }
        break;
      case 'anchorTextSawCaret':
        if (symbol === '|') {
          anchorText = result.substring(resultOffset, result.length);
          result = result.substring(0, resultOffset);
          state = 'anchorBody';
        } else {
          result += '^' + symbol;
          state = 'anchorText';
        }
        break;
      case 'anchorBody':
        if (symbol === '^') {
          state = 'anchorBodySawCaret';
        } else {
          result += symbol;
        }
        break;
      case 'anchorBodySawCaret':
        if (symbol === ']') {
          anchorHRef = result.substring(resultOffset, result.length);
          result = result.substring(0, resultOffset);
          result +=
            '<a class="inline-link" href="' +
            anchorHRef +
            '">' +
            anchorText +
            '</a>';
          state = 'normal';
        } else {
          result += '^' + symbol;
          state = 'anchorBody';
        }
        break;
      default: {
        result += symbol;
      }
    }
  }
  return sanitizeHtml(result, {
    allowedClasses: {
      '*': ['*']
    }
  });
};

export const getCleanText = (text: string) => {
  return text.trim().replace(/\s{2,}/g, ' ');
};

export const getMarkdown = (text: string, options?: ITextOptions) => {
  const lines = text.split('\n');
  const componentList = [];

  for (let i = 0; i < lines.length; i++) {
    const cleanText = getCleanText(lines[i]);

    if (cleanText.startsWith('# ')) {
      componentList.push(
        <>
          <h1
            className="headline--s"
            key={cleanText.trim()}
            dangerouslySetInnerHTML={{
              __html: processBlock(postfixAndTrim(cleanText, 2)),
            }}
          ></h1>
          <hr />
        </>
      );
    } else if (cleanText.startsWith('## ')) {
      const className = options?.subHeaderFirstLevelClass
        ? options.subHeaderFirstLevelClass
        : 'headline--m';

      componentList.push(
        <h2
          className={className}
          key={cleanText.trim()}
          dangerouslySetInnerHTML={{
            __html: processBlock(postfixAndTrim(cleanText, 3)),
          }}
        ></h2>
      );
    } else if (cleanText.startsWith('### ')) {
      componentList.push(
        <>
          <h1
            className="headline--s"
            key={cleanText.trim()}
            dangerouslySetInnerHTML={{
              __html: processBlock(postfixAndTrim(cleanText, 4)),
            }}
          ></h1>
          <hr />
        </>
      );
    } else if (cleanText.startsWith('#### ')) {
      componentList.push(
        <h1
          key={cleanText.trim()}
          className="headline--m"
          dangerouslySetInnerHTML={{
            __html: processBlock(postfixAndTrim(cleanText, 5)),
          }}
        ></h1>
      );
    } else if (cleanText.startsWith('@credit ')) {
      componentList.push(
        <p
          key={i}
          className="credit"
          dangerouslySetInnerHTML={{
            __html: processBlock(postfixAndTrim(cleanText, 8)),
          }}
        ></p>
      );
    } else if (cleanText.startsWith('@hr')) {
      componentList.push(<hr key={i} />);
    } else if (cleanText.startsWith('@image(')) {
      const [tag, src, width, height] =
        cleanText.match(/^@image\(([^,]+),([^,]+),([^)]+)\)$/) || [];

      if (tag) {
        componentList.push(
          <img
            alt="content"
            key={src}
            src={src}
            width={width}
            height={height}
            className="image"
          />
        );
      }
    } else if (cleanText.startsWith('@link(')) {
      const found2 = cleanText.match(/^@link\(([^)]+)\)\s+(.+)$/);
      const link = found2?.length ? found2[1] : '';
      if (found2 !== null) {
        componentList.push(
          <FotoscapeLink key={link} to={link} className="source_link">
            {found2[2]}
          </FotoscapeLink>
        );
      }
    } else if (cleanText.startsWith('@cta(')) {
      const [tag, href, text] =
        cleanText.match(/^@cta\(([^)]+)\)\s+(.+)$/) || [];

      if (tag) {
        componentList.push(
          <FotoscapeLink key={href} to={href} className="button">
            {text}
          </FotoscapeLink>
        );
      }
    } else if (cleanText.startsWith('@sp')) {
      componentList.push(<p key={i}>&nbsp;</p>);
    } else if (cleanText.startsWith('@ad')) {
      const mackResult = cleanText.match(/^@ad\(([^)]+)\)/) || [];
      const slotID = mackResult[1];

      if (slotID) {
        componentList.push(
          <div className="ad-wrapper" key={slotID}>
            <div id={`ad_unit_${slotID}`}></div>
          </div>
        );
      }
    } else if (!options?.isIgnoreParagraph) {
      componentList.push(
        <p
          key={i}
          className={
            options?.paragraphClass ? options.paragraphClass : 'paragraph--s'
          }
          dangerouslySetInnerHTML={{
            __html: processBlock(postfixAndTrim(cleanText, 0)),
          }}
        ></p>
      );
    } else {
      componentList.push(processBlock(postfixAndTrim(cleanText, 0)));
    }
  }

  return componentList;
};
