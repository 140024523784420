import { IRouteObject } from '../../../router/interfaces';
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary';
import Category from '../pages/HomeRoot/pages/Category/Category';
import Games from '../pages/HomeRoot/pages/Games/Games';
import Interests from '../pages/HomeRoot/pages/Interests/Interests';
import Home from '../pages/HomeRoot/pages/Home/Home';
import Story from '../pages/HomeRoot/pages/Story/Story';
import Lookbook from '../pages/Lookbook/Lookbook';
import NotFoundPage from '../../../pages/NotFoundPage/NotFoundPage';
import Stories from '../pages/HomeRoot/pages/Stories/Stories';
import {
  FetchHomeData,
  fetchDailyListData,
  getInterest,
  getRecirc,
} from '../../../server-side-logic/ServerDataUtils.ts';
import { IServerDataContext } from '../../../server-side-logic/ServerDataContext.ts';
import { fetchLookBook } from '../../../server-side-logic/ServerDataContextFetcher.ts';
import {
  DEFAULT_BRAND_GROUP_ID,
  GAMES_SCHEDULE,
  INTEREST_SCHEDULE,
  LATEST_SCHEDULE,
  LATEST_TITLE,
  POPULAR_SCHEDULE,
  STANDART_STORIES_SCHEDULE,
} from '../pages/HomeRoot/constants.ts';
import { getAvailableInterestList } from '../pages/HomeRoot/utils.ts';
import { IDailyLookBook } from '../services/api/lookBook/interfaces.ts';
import adsAPIService from '../services/api/ads';
import { WithInterest } from '../ContextWrapper/WithInterest/WithInterest.tsx';
import {AdConfig, IAdTag, IAdTagResponce} from '../services/api/ads/interfaces.ts';
import {
  CATEGORY_ROUTE_PATH,
  GAMES_ROUTE_PATH,
  HOMEPAGE_ROUTE_PATH,
  INTEREST_ROUTE_PATH,
  LOOKBOOK_ROUTE_PATH,
  NOT_FOUND_PAGE_ROUTE_PATH,
  ROUTER_ANIMATION_TYPE,
  STORIES_ROUTE_PATH,
} from './constants.ts';
import { getInterestUIDByURLName } from '../utils';
import lookBookApiService from '../services/api/lookBook';

export const isRouterAnimation = (animationName: string) => {
  return Object.values(ROUTER_ANIMATION_TYPE).includes(animationName);
};

const getAdConfig = async (brandgroup: string, params: any): Promise<AdConfig> => {
  try {
    const { utm_source } = params ?? {}
    const defaultAdData = await adsAPIService.getAds(
        brandgroup || DEFAULT_BRAND_GROUP_ID,
        { utmSource: utm_source }
    );
    return new AdConfig(defaultAdData);
  } catch (e: any) {
    console.error(`Error getting ad config`, e);
    if (brandgroup) {
      const defaultAdData = await adsAPIService.getAds(DEFAULT_BRAND_GROUP_ID);
      return new AdConfig(defaultAdData);
    }
  }
}

export const LOOKBOOK_ROUTE: IRouteObject = {
  path: LOOKBOOK_ROUTE_PATH,
  title: ':lookbookTitle | Fotoscapes',
  contextData: async (params?: any): Promise<IServerDataContext> => {
    const id = params.id;
    const recirc = await getRecirc(id);
    const lookbook = await fetchLookBook(id);
    const lookbookRecurcList: IDailyLookBook[] = [];

    const defaultAdData = await getAdConfig(lookbook?.ownedBy.brandGroup, params);

    return {
      lookbookData: {
        lookbookRecurcList,
        recirc,
        lookbook,
        adScriptList: [defaultAdData.adTag],
      },
      commonData: {
        interests: await getInterest(),
      },
    };
  },
  element: (
    <ErrorBoundary>
      <WithInterest>
        <Lookbook />
      </WithInterest>
    </ErrorBoundary>
  ),
};

export const CATEGORY_ROUTE: IRouteObject = {
  path: CATEGORY_ROUTE_PATH,
  title: ':interestTitle Galleries and Stories  | Fotoscapes',
  element: (
    <ErrorBoundary>
      <WithInterest>
        <Category />
      </WithInterest>
    </ErrorBoundary>
  ),
  contextData: async (params?: any): Promise<IServerDataContext> => {
    const categoryList: Record<string, IDailyLookBook[]> = {};
    const interestMap = await getInterest();

    const availableInterestList = getAvailableInterestList(
      interestMap,
      getInterestUIDByURLName(params.interestTitle)
    ).sort(({ uid }) => {
      return uid === getInterestUIDByURLName(params.interestTitle) ? -1 : 1;
    });

    for (const interest of availableInterestList) {
      try {
        const data = await fetchDailyListData(INTEREST_SCHEDULE[interest.uid]);

        if (params.interestTitle === LATEST_TITLE) {
          const latestData = await fetchDailyListData(LATEST_SCHEDULE);
          categoryList[LATEST_TITLE] = latestData.filter((item) => {
            return item.lbtype === 'standard';
          });
        }

        categoryList[interest.uid] = data.filter((item) => {
          return item.lbtype === 'standard';
        });
      } catch (e: any) {
        console.log(e);
      }
    }

    const defaultAdData = await getAdConfig(DEFAULT_BRAND_GROUP_ID, params);

    return {
      categoryData: {
        categoryList,
      },
      commonData: {
        interests: await getInterest(),
        defaultAdData: defaultAdData,
      },
    };
  },
};

export const GAMES_ROUTE: IRouteObject = {
  path: GAMES_ROUTE_PATH,
  title: 'Playable Games',
  description:
    'Kick back and enjoy some of our favorite playable games. Each one only takes a few minutes to explore. We add games weekly, so check back regularly to improve your high scores and try out new games.',
  element: (
    <ErrorBoundary>
      <Games />
    </ErrorBoundary>
  ),
  contextData: async (): Promise<IServerDataContext> => {
    return {
      gamesData: {
        gameList: await fetchDailyListData(GAMES_SCHEDULE),
      },
      commonData: {
        interests: await getInterest(),
      },
    };
  },
};

export const INTERESTS_ROUTE: IRouteObject = {
  path: INTEREST_ROUTE_PATH,
  title: 'Explore Photo Galleries | Fotoscapes',
  description:
    'Discover captivating photo galleries and immerse yourself in breathtaking imagery at Fotoscapes. Explore a world of visual inspiration today from topics you love.',
  element: (
    <ErrorBoundary>
      <WithInterest>
        <Interests />
      </WithInterest>
    </ErrorBoundary>
  ),
  contextData: async (): Promise<IServerDataContext> => {
    return {
      commonData: {
        interests: await getInterest(),
      },
    };
  },
};

export const STORIES_ROUTE: IRouteObject = {
  path: STORIES_ROUTE_PATH,
  title: 'FotoSCAPES Exclusive Stories',
  description: 'Profundice en nuestros temas más destacados.',
  element: (
    <WithInterest>
      <Stories />
    </WithInterest>
  ),
  contextData: async (params?:any): Promise<IServerDataContext> => {
    const defaultAdData = await getAdConfig(DEFAULT_BRAND_GROUP_ID, params);

    return {
      storiesData: {
        storyList: await fetchDailyListData(STANDART_STORIES_SCHEDULE),
      },
      commonData: {
        interests: await getInterest(),
        defaultAdData: defaultAdData,
      },
    };
  },
};

export const HOMEPAGE_ROUTE: IRouteObject = {
  path: HOMEPAGE_ROUTE_PATH,
  title: 'Curated Stories and Stunning Photography | Fotoscapes',
  description:
    'Discover curated stories and captivating photography at Fotoscapes. Explore a daily dose of celebrity homes, travel destinations, sneakers, cars, and more. Immerse yourself in a world of inspiration and visual delight.',
  contextData: async (params?:any) => {

    const defaultAdData = await getAdConfig(DEFAULT_BRAND_GROUP_ID, params);

    return {
      homeData: {
        ...(await FetchHomeData()),
      },
      commonData: {
        interests: await getInterest(),
        defaultAdData: defaultAdData,
      },
    };
  },
  element: (
    <ErrorBoundary>
      <WithInterest>
        <Home />
      </WithInterest>
    </ErrorBoundary>
  ),
};

export const STORY_ROUTE: IRouteObject = {
  path: '/stories/:uid/:title?',
  title: ':lookbookTitle | Fotoscapes',
  element: (
    <ErrorBoundary>
      <WithInterest>
        <Story />
      </WithInterest>
    </ErrorBoundary>
  ),
  contextData: async (params?: any): Promise<IServerDataContext> => {
    const id = params?.uid;

    const defaultAdData = await getAdConfig(DEFAULT_BRAND_GROUP_ID, params);

    return {
      storyData: {
        travelList: await fetchDailyListData(INTEREST_SCHEDULE.NZf7cr),
        popularList: await fetchDailyListData(POPULAR_SCHEDULE),
        interests: await getInterest(),
        lookBook: await fetchLookBook(id),
      },
      commonData: {
        defaultAdData: defaultAdData,
      },
    };
  },
};

export const NOT_FOUND_PAGE_ROUTE = {
  path: NOT_FOUND_PAGE_ROUTE_PATH,
  title: '',
  description: '',
  element: (
    <ErrorBoundary>
      <NotFoundPage />
    </ErrorBoundary>
  ),
  contextData: async (): Promise<IServerDataContext> => {
    return {
      notFoundData: {
        latestSchedule: await fetchDailyListData(LATEST_SCHEDULE),
      },
    };
  },
};

export const rootRoutes = [
  HOMEPAGE_ROUTE,
  INTERESTS_ROUTE,
  CATEGORY_ROUTE,
  GAMES_ROUTE,
  STORIES_ROUTE,
  STORY_ROUTE,
  LOOKBOOK_ROUTE,
  NOT_FOUND_PAGE_ROUTE,
];
