export enum BridgeEvent {
  AD_LOAD = "AD_LOAD",
  AD_IMPRESSION = "AD_IMPRESSION",
  AD_IMPRESSION_VIEWABLE = "AD_IMPRESSION_VIEWABLE",
  OPTIN_ACCEPT = "OPTIN_ACCEPT",
  OPTIN_DISMISS = "OPTIN_DISMISS",
  INTEREST_CLICK = "INTEREST_CLICK",
  SCREEN_UNLOCK = "SCREEN_UNLOCK",
  DO_NOT_SELL_STATE_CHANGED = "DO_NOT_SELL_STATE_CHANGED"
}

export enum BridgeAction {
  SHARE_URL = "SHARE_URL",
  SHOW_SECRET_PAGE = "SHOW_SECRET_PAGE",
  DELETE_ALL_LOCAL_STORAGE = "DELETE_ALL_LOCAL_STORAGE",
  OPEN_PAGE = "OPEN_PAGE"
}

export enum BridgeData {
  AD_DATA = "AD_DATA",
  INTERESTS = "INTERESTS",
  DEVICE_INFO = "DEVICE_INFO",
  KEYGUARD_LOCKED = "KEYGUARD_LOCKED"
}

//TODO: maybe have this one as a 'language' def, and have a second/third one describing the native one or the js one
export interface DTCMBridge {
  onEvent(eventName: BridgeEvent, extras: string | object): void;
  performAction(actionName: BridgeAction, payloadJson: object | string): void;
  getData(dataName: BridgeData): object|string;
  getSupportedActions(): string|string[];
  saveData(tag: string, payloadJson: object|string): void;
  log(message: string): void;
  getBridgeVersion(): string;
}
