import {FC, PropsWithChildren, useCallback, useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';

import { FeatureConfigContext, IFeatureConfig } from './FeatureConfigContext';
import { UTM_SOURCE_QUERY } from '../../utils/constants';
import {MultitenantFeatureFlags} from "../../../../featureflags";

export const WithFeatureConfig: FC<PropsWithChildren> = ({ children }) => {
  const [search] = useSearchParams();
  const [config, setConfig] = useState<IFeatureConfig>(
      MultitenantFeatureFlags.fotoscapes as IFeatureConfig
  );
  const loadFeatureFlagsConfig = useCallback(async () => {
    const regex = /^(staging.)?(.*).com/;
    const match = window.location.hostname?.match(regex);
    const hostOverride = search.get('host_override');
    if (match || hostOverride) {
      try {
        const host = match ? match[2] : hostOverride;
        console.debug(`loading config for ${host}`);
        const tenantConfig = MultitenantFeatureFlags[host] || MultitenantFeatureFlags[hostOverride];
        if (tenantConfig) {
          setConfig(tenantConfig);
        } else {
          console.debug(`Partner config for '${host}' and override '${hostOverride}' not found, defaulting`);
        }
      } catch (e) {
        console.error(`Error loading config, defaulting`, e)
      }
    }
  }, [window.location])

  const [utmSource, setUtmSource] = useState<string>('');

  useEffect(() => {
    const queryUTMSource = search.get(UTM_SOURCE_QUERY);
    if (queryUTMSource) {
      setUtmSource(queryUTMSource);
    }
    loadFeatureFlagsConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FeatureConfigContext.Provider value={{ config, utmSource }}>
      {children}
    </FeatureConfigContext.Provider>
  );
};
