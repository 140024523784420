import { FC } from 'react';

import SkeletonImage from '../../../pages/Root/components/skeletons/SkeletonImage/SkeletonImage.tsx';

import './AdSkeleton.scss';

const AdSkeleton: FC = () => {
  return (
    <div className="ad-skeleton">
      <SkeletonImage />
    </div>
  )
}

export default AdSkeleton;
