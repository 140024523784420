import { PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from '../../../pages/Root/utils/constants.ts';

export const FOOTER_LINK_LIST = [
  { text: 'digitalTurbineTermsOfUse', link: TERMS_OF_SERVICE_LINK },
  {
    text: 'noticeAtCollectionAndPrivacyPolicy',
    link: PRIVACY_POLICY_LINK,
  },
  // TODO: Uncomment when those pages will be ready
  // { text: 'managePrivacy', link: '#' },
  // {
  //   text: 'doNotSellMyPersonalInformation',
  //   link: "#",
  // },
];
