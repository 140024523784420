import {
  CSSProperties,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  getInterestBackgroundImg,
  getInterestUIDByURLName,
  getWithOutSpecialChars,
} from '../../../../utils';
import { IInterest } from '../../../../services/api/interest/interfaces';
import { IDailyLookBook } from '../../../../services/api/lookBook/interfaces';
import {
  CATEGORY_DESKTOP_AD_MAP_DATA,
  CATEGORY_MOBILE_AD_MAP_DATA,
  CATEGORY_SMALL_TABLED_AD_MAP_DATA,
  CATEGORY_TABLED_AD_MAP_DATA,
  INTEREST_HEIGHT,
  MOBILE_INTEREST_HEIGHT,
} from './constants';
import { LATEST_SCHEDULE, LATEST_TITLE } from '../../constants';
import { getAvailableInterestList, setUpAds } from '../../utils';
import { useResize } from '../../../../hooks';
import InterestCarousel from '../../../../components/InterestCarousel/InterestCarousel';
import Footer from '../../../../../../components/Footer/Footer';
import CategorySkeleton from './components/CategorySkeleton/CategorySkeleton';
import Lazy from '../../../../components/Lazy/Lazy';
import SingleCard from '../../components/SingleCard/SingleCard';
import { getTranslation } from '../../../../../../i18n';
import { useFotoscapeNavigate } from '../../../../../../hooks/navigation';
import {
  IServerDataContext,
  ServerDataContext,
} from '../../../../../../server-side-logic/ServerDataContext.ts';
import { IAdTag } from '../../../../services/api/ads/interfaces.ts';
import { LOOKBOOK_PARAMETERS_TO_COPY } from '../../../Lookbook/contants.ts';
import { InterestContext } from '../../../../ContextWrapper/WithInterest/InterestContext.ts';
import lookBookApiService from '../../../../services/api/lookBook';
import { DEFAULT_BRAND_GROUP_ID, INTEREST_SCHEDULE } from '../../constants.ts';
import adsAPIService from '../../../../services/api/ads';
import { loadAdScripts } from '../../../../utils/ads.ts';
import {
  LOOKBOOK_ROUTE_PATH,
  ROUTER_ANIMATION_TYPE,
} from '../../../../router/constants.ts';

import './index.scss';

const Category: FC = () => {
  const navigate = useFotoscapeNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile } = useResize();
  const { categoryData: serverCategoryData, commonData } =
    useContext<IServerDataContext>(ServerDataContext);
  const { interestMap } = useContext(InterestContext);

  const adDataSeverData = useMemo<IAdTag | null>(() => {
    return commonData?.defaultAdData?.adTag || null;
  }, [commonData]);

  const [adData, setAdData] = useState(adDataSeverData);

  const [, setRenderIndex] = useState(0);

  const [lookBookList, setLookBookList] = useState(
    serverCategoryData?.categoryList || {}
  );

  const interestList = useMemo(() => {
    if (interestMap) {
      return getAvailableInterestList(
        interestMap,
        getInterestUIDByURLName(params.interestTitle)
      ).sort(({ uid }) => {
        return uid === getInterestUIDByURLName(params.interestTitle) ? -1 : 1;
      });
    }

    return [];
  }, [params.interestTitle, interestMap]);

  const interest = useMemo<IInterest | undefined>(() => {
    return interestList[0];
  }, [interestList]);

  const sectionHeight = useMemo(() => {
    if (isMobile) {
      return MOBILE_INTEREST_HEIGHT;
    }

    return INTEREST_HEIGHT;
  }, [isMobile]);

  const interestPageHeight = useMemo(() => {
    const scrollTop = searchParams.get('scrollTop');
    const cardsInView = window.innerHeight / sectionHeight;

    if (scrollTop) {
      return Number(scrollTop) + sectionHeight * cardsInView;
    }

    return '100vh';
  }, [searchParams, sectionHeight]);

  const interestStyleList = useMemo(() => {
    return { minHeight: interestPageHeight };
  }, [interestPageHeight]);

  const isShowPlaceHolder = useMemo(() => {
    return !interestList.length || !Object.keys(lookBookList).length;
  }, [interestList, lookBookList]);

  const isLatest = useMemo(() => {
    return params.interestTitle === LATEST_TITLE;
  }, [params]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const scrollTop = searchParams.get('scrollTop');

    if (scrollTop && interestList.length) {
      window.scroll({ top: Number(scrollTop) });
    }
  }, [interestList, navigate, searchParams, setSearchParams]);

  const fetchDailyFotoscapeLookBookList = (uid: string, schedule: string) => {
    lookBookApiService
      .getDailyLookbookList(schedule)
      .then((data) => {
        if (data.items.length) {
          setLookBookList((item) => {
            return {
              ...item,
              [uid]: data.items.filter((item) => {
                return item.lbtype === 'standard';
              }),
            };
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const lookBookItemClassList = (uid: string) => {
    const classList = ['category__interest-list-item'];

    if (uid) {
      classList.push(
        `interest-background  interest-background--with-img interest-background--${uid}`
      );
    }

    return classList.join(' ');
  };

  const renderCarouselItem = ({
    images,
    summary,
    title,
    brandLogo,
    uid,
    interests
  }: IDailyLookBook) => {
    return (
      <SingleCard
        uid={uid}
        interestId={interests[0]}
        link={{
          pathname: `${LOOKBOOK_ROUTE_PATH.replace(':id', uid).replace(
            ':title?',
            getWithOutSpecialChars(title.en)
          )}`,
          hash: '#1',
        }}
        state={{
          animation: {
            out: ROUTER_ANIMATION_TYPE.routerAnimationFadeOut,
            in: ROUTER_ANIMATION_TYPE.routerAnimationFadeIn,
          },
        }}
        classList="interest-carousel__cover-look"
        title={getTranslation(title)}
        imgList={images}
        descriptionLineCount={4}
        description={getTranslation(summary)}
        logoURL={brandLogo}
      />
    );
  };

  const onItemRender = useCallback(() => {
    setRenderIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;

      if (lookBookList[interestList[nextIndex]?.u]) {
        return nextIndex;
      }

      if (params.interestTitle === LATEST_TITLE && prevIndex === 0) {
        fetchDailyFotoscapeLookBookList(LATEST_TITLE, LATEST_SCHEDULE);
      }

      const interestUID = interestList[nextIndex]?.uid;

      if (interestList[nextIndex] && !lookBookList[interestUID]) {
        fetchDailyFotoscapeLookBookList(
          interestUID,
          INTEREST_SCHEDULE[interestUID]
        );
      }

      return nextIndex;
    });
  }, [setRenderIndex, interestList, params, lookBookList]);

  const getSectionTemplate = (
    uid: string,
    backgroundImage: string,
    name: Record<string, string>,
    description?: Record<string, string>
  ) => {
    return (
      <Lazy
        className="category__interest-list"
        id={uid}
        key={uid}
        fallback={<CategorySkeleton />}
        styleList={
          {
            '--background-image': `url(${backgroundImage})`,
            height: sectionHeight,
          } as CSSProperties
        }
        onRender={onItemRender}
      >
        <section className={lookBookItemClassList(uid)}>
          <div className="interest-background__item" />

          <h1 className="category__title f-headline--xxxl">
            {getTranslation(name)}
          </h1>

          {description ? (
            <h2 className="category__subtitle paragraph--m">
              {getTranslation(description)}
            </h2>
          ) : null}

          <div className="category__lookbook-list">
            {lookBookList[uid]?.length ? (
              <InterestCarousel
                isMobile={isMobile}
                itemList={lookBookList[uid]}
                renderItem={renderCarouselItem}
              />
            ) : null}
          </div>
        </section>
      </Lazy>
    );
  };

  const renderSection = (interest: IInterest) => {
    const { uid, name, description } = interest;
    const backgroundImage = getInterestBackgroundImg(interest);

    return lookBookList[uid]?.length
      ? getSectionTemplate(uid, backgroundImage, name, description)
      : null;
  };

  const renderAd = useCallback(() => {
    const parameterList = [];
    const size = [
      ...CATEGORY_DESKTOP_AD_MAP_DATA.adSizeList,
      ...CATEGORY_TABLED_AD_MAP_DATA.adSizeList,
      ...CATEGORY_SMALL_TABLED_AD_MAP_DATA.adSizeList,
      ...CATEGORY_MOBILE_AD_MAP_DATA.adSizeList,
    ];

    const responsiveSize = [
      CATEGORY_DESKTOP_AD_MAP_DATA,
      CATEGORY_TABLED_AD_MAP_DATA,
      CATEGORY_SMALL_TABLED_AD_MAP_DATA,
      CATEGORY_MOBILE_AD_MAP_DATA,
    ];

    for (const parameter of LOOKBOOK_PARAMETERS_TO_COPY) {
      const parameterValue = searchParams.get(parameter);
      if (parameterValue) {
        parameterList.push({ parameter, parameterValue });
      }
    }

    if (interest) {
      parameterList.push({
        parameter: 'pathname',
        parameterValue: interest.name.en,
      });
    }

    if (adData) {
      setUpAds(adData, 'category', size, parameterList, responsiveSize);
    }
  }, [searchParams, adData, interest]);

  useEffect(() => {
    if (!isShowPlaceHolder && adData) {
      renderAd();
    }

    if (!adData) {
      adsAPIService
        .getAds(DEFAULT_BRAND_GROUP_ID)
        .then((data) => {
          setAdData(data.adTag);
          loadAdScripts(data.adTag);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [renderAd, isShowPlaceHolder, adData]);

  useEffect(() => {
    const { uid } = interestList[0];
    if (!lookBookList[uid]) {
      fetchDailyFotoscapeLookBookList(uid, INTEREST_SCHEDULE[uid]);
    }
  }, [lookBookList, interestList]);

  useEffect(() => {
    setRenderIndex(0);
  }, [params]);

  return (
    <div className="category" style={interestStyleList}>
      {isShowPlaceHolder ? (
        <>
          <CategorySkeleton />
          <CategorySkeleton />
        </>
      ) : (
        <div>
          {isLatest
            ? getSectionTemplate(
                LATEST_TITLE,
                LATEST_TITLE,
                { en: 'Latest' },
                {
                  en: 'Check out the most recent images and stories from the Fotoscapes team',
                }
              )
            : null}
          {interestList?.slice(0, 1).map(renderSection)}
          <div key="ad" className="category__ad" id="ad_unit_category"></div>
          {interestList?.slice(1).map(renderSection)}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Category;
