import { FC } from 'react';

import { IImageData } from '../../../pages/Root/services/api/interfaces.ts';
import Image from '../../containers/BestImageContainer/BestImageContainer.tsx';
import { Button } from '../../../stories/Button.tsx';

import './GamesTile.scss';

interface IGamesTileProps {
  buttonText: string;
  imageList: IImageData[];
  onClick?: () => void;
}

const GamesTile: FC<IGamesTileProps> = ({ buttonText, imageList, onClick }) => {
  const handlePlayNow = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="games-tile">
      <div className="games-tile__image-container">
        <Image
          targetWidth={'100%'}
          targetHeight={172}
          alt={''}
          sizeHint={'large'}
          images={imageList}
        />
      </div>
      <Button
        className="games-tile__button"
        primary
        textColor="#000"
        backgroundColor="#EFEFEF"
        onClick={handlePlayNow}
        label={buttonText}
      ></Button>
    </div>
  );
};

export default GamesTile;
