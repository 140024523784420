import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react';

import { ICutout, cutoutSize } from './interfaces';
import Image from '../../containers/BestImageContainer/BestImageContainer.tsx';

import './Cutout.scss';

const Cutout: FC<ICutout> = ({
                               text,
                               imageList,
                               children,
                               size,
                               imageClassName,
                               contentClassName,
                             }) => {
  const [isReadyToUse, setIsReadyToUse] = useState(false);
  const textRef = useRef<HTMLInputElement>(null);

  const classList = useMemo(() => {
    const list = ['cutout'];

    if (size === cutoutSize.lg) {
      list.push('cutout--lg');
    }

    return list.join(' ');
  }, [size]);

  const textElWidth = useMemo(() => {
    if (textRef.current && isReadyToUse) {
      if(text) {
        return `${textRef.current?.getBoundingClientRect().width + 16}px`;
      } else {
        return `${textRef.current?.getBoundingClientRect().width}px`;
      }
    }

    return '50%';
  }, [isReadyToUse, textRef.current]);

  const styleList = useMemo(() => {
    return {
      '--text-el-width': textElWidth,
    } as CSSProperties;
  }, [textElWidth]);

  const imageClassList = useMemo(() => {
    const list = ['cutout__image'];

    if (imageClassName) {
      list.push(imageClassName);
    }

    return list.join(' ');
  }, [imageClassName]);

  const contentClassList = useMemo(() => {
    const list = ['cutout__content'];

    if (contentClassName) {
      list.push(contentClassName);
    }

    return list.join(' ');
  }, [contentClassName]);

  useEffect(() => {
    setIsReadyToUse(true);
  }, []);

  return (
    <div className={classList} style={styleList}>
      <div className={imageClassList}>
        <Image
          targetWidth={"100%"}
          targetHeight={240}
          alt={text}
          sizeHint={"large"}
          images={imageList}
        />
      </div>

      {text ? (
        <div ref={textRef} className="cutout__text f-headline--xs">
          {text}
        </div>
      ) : null}

      {children ? <div ref={textRef} className={contentClassList}>{children}</div> : null}
    </div>
  );
};

export default Cutout;