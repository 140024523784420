import React from 'react';
import './OptIn.scss';
import momentLogo from '/assets/images/moment-logo-red.svg?url';
import { Trans, useTranslation } from 'react-i18next';
import { useBridge } from '../../bridge/bridge-provider.tsx';
import { BridgeEvent } from '../../bridge/client-bridge-defs.ts';

interface OptInProps {
  children: React.ReactNode;
}

const SimpleOptInContent: React.FC = () => {
  return (
    <div className="centered-opt-in">
      <div className="opt-in-content-image">
        <img src={momentLogo} />
      </div>
      <div className="opt-in-content-text">
        <Trans i18nKey="optInHeading">
          The world’s latest news captured <b>in the moment.</b>
        </Trans>
      </div>
    </div>
  );
};

const OptIn: React.FC<OptInProps> = ({ children }) => {
  const { t } = useTranslation();
  const bridge = useBridge();
  return (
    <div className="optin-layout">
      <div className="content">{children}</div>
      <div className="bottom-section">
        <button
          className="bottom-button"
          onClick={() => {
            return bridge.onEvent(BridgeEvent.OPTIN_ACCEPT, {})
          }}
        >
          {t('getStarted')}
        </button>
        <p className="bottom-text">
          <Trans i18nKey="tosOptIn">
            Moment utilizes cellular data. By activating The Moment app, I
            confirm that I am 13+ years of age and have read and agree to the
            <a
              href="https://www.digitalturbine.com/legal/terms-of-use"
              target="_blank"
            >
              Terms of Service
            </a>
          </Trans>
        </p>
      </div>
    </div>
  );
};

const OptInLayout: React.FC = () => {
  return (
    <OptIn>
      <SimpleOptInContent />
    </OptIn>
  );
};

export default OptInLayout;
