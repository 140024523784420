import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Footer from '../../../../../../components/Footer/Footer';
import { IDailyLookBook } from '../../../../services/api/lookBook/interfaces';
import { useResize } from '../../../../hooks';
import { LOOKBOOK_PARAMETERS_TO_COPY } from '../../../Lookbook/contants';
import {
  STORIES_AD_SLOT,
  STORIES_DESKTOP_AD_MAP_DATA,
  STORIES_MOBILE_AD_MAP_DATA,
  STORIES_TABLED_AD_MAP_DATA,
  STORIES_TABLED_SMALL_AD_MAP_DATA,
} from './constants';
import { setUpAds } from '../../utils';
import { IAdParameter } from '../../interfaces';
import { STORY_ROUTE } from '../../../../router/router';
import StoriesSkeletonList from './components/StoriesSkeletonList/StoriesSkeletonList';
import { getWithOutSpecialChars } from '../../../../utils';
import SingleCard from '../../components/SingleCard/SingleCard';
import { getTranslation } from '../../../../../../i18n';
import {
  IServerDataContext,
  ServerDataContext,
} from '../../../../../../server-side-logic/ServerDataContext.ts';
import { IAdTag } from '../../../../services/api/ads/interfaces.ts';
import { InterestContext } from '../../../../ContextWrapper/WithInterest/InterestContext.ts';
import {
  DEFAULT_BRAND_GROUP_ID,
  STANDART_STORIES_SCHEDULE,
} from '../../constants.ts';
import lookBookApiService from '../../../../services/api/lookBook';
import adsAPIService from '../../../../services/api/ads';
import { loadAdScripts } from '../../../../utils/ads.ts';

import './index.scss';

const Stories: FC = () => {
  const { isDesktop, isTabled, isMobile } = useResize();
  const [searchParams] = useSearchParams();
  const { storiesData, commonData } =
    useContext<IServerDataContext>(ServerDataContext);
  const { t } = useTranslation();
  const { interestMap } = useContext(InterestContext);

  const adDataSeverData = useMemo<IAdTag | null>(() => {
    return commonData?.defaultAdData?.adTag || null;
  }, [commonData]);

  const [adData, setAdData] = useState(adDataSeverData);
  const [storyList, setStoryList] = useState(storiesData?.storyList || []);

  const renderList = useMemo(() => {
    let rest = storyList.length;

    if (isDesktop) {
      rest = -storyList.length % 3 || storyList.length;
    }

    if (isTabled) {
      rest = -storyList.length % 2 || storyList.length;
    }

    return storyList.slice(0, rest);
  }, [storyList, isDesktop, isTabled]);

  const firstListLength = useMemo(() => {
    if (isMobile) {
      return 1;
    }

    if (isTabled) {
      return 4;
    }

    return 6;
  }, [isTabled, isMobile]);

  const firstSectionList = useMemo(() => {
    return renderList.slice(0, firstListLength);
  }, [renderList, firstListLength]);

  const secondSectionList = useMemo(() => {
    return [...renderList.slice(firstListLength)];
  }, [renderList, firstListLength]);

  const getInterestName = useCallback(
    (interestUID: string) => {
      const name = interestMap.get(interestUID)?.name;

      if (name) {
        return getTranslation(name);
      }

      return '';
    },
    [interestMap]
  );

  const renderAd = useCallback(() => {
    const size = [
      ...STORIES_DESKTOP_AD_MAP_DATA.adSizeList,
      ...STORIES_TABLED_AD_MAP_DATA.adSizeList,
      ...STORIES_TABLED_SMALL_AD_MAP_DATA.adSizeList,
      ...STORIES_MOBILE_AD_MAP_DATA.adSizeList,
    ];

    const responsiveSize = [
      STORIES_DESKTOP_AD_MAP_DATA,
      STORIES_TABLED_AD_MAP_DATA,
      STORIES_TABLED_SMALL_AD_MAP_DATA,
      STORIES_MOBILE_AD_MAP_DATA,
    ];

    const parameterList: IAdParameter[] = [];

    for (const parameter of LOOKBOOK_PARAMETERS_TO_COPY) {
      const parameterValue = searchParams.get(parameter);
      if (parameterValue) {
        parameterList.push({ parameter, parameterValue });
      }
    }
    parameterList.push({
      parameter: 'pathname',
      parameterValue: 'stories',
    });

    if (adData) {
      setUpAds(adData, STORIES_AD_SLOT, size, parameterList, responsiveSize);
    }
  }, [searchParams, adData]);

  const renderItem = (data: IDailyLookBook) => {
    const { title, summary, previews, uid, interests } = data;
    const interestName = getInterestName(interests[0]);

    return (
      <div className="stories__card-wrapper" key={uid}>
        <SingleCard
          uid={uid}
          interestId={interests[0]}
          link={STORY_ROUTE.path
            .replace(':uid', uid)
            .replace(':title', getWithOutSpecialChars(title.en))}
          title={getTranslation(title)}
          description={getTranslation(summary)}
          descriptionLineCount={3}
          imgList={previews}
          interest={interestName}
        />
      </div>
    );
  };

  useEffect(() => {
    if (adData) {
      renderAd();
    } else {
      adsAPIService
        .getAds(DEFAULT_BRAND_GROUP_ID)
        .then((data) => {
          setAdData(data.adTag);
          loadAdScripts(data.adTag);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [renderAd, adData]);

  useEffect(() => {
    if (storyList.length === 0) {
      lookBookApiService
        .getDailyLookbookList(STANDART_STORIES_SCHEDULE, 'all')
        .then((data) => {
          if (data.items.length) {
            setStoryList(data.items);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [storyList]);

  return (
    <div className="stories">
      <h1 className="stories__title">{t('fotoSCAPESSExclusive')}</h1>

      <h2 className="stories__subtitle paragraph--l">{t('diveDeep')}</h2>

      {firstSectionList.length ? (
        <div className="stories__list">{firstSectionList.map(renderItem)}</div>
      ) : (
        <StoriesSkeletonList />
      )}

      <div className="stories__ad-wrapper">
        <div id={`ad_unit_${STORIES_AD_SLOT}`} />
      </div>

      <div className="stories__list">{secondSectionList.map(renderItem)}</div>
      <Footer />
    </div>
  );
};

export default Stories;
