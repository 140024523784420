import React, {JSX} from "react";

import { IRouteObject } from '../../router/interfaces.ts';
import {IServerDataContext} from "../../server-side-logic/ServerDataContext.ts";
import { LayoutConfigurationPage } from '../layouts/LayoutConfiguration/layout-configuration-factory.ts';

export interface MomentBaseProps<P,T> {
  queryFn: (params: P) => Promise<T>
}


export const buildMomentRoute = <TParams extends Record<string, string>>(route: MomentRoute<TParams, any>): Partial<IRouteObject> => {

  return {
    path: route.path.raw,
    title: route.title,
    description: route.description,
    element: React.cloneElement(route.element, { queryFn: route.prefetchQuery }),
    prefetchQuery: route.prefetchQuery,
    contextData: route.contextData,
    feedRoute: route.feedRoute,
  }
}

interface PathType<TParams extends Record<string, string>> {
  raw: string;
  build: (params: TParams, query?: Record<string, string>) => string;
}

export function createPathType<TParams extends Record<string, string>>(
  raw: string,
): PathType<TParams> {
  return {
    raw,
    build: (params: TParams, query?: Record<string, string>) => {
      let path = raw;
      const paramKeys = raw.match(/:[a-zA-Z]+/g)?.map((p) => {return p.slice(1)}) || [];
      for (const key of paramKeys) {
        if (!(key in params)) {
          throw new Error(`Missing required param '${key}' for path '${raw}'`);
        }
        path = path.replace(`:${key}`, params[key]);
      }
      if (query) {
        const queryString = new URLSearchParams(query).toString();
        return `${path}?${queryString}`;
      }
      return path;
    },
  };
}

export type MomentRoute<TParams extends Record<string, string>, PrefetchType> = {
  path: PathType<TParams >;
  title?: string;
  description?: string;
  element: JSX.Element;
  prefetchQuery?: (params: TParams) => Promise<PrefetchType>;
  contextData?: (params?: object) => Promise<IServerDataContext>;
  feedRoute?: LayoutConfigurationPage
};
