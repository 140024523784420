import { useQuery } from '@tanstack/react-query';
import { EnhancedLightboxItem, FeedQueryResult } from '../../net/softbox-types.ts';

import './FeedLayout.scss';
import SectionHeader from '../../components/SectionHeader/SectionHeader.tsx';
import { AdInitLayout } from '../AdInitLayout.tsx';
import { FeedBuilder } from './FeedBuilder.tsx';
import ActivityIndicator from '../../components/ActivityIndicator/ActivityIndicator.tsx';
import { MomentBaseProps } from '../../routes/types.ts';
import { LayoutConfigurationPage } from '../LayoutConfiguration/layout-configuration-factory.ts';
import { resolvedLanguage } from '../../../i18n.ts';
import {useSearchParams} from "react-router-dom";
import {AdhesionAd} from "../../components/AdhesionAd/AdhesionAd.tsx";

export interface FeedLayoutProps
  extends MomentBaseProps<Record<string, string>, FeedQueryResult> {
  schedule: string;
  configuration: LayoutConfigurationPage;
}

const lang = resolvedLanguage('en');

export const FeedLayout = (props: FeedLayoutProps) => {
  const [searchParams] = useSearchParams();
  //XXX NOTE - buildMomentRoute is mutating this element's props to add queryFn XXX
  const feedQueryConstruct = {
    queryKey: [props.configuration.path, lang],
    queryFn: props.queryFn,
  };

  const { data, isLoading, error } = useQuery<FeedQueryResult>(feedQueryConstruct);

  const showAdhesion = searchParams.get('medium') === 'fsd';

  return (
    <div>
      <header>Feed!</header>
      <main>
        {isLoading && <ActivityIndicator />}
        {data && (
          <div className="moment-container">
            <div className="feed-container">
              <AdInitLayout>
                <FeedBuilder
                  configuration={props.configuration}
                  newsItems={data.news.items.map((it) => {return new EnhancedLightboxItem(it)})}
                  interests={data.interests}
                />
                {showAdhesion && <AdhesionAd adUnitId={`adh1`}/>}
              </AdInitLayout>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};
