import axios from 'axios';

import { getBaseUrl } from './utils';
import { ClientApi } from './api-impl-client.ts';
import { ServerApi } from './api-impl-server.ts';
import { API } from './api.ts';
export const BASE_URL = getBaseUrl();

const targetClass = import.meta.env.SSR ? ServerApi : ClientApi

export default new targetClass(axios, {
  baseURL: BASE_URL,
}) as API;
