import { FeedQueryResult, LightboxRootObject } from './softbox-types.ts';
import { getBaseUrl } from '../../pages/Root/services/api/utils.ts';
import { resolvedLanguage } from '../../i18n.ts';
import { LRUCache } from 'lru-cache';

const DEFAULT_ASPECT = '9:4';

const DEFAULT_CKEY = 'fb529d256155b9c6';

const cache = new LRUCache<string, any>({
  max: 1024,
  ttl: 5 * 60 * 1000,
});

const API_URL = getBaseUrl()

export const generateFetchFeedQuery = (schedule: string) => {
  return async () => {
    const language = resolvedLanguage(
      'en'
    );

    const key = JSON.stringify({
      language,
      schedule,
    })

    if (cache.has(key)) {
      return cache.get(key);
    }
    const res = await fetch(
      `${API_URL}/wp/v1/daily?ckey=${DEFAULT_CKEY}&mp_lang=${language}&sched=${schedule}&previewAspect=${DEFAULT_ASPECT}`
    );
    const data: LightboxRootObject = await res.json();

    const resInterests = await fetch(
      `${API_URL}/wp/v1/interests?ckey=${DEFAULT_CKEY}&previewAspect=${DEFAULT_ASPECT}`
    );
    const interests = await resInterests.json();

    const output = {
      news: data,
      interests,
    } satisfies FeedQueryResult;

    cache.set(key, output);

    return output
  };
};

export const fetchFeedQuery = async (params: {
  lang: string;
  hour: string;
}) => {
  //todo refactor to include consts from classic fotoscapes
  const res = await fetch(
    '${API_URL}/wp/v1/daily?ckey=fb529d256155b9c6&mp_lang=en&sched=dynamic-news&previewAspect=9:4'
  );
  const data: LightboxRootObject = await res.json();

  const resInterests = await fetch(
    '${API_URL}/wp/v1/interests?ckey=fb529d256155b9c6&previewAspect=9:4'
  );
  const interests = await resInterests.json();

  return {
    news: data,
    interests,
  } satisfies FeedQueryResult;
};
