import eventsbus from '../../utils/eventsbus.ts';
import { bridge } from './client-bridge.ts';
import { BridgeEvent } from './client-bridge-defs.ts';
import { BridgeInternalEvents } from './bridge-internal-event-defs.ts';

export default {
  init: () => {
    eventsbus.on(BridgeInternalEvents.LINK_CLICK, (event) => {
      bridge.onEvent(BridgeEvent.INTEREST_CLICK, {
        interestId: event.extras.interestId,
        id: event.extras.id,
        timestamp: event.timestamp,
      });
    });
  }
}


