import React, { useState } from 'react';
import './DataRequestForm.scss';
import FotoscapeLink from '../../../components/FotoscapeLink/FotoscapeLink.tsx';

interface DataRequestFormProps {
  mode: 'delete' | 'give';
}

const DataRequestForm: React.FC<DataRequestFormProps> = ({ mode }) => {
  // State for form inputs
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  // State for submission status
  const [submissionStatus, setSubmissionStatus] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle');

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmissionStatus('submitting');

    const type = mode === 'delete' ? 'DELETE_DATA_REQUEST' : 'GET_DATA_REQUEST';

    let mpId: string = 'null';

    if (window && window.location) {
      const params = new URLSearchParams(window.location.search);
      if (params.has('mpid')) {
        mpId = params.get('mpid')!
      } else if (params.has('uid')) {
        mpId = params.get('uid')!
      }
    }

    const payload = { type, name, state, email, mpId };

    try {
      const response = await fetch('/api/data-request', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      setSubmissionStatus('success');
    } catch (error) {
      setSubmissionStatus('error');
    }
  };

  // Introductory text based on mode
  const introText = mode === 'delete' ? (
    <>
      You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions that we state in our California Privacy Statement located <FotoscapeLink to="/manage-privacy">here</FotoscapeLink>. You can submit your request by filling out the form below. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.
    </>
  ) : (
    <>
      You have the right to request that we disclose certain information about our collection and use of your personal information for a period up to 12 months. You can submit your request by filling out the form below. Once we receive and confirm your verifiable consumer request, we will disclose to you the categories of information that we collect as stated in our California Privacy Statement located <FotoscapeLink to="/manage-privacy">here</FotoscapeLink>.
    </>
  );

  // Button text based on mode
  const buttonText = mode === 'delete' ? 'Delete My Data' : 'Give Me My Data';

  // List of U.S. states and territories
  const states = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District Of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'GU', label: 'Guam' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'UM', label: 'United States Minor Outlying Islands' },
    { value: 'VI', label: 'Virgin Islands' },
  ];

  return (
    <main className="legal-data-request">
      <p>{introText}</p>
      <hr />
      <form onSubmit={handleSubmit} data-gtm-id="ccpa-form">
        <h4>Please provide us with the following:</h4>
        <label htmlFor="name-input">
          <p>Your Name</p>
          <input
            id="name-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            maxLength={256}
            data-gtm-id="name-input"
          />
        </label>
        <label htmlFor="email-input">
          <p>Your email address so that we can contact you</p>
          <input
            id="email-input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
            title="Email address is invalid"
            maxLength={256}
            data-gtm-id="email-input"
          />
        </label>
        <label htmlFor="state-input">
          <p>Your state of residence</p>
          <select
            id="state-input"
            value={state}
            onChange={(e) => setState(e.target.value)}
            required
            data-gtm-id="state-input"
          >
            <option value="">-- No State Selected --</option>
            {states.map((stateOption) => (
              <option key={stateOption.value} value={stateOption.value}>
                {stateOption.label}
              </option>
            ))}
          </select>
        </label>
        <p className="disclaimer">
          By submitting this request, you confirm (1) your information is true and accurate, (2) you understand your information will be handled in accordance with our California Privacy Statement, and (3) Digital Turbine has the right to contact you to verify your identity and to process this request.
        </p>
        <button
          type="submit"
          disabled={submissionStatus === 'submitting'}
          data-gtm-event-action={mode === 'delete' ? 'delete my data' : 'give me my data'}
          data-gtm-event-category="ccpa"
          data-gtm-event-label="form submit"
          data-gtm-event-type="ccpa"
        >
          {submissionStatus === 'submitting' ? 'Submitting...' : buttonText}
        </button>
      </form>
      {submissionStatus === 'success' && <p className="success-message">Request submitted successfully.</p>}
      {submissionStatus === 'error' && <p className="error-message">An error occurred. Please try again.</p>}
    </main>
  );
};

export default DataRequestForm;
