import { BridgeAction, BridgeData, BridgeEvent, DTCMBridge } from './client-bridge-defs.ts';
import { createMockBridge } from './mock-bridge.ts';

const isAndroidBridgeAvailable = typeof (window as any).MP !== "undefined";
const androidBridge = isAndroidBridgeAvailable ? (window as any).MP as DTCMBridge : createMockBridge();

/**
 * This is the bridge object for communicating web -> android (or iOS?)
 */
export const bridge: DTCMBridge = {
  /**
   * Notify Android of an event
   * @param event The event type
   * @param extras Optional event data as a string (e.g., JSON)
   */
  onEvent: (event: BridgeEvent, extras?: string|object) => {
    if (androidBridge) {
      androidBridge.onEvent(event, typeof extras === 'string' ? extras : JSON.stringify(extras));
    } else {
      console.warn(`Android bridge not available. Cannot send event: ${event}`);
    }
  },

  /**
   * Request an action from Android
   * @param action The action to perform
   * @param payload The payload as an object, converted to JSON
   */
  performAction: (action: BridgeAction, payload: object|string) => {
    if (androidBridge) {
      androidBridge.performAction(action, typeof payload === 'string' ? payload : JSON.stringify(payload));
    } else {
      console.warn(`Android bridge not available. Cannot perform action: ${action}`);
    }
  },

  /**
   * Request data from Android
   * @param data The data type to retrieve
   * @returns Parsed JSON object or raw string if parsing fails
   */
  getData: (data: BridgeData): object | string => {
    if (androidBridge) {
      const result = androidBridge.getData(data);
      return typeof result === 'object' ? result : JSON.parse(result)
    } else {
      console.warn(`Android bridge not available. Cannot get data: ${data}`);
      return {};
    }
  },

  /**
   * Get list of supported actions
   * @returns Array of action names
   */
  getSupportedActions: (): string[] => {
    if (androidBridge) {
      const result = androidBridge.getSupportedActions();
      return Array.isArray(result) ? result : JSON.parse(result); // Assumes a JSON array of strings
    } else {
      console.warn("Android bridge not available. Cannot get supported actions");
      return [];
    }
  },

  /**
   * Save data on Android
   * @param tag Identifier for the data
   * @param payload Data to save as an object
   */
  saveData: (tag: string, payload: object|string) => {
    if (androidBridge) {
      androidBridge.saveData(tag, typeof payload === 'string' ? JSON.stringify(payload) : payload);
    } else {
      console.warn(`Android bridge not available. Cannot save data with tag: ${tag}`);
    }
  },

  /**
   * Log a message to Android
   * @param message The message to log
   */
  log: (message: string) => {
    if (androidBridge) {
      androidBridge.log(message);
    } else {
      console.log(`[Bridge Log] ${message}`);
    }
  },

  /**
   * Get the bridge version
   * @returns Version string
   */
  getBridgeVersion: (): string => {
    if (androidBridge) {
      return androidBridge.getBridgeVersion();
    } else {
      console.warn("Android bridge not available. Cannot get bridge version");
      return "unknown";
    }
  }
};


export interface DTCMWeb {
  onBridgeEvent(eventName: string, extras: string): void;
}

type BridgeEventHandler = (eventName: string, extras: string) => void;

const bridgeEventListeners: BridgeEventHandler[] = [];

/**
 * Add a listener for bridge events from Android
 */
export function addBridgeEventListener(listener: BridgeEventHandler) {
  bridgeEventListeners.push(listener);
}

/**
 * Remove a listener
 */
export function removeBridgeEventListener(listener: BridgeEventHandler) {
  const index = bridgeEventListeners.indexOf(listener);
  if (index !== -1) {
    bridgeEventListeners.splice(index, 1);
  }
}

(window as any).DTCMWeb = {
  onBridgeEvent: function(eventName: string, extras: string) {
    // Dispatch the event to all registered listeners
    bridgeEventListeners.forEach(listener => listener(eventName, extras));
  }
};
