import {IAdTag, IAdTagResponce} from "../../pages/Root/services/api/ads/interfaces.ts";
import {IAdMapData, IAdParameter, IGeneralSize} from "../../pages/Root/pages/HomeRoot/interfaces.ts";

export const refreshAdd = (adUnitId: string) => {
    window.googletag = window.googletag || { cmd: [] };
    window.googletag.cmd.push(async function () {
        const slot = window.gptadslots ? window.gptadslots[`ad_unit_${adUnitId}`] : {};
        window.googletag.display(`ad_unit_${adUnitId}`);

        if (slot) {
            if (
                typeof window.pubwise !== 'undefined' &&
                window.pubwise.enabled === true
            ) {
                window.pbjs.que.push(function () {
                    slot.pwUseAdunitName = 1;
                    window.pwRegisterLazyLoad(slot, 2, [50, 0, 50, 0], 0, 768, 2);
                });
            } else {
                window.googletag.pubads().refresh([slot]);
            }
        }
    });
};

export const initAds = (parameterList: IAdParameter[]) => {
    window.googletag = window.googletag || { cmd: [] };
    window.googletag.cmd.push(function () {
        parameterList.forEach(({ parameter, parameterValue }) => {
            window.googletag.pubads().setTargeting(parameter, parameterValue);
        });

        window.googletag.pubads().disableInitialLoad();
        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();
    });
}

export const defineAdSlot = (
    adDataResult: IAdTag,
    adUnitId: string,
    size: IGeneralSize,
    responsiveSizeList?: IAdMapData[]
) => {
    if (window.gptadslots && !window.gptadslots[`ad_unit_${adUnitId}`]) {
        window.googletag.cmd.push(function () {
            const newSlot = window.googletag.defineSlot(
                adDataResult?.unitPath,
                size,
                `ad_unit_${adUnitId}`
            );

            if (responsiveSizeList?.length) {
                const preMapping = googletag.sizeMapping();

                responsiveSizeList.forEach(({ adSizeList, screenSize }) => {
                    preMapping.addSize(screenSize, adSizeList);
                });

                const mapping = preMapping.build();
                if (newSlot && mapping) {
                    newSlot.defineSizeMapping(mapping);
                }
            }

            window.gptadslots[`ad_unit_${adUnitId}`] = newSlot;
            if (newSlot) {
                newSlot.setForceSafeFrame(true).addService(window.googletag.pubads());
            }
        });
    }

    refreshAdd(adUnitId);
};

export const cleanUpAds = () => {
    for (const slot in window.gptadslots) {
        if (window.gptadslots[slot]) {
            console.debug(`Removing ad slot ${slot}`)
            window.googletag.destroySlots([window.gptadslots[slot]]);
            delete window.gptadslots[slot];
        }
    }
}

export const enum BrandGroups {
    moment = 'YofriD'
}

export const DEFAULT_MOMENT_UTM_SOURCE = 'mp-moment';
export const DEFAULT_MOMENT_BRAND_ID = 'YofriD';