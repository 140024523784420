import React, { FC, useMemo } from 'react';

import './button.css';

export interface ButtonProps {
  /** Is this the principal call to action on the page? */
  primary?: boolean;
  /** What background color to use */
  backgroundColor?: string;
  /** What text color to use */
  textColor?: string;
  /** Button class */
  className?: string;
  /** How large should the button be? */
  size?: 'small' | 'medium' | 'large';
  /** Button contents */
  label: string;
  /** Optional click handler */
  onClick?: () => void;
}

/** Primary UI component for user interaction */
export const Button: FC<ButtonProps> = ({
  primary = false,
  size = 'medium',
  backgroundColor,
  textColor,
  className,
  label,
  ...props
}: ButtonProps) => {

  const mode = useMemo(() => {
    return primary
      ? 'storybook-button--primary'
      : 'storybook-button--secondary';
  }, [primary]);

  const classList = useMemo(() => {
    return [
      'storybook-button',
      `storybook-button--${size}`,
      mode,
      className,
    ].join(' ');
  }, [mode, className, size]);

  const styleList = useMemo(() => {
    return { backgroundColor, color: textColor };
  }, [backgroundColor, textColor]);

  return (
    <button
      type="button"
      className={classList}
      style={styleList}
      {...props}
    >
      {label}
    </button>
  );
};
