import React, { createContext, useContext } from 'react';
import { bridge } from './client-bridge.ts';
import { DTCMBridge } from './client-bridge-defs.ts';


const BridgeContext = createContext<DTCMBridge>(bridge);

// Custom Hook to Access Bridge
export const useBridge = (): DTCMBridge => {
  const bridge = useContext(BridgeContext);
  if (!bridge) {
    throw new Error('useBridge must be used within a BridgeProvider');
  }
  return bridge;
};

export const BridgeProvider: React.FC<{ children: React.ReactNode; }> = ({ children}) => {
  return <BridgeContext.Provider value={bridge}>{children}</BridgeContext.Provider>;
}
;
