import {
  JSX,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import lookBookApiService from '../../services/api/lookBook';
import {
  extractRecircId,
  getInterestBackgroundImg,
  getWithOutSpecialChars,
} from '../../utils';
import gtm, {
  IEventData,
  emitPageViewEvent,
  getDNSMI,
  getRequiredLookEventParams,
} from '../../../../utils/gtm';
import {
  IActionTypes,
  ILookBookState,
  actionList,
  lookBookInitialArg,
  lookBookReducer,
} from './reducer';
import { LOOKBOOK_PARAMETERS_TO_COPY } from './contants';
import { IAdData, IFormattedLook, IFormattedLookBook } from './interfaces';
import {
  addLookBookI18NextResources,
  fixBrokenViews,
  getIsHashEmpty,
  getSliderIdFromHash,
  getSlugForLookbook,
  getValidTranslationLang,
} from './utils';
import {
  IDailyLookBook,
  ILook,
  ILookBook,
} from '../../services/api/lookBook/interfaces';
import Carousel from './components/Carousel/Carousel';
import {
  INTEREST_SECTION_UID_QUERY,
} from '../../utils/constants';
import {
  LOOKBOOK_ROUTE_PATH,
  NOT_FOUND_PAGE_ROUTE_PATH,
} from '../../router/constants';
import LookbookSkeleton from './components/LookbookSkeleton/LookbookSkeleton';
import { useFeature } from '../../ContextWrapper/WithFeatureConfig/hooks';
import { useResize } from '../../hooks';
import { useFotoscapeNavigate } from '../../../../hooks/navigation';
import { LATEST_SCHEDULE } from '../HomeRoot/constants';
import {
  eventTypes, STORE_IS_REGISTRED,
  STORE_LAST_MONTH_USER_VISITS,
  VISITS_DURING_DECLINE_SUBSCRIPTION
} from '../../../../utils/constants';
import {
  IServerDataContext,
  ServerDataContext,
} from '../../../../server-side-logic/ServerDataContext.ts';
import { InterestContext } from '../../ContextWrapper/WithInterest/InterestContext.ts';
import adsAPIService from '../../services/api/ads';
import Icon from '../../../../components/Icon/Icon.tsx';
import { IconTypes } from '../../../../components/Icon/constants.ts';

import './index.scss';
import localstorage from "../../services/storage/localstorage.ts";
import {ISubscribeFormData} from "../../../../components/Subscribe/interface.ts";
import talkbackApiService from "../../services/api/talkback";
import Subscribe from "../../../../components/Subscribe/Subscribe.tsx";

let prevVersion: () => void;

const Lookbook: () => JSX.Element = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams();
  const navigate = useFotoscapeNavigate();
  const { t } = useTranslation();
  const [cookies, setCookies, removeCookies] = useCookies();
  const { hash } = useLocation();
  const { isMobile } = useResize();

  const { lookbookData: serverlookbookContext } =
    useContext<IServerDataContext>(ServerDataContext);
  const { interestMap } = useContext(InterestContext);

  const lookBookServerData = useMemo(() => {
    return serverlookbookContext?.lookbook;
  }, [serverlookbookContext]);

  const serverAdData = useMemo(() => {
    return serverlookbookContext?.adScriptList?.length
      ? serverlookbookContext?.adScriptList[0]
      : null;
  }, [serverlookbookContext]);

  const [adData, setAdData] = useState(serverAdData);

  const [isShowSubscribe, setIsShowSubscribe] = useState(false);

  const fetchLookBook = useCallback(
    async (lookBookId: string) => {
      try {
        const article = (
          await lookBookApiService.getLookBook(lookBookId, {
            language: i18next.language,
          })
        ).lookbook;

        if (article) {
          const checkedArticle = fixBrokenViews(article);

          addLookBookI18NextResources(checkedArticle);

          lookBookDispatch({
            type: actionList.setLookBook,
            payload: checkedArticle,
          });
        }
      } catch (e) {
        console.warn(e);
        navigate(NOT_FOUND_PAGE_ROUTE_PATH);
      }
    },
    [navigate]
  );

  const recircServerData = useMemo(() => {
    return serverlookbookContext?.recirc || [];
  }, [serverlookbookContext]);

  const getReduserInitialState = () => {
    let defaultConfig = {
      ...lookBookInitialArg,
    };

    if (params.id === lookBookServerData?.uid) {
      if (lookBookServerData) {
        defaultConfig = {
          ...defaultConfig,
          lookBook: {
            ...defaultConfig.lookBook,
            lookBookId: lookBookServerData.uid,
            itemList: {
              [lookBookServerData.uid]: lookBookServerData,
            },
            limit: {
              min: 0,
              max: lookBookServerData.plan.views.length,
            },
          },
        };
      }

      if (recircServerData) {
        defaultConfig = {
          ...defaultConfig,
          recirc: {
            ...defaultConfig.recirc,
            recircList: recircServerData,
          },
        };
      }
    }

    return defaultConfig;
  };

  useEffect(() => {
    if (
      params.id &&
      (!lookBookServerData || lookBookServerData?.uid !== params.id)
    ) {
      fetchLookBook(params.id);
    }
  }, [params.id, fetchLookBook, lookBookServerData]);

  const [{ lookBook, recirc }, lookBookDispatch] = useReducer<
    (prevState: ILookBookState, action: IActionTypes) => ILookBookState
  >(lookBookReducer, getReduserInitialState());

  useEffect(() => {
    const { lookBookId, itemList } = lookBook;
    if (itemList[lookBookId]) {
      addLookBookI18NextResources(itemList[lookBookId]);
    }
  }, [lookBook]);

  const serverLookBookRecirc = useMemo(() => {
    return serverlookbookContext?.lookbookRecurcList || [];
  }, [serverlookbookContext]);

  const {
    getIsAutoplayAvailable,
    isInterstitialAd1PositionAvailable,
    isInterstitialAd2PositionAvailable,
    isLookBookPlayerHeaderAvailable,
    isInfinitySliderAvailable,
    isEmailSubscribeAvailable,
  } = useFeature();

  const isInterstitialAdAvailable = useMemo(() => {
    return (
      isInterstitialAd1PositionAvailable || isInterstitialAd2PositionAvailable
    );
  }, [isInterstitialAd1PositionAvailable, isInterstitialAd2PositionAvailable]);

  const adSlideFirstPosition = useMemo(() => {
    if (isInterstitialAd1PositionAvailable) {
      return 1;
    }

    if (isInterstitialAd2PositionAvailable) {
      return 2;
    }

    return 0;
  }, [isInterstitialAd1PositionAvailable, isInterstitialAd2PositionAvailable]);

  const isRecircleSlideActive = useMemo(() => {
    return !isInfinitySliderAvailable;
  }, [isInfinitySliderAvailable]);

  const isEmailSubscribeActive = useMemo(() => {
    return isEmailSubscribeAvailable;
  }, [isEmailSubscribeAvailable]);

  const hashId = useMemo(() => {
    return getSliderIdFromHash(hash);
  }, [hash]);

  const initialSlideId = useMemo<number>(() => {
    if (window?.location?.hash) {
      const hashPageId = getSliderIdFromHash(window?.location?.hash);
      if (hashPageId) {
        return hashPageId - 1;
      }
    }

    return 0;
  }, []);

  const deviceId = useMemo(() => {
    return searchParams.get('mp_mpid');
  }, [searchParams]);

  const doNotTrack = useMemo(() => {
    return cookies.mp_dnsmi === 1 || cookies.mp_dnsmi === '1';
  }, [cookies.mp_dnsmi]);

  const isNextTitleVisible = useMemo(() => {
    const { lookBookId, itemList } = lookBook;
    return itemList[lookBookId]?.plan?.views?.length === hashId - 1;
  }, [lookBook, hashId]);

  const isDotsWithBackground = useMemo(() => {
    return hashId > 1;
  }, [hashId]);

  const [isBackButtonAvailable, setIsBackButtonAvailable] = useState<boolean>(
    window?.history?.length > 1 || true
  );
  const [slideId, setSlideId] = useState(initialSlideId);
  const [slideAdData, setSlideAdData] = useState<IAdData>();
  const [isInitAdConfigSetUp, setIsInitAdConfigSetUp] = useState(false);
  const [windowHeight, setHeight] = useState(0);
  const [documentHeight, setDocumentHeight] = useState(0);
  const [isRecircNotFound, setIsRecircNotFound] = useState(
    serverLookBookRecirc.length === 0
  );

  useEffect(() => {
    const sanitizedHash = hash.replace("#", "")
    if (`${slideId}` !== sanitizedHash && sanitizedHash !== '' && slideId >= 0) {
      setSlideId(Number(sanitizedHash))
    }
  }, [hash]);

  useEffect(() => {
    const sanitizedHash = hash.replace("#", "")
    if (`${slideId}` !== sanitizedHash && sanitizedHash !== '' && slideId >= 0) {
      navigate({ hash: `#${slideId}` },  { replace: false });
    }
  }, [slideId]);


  const [currentLookbookRecirc, setCurrentLookbookRecirc] =
    useState<IDailyLookBook[]>(serverLookBookRecirc);

  const carouselHeight = useMemo(() => {
    const height = windowHeight !== documentHeight ? windowHeight : undefined;

    if (isLookBookPlayerHeaderAvailable) {
      if (isMobile) {
        return windowHeight - 65;
      }

      return windowHeight - 63;
    }

    return height;
  }, [windowHeight, documentHeight, isMobile, isLookBookPlayerHeaderAvailable]);

  const formattedLooks = useMemo<
    (IFormattedLook | IFormattedLookBook)[]
  >(() => {
    const { itemList } = lookBook;
    const looksList: (IFormattedLook | IFormattedLookBook)[] = [];

    Object.keys(itemList).forEach((key) => {
      const { uid, plan, defaultAdTag, ownedBy, looks } = itemList[key];
      const language = getValidTranslationLang(itemList[key], i18next.language);

      looksList.push({
        ...itemList[key],
        language,
        pageIndex: 0,
        lookbookUid: uid,
        description: t(`${uid}.summary`, {
          lng: language,
          defaultValue: '',
        }),
      });

      const lookList: IFormattedLook[] = plan.views.map(({ panes }, index) => {
        const [firstPlan, secondPlan] = panes;

        const look: ILook = looks.find((lookItem) => {
          return (
            lookItem.uid === firstPlan?.lookUid ||
            lookItem.uid === secondPlan?.lookUid
          );
        }) as ILook;

        return {
          ...look,
          defaultAdTag,
          ownedBy,
          language,
          lookbookUid: uid,
          pageIndex: index + 1,
          description: t(`${look.uid}.description`, {
            lng: language,
            defaultValue: '',
          }),
        };
      });

      looksList.push(...lookList);
    });

    return looksList;
  }, [lookBook, t, params]);

  const currentLang = useMemo(() => {
    const { lookBookId, itemList } = lookBook;

    return formattedLooks.find((item) => {
      return item.lookbookUid === itemList[lookBookId]?.uid;
    })?.language;
  }, [lookBook, formattedLooks]);

  const beforeSlideChange = async (
    currentSlideId: number,
    nextSlideId: number
  ) => {
    setSlideId(nextSlideId);

    const { lookBookId, limit } = lookBook;
    const { recircIndex, recircList } = recirc;

    if (!params.title) {
      const title = getSlugForLookbook(lookBook.itemList[lookBookId]);
      navigate(
        {
          pathname: `/lookbook/${lookBookId}/${title}`,
          search: searchParams.toString(),
          hash: window.location.hash,
        },
        { replace: false }
      );
    }

    if (isRecircleSlideActive) {
      return;
    }


    const resetRecircle = async () => {
      const { lookbooks } = await lookBookApiService.getRecirc(lookBookId);
      lookBookDispatch({
        type: actionList.setRecircLookBookList,
        payload: lookbooks,
      });
      lookBookDispatch({ type: actionList.setRecircIndex, payload: 0 });
      const id = lookbooks[0]?.uid || extractRecircId(lookbooks[0].link);
      await fetchLookBook(id);
    };

    const takeNextRecircleLookBook = async () => {
      lookBookDispatch({
        type: actionList.setRecircIndex,
        payload: recircIndex + 1,
      });

      const checkedArticle = fixBrokenViews(serverLookBookRecirc[recircIndex]);
      addLookBookI18NextResources(checkedArticle);

      lookBookDispatch({
        type: actionList.setLookBook,
        payload: checkedArticle,
      });
    };

    const takeFirstRecircleData = async () => {
      lookBookDispatch({
        type: actionList.setRecircIndex,
        payload: recircIndex + 1,
      });

      const checkedArticle = fixBrokenViews(serverLookBookRecirc[0]);
      addLookBookI18NextResources(checkedArticle);

      lookBookDispatch({
        type: actionList.setLookBook,
        payload: checkedArticle,
      });
    };

    if (nextSlideId === limit.max - 1 || nextSlideId === limit.max) {
      if (recircIndex === 0) {
        await takeFirstRecircleData();
      } else if (recircIndex === recircList.length) {
        // TODO: Review the logic
        await resetRecircle();
      } else {
        await takeNextRecircleLookBook();
      }
    }
  };

  const handleResize = () => {
    setHeight(window.innerHeight);
    setDocumentHeight(document.documentElement.offsetHeight);
  };

  const setUpAdInitConfig = useCallback(() => {
    window.googletag = window.googletag || { cmd: [] };
    window.googletag.cmd.push(function () {
      const pubads = window.googletag.pubads();

      for (const parameter of LOOKBOOK_PARAMETERS_TO_COPY) {
        const parameterValue = searchParams.get(parameter);
        if (parameterValue) {
          pubads.setTargeting(parameter, parameterValue);
        }
      }
      window.googletag.enableServices();

      pubads.setTargeting('mp_contentid', lookBook.lookBookId);
      pubads.enableSingleRequest();
      pubads.disableInitialLoad();
      window.googletag.enableServices();
    });
  }, [lookBook.lookBookId, searchParams]);

  const setPubCommonIdCookies = useCallback(() => {
    let pcidCookieName = null;
    let pcidCookieValue = null;

    try {
      // Get the cookie name from pbjs configs
      const pbjs = window.pbjs;

      if (pbjs.getConfig) {
        const pbjsConfig = pbjs.getConfig().userSync.userIds;

        const pbjsConfigIndex = pbjsConfig.findIndex(function (item: any) {
          return item.name === 'pubCommonId';
        });

        // If no config present, return
        if (pbjsConfigIndex === -1) {
          console.warn('WARNING: pubCommonId config not found');
          return;
        }
        pcidCookieName = pbjsConfig[pbjsConfigIndex].storage.name;

        // If the cookie does not already exist, write it
        pcidCookieValue = cookies[pcidCookieName];
      }
    } catch (err) {
      console.error(err);
      return;
    }

    if (doNotTrack) {
      removeCookies(pcidCookieName);
      console.log('DNSMI detected - bypass pubwise deviceid tracking.');
      return;
    }

    // We need to retrieve the mpid from the querystring.
    // If it's not there or is null, we don't want to use it.
    if (!deviceId || deviceId === 'null') {
      console.log("mp_mpid not available. Can't override the pubCommonId");
      return;
    }

    try {
      if (!pcidCookieValue) {
        setCookies(pcidCookieName, deviceId);
        // If it exists and has the wrong value, update it
      } else if (pcidCookieValue !== deviceId) {
        setCookies(pcidCookieName, deviceId);
      }
    } catch (err) {
      console.error(err);
    }
  }, [cookies, deviceId, doNotTrack, removeCookies, setCookies]);

  const setUpMpidCookies = useCallback(() => {
    setCookies('mp_mpid', deviceId, {
      expires: new Date(25 * 365),
      domain: 'fotoscapes.com',
      path: '/',
    });
  }, [deviceId, setCookies]);

  const setUpAdsAndCookies = useCallback(() => {
    setPubCommonIdCookies();
    setUpMpidCookies();

    if (!doNotTrack) {
      setUpAdInitConfig();
      setIsInitAdConfigSetUp(true);
    }
  }, [setPubCommonIdCookies, setUpMpidCookies, doNotTrack, setUpAdInitConfig]);

  const emitGTMEvent = useCallback(
    (index: number, eventType: string) => {
      const look = formattedLooks?.[index];
      const activeLookbook = lookBook.itemList[look?.lookbookUid];

      if (activeLookbook) {
        const { ownedBy, uid, interests, lbtype } = activeLookbook;
        const { mp_dnsmi } = cookies;

        const eventData: IEventData = {
          event: eventType,
          title: t(`${uid}.title`, {
            lng: look.language,
            defaultValue: '',
          }),
          content_owner: ownedBy.name,
          id: uid,
          language: look.language,
          page_number: window.location.hash,
          primary_interest: interests[0]?.name + ':' + interests[0].uid,
          type: lbtype,
          dnsmi: mp_dnsmi === '1',
          ...getRequiredLookEventParams(searchParams),
        };

        gtm.emit(eventData);
      }
    },
    [lookBook.itemList, cookies, t, formattedLooks, searchParams]
  );

  const afterChange = (slideId: number) => {
    emitGTMEvent(slideId, eventTypes.swipe);
  };

  const goToNextSlide = useCallback(() => {
    setSlideId((id: number) => {
      return id + 1;
    });
  }, []);

  const backgroundType = useMemo(() => {
    const { itemList, lookBookId } = lookBook;
    const activeLookbook = itemList[lookBookId];
    if (activeLookbook) {
      const interestUID =
        searchParams.get(INTEREST_SECTION_UID_QUERY) ||
        activeLookbook?.interests[0].uid;

      const interest = interestMap.get(interestUID);

      return interest?.uid || '';
    }

    return '';
  }, [lookBook, interestMap, searchParams]);

  const backgroundImage = useMemo(() => {
    const { itemList, lookBookId } = lookBook;

    const interestUID =
      searchParams.get(INTEREST_SECTION_UID_QUERY) ||
      itemList[lookBookId]?.interests[0].uid;
    const interest = interestMap.get(interestUID);

    if (interest) {
      return getInterestBackgroundImg(interest);
    }

    return '';
  }, [interestMap, lookBook, searchParams]);

  const isAutoplayAvailable = useMemo(() => {
    const { itemList, lookBookId } = lookBook;
    const activeLookBook = itemList[lookBookId];

    if (activeLookBook) {
      const isAutoplay = getIsAutoplayAvailable(
        activeLookBook.lbtype,
        formattedLooks.length
      );

      console.debug(
        `isAutoplay=${isAutoplay}, type=${activeLookBook.lbtype}, length=${formattedLooks.length}`
      );

      return isAutoplay;
    }

    return false;
  }, [getIsAutoplayAvailable, formattedLooks, lookBook]);

  useEffect(() => {
    const lookBookId = params.id;
    if (lookBookId) {
      setCurrentLookbookRecirc([]);
      lookBookDispatch({ type: actionList.reset });
      lookBookDispatch({ type: actionList.setLookBookId, payload: lookBookId });
    }
  }, [params.id]);

  useEffect(() => {
    const { lookBookId, itemList } = lookBook;
    if (lookBookId && Object.keys(itemList).length === 0) {
      // noinspection JSIgnoredPromiseFromCall
      fetchLookBook(lookBookId);
    }
  }, [fetchLookBook, lookBook]);

  const getAds = useCallback(async () => {
    const { itemList, lookBookId } = lookBook;
    const activeLookbook = itemList[lookBookId];

    setUpAdsAndCookies();

    if (adData && !getDNSMI() && activeLookbook) {
      setSlideAdData({
        lookbookUID: activeLookbook.uid,
        ad: adData,
      });
    }
  }, [setUpAdsAndCookies, adData, lookBook]);

  const activeLookbook = useMemo(() => {
    const { itemList, lookBookId } = lookBook;
    return itemList[lookBookId];
  }, [lookBook]);

  useEffect(() => {
    if (adData) {
      getAds();
    } else if (activeLookbook) {
      const utmSource = searchParams.get('utm_source');

      adsAPIService
        .getAds(activeLookbook.ownedBy?.brandGroup, { utmSource })
        .then((data) => {
          setAdData(data.adTag);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookBook, adData, searchParams, activeLookbook]);

  useEffect(() => {
    setTimeout(() => {
      setHeight(window.innerHeight);
      setDocumentHeight(document.documentElement.offsetHeight);
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, 300);

    setIsBackButtonAvailable(window?.history?.length > 0);
  }, []);

  const emitViewEvent = (lookbook: ILookBook) => {
    const { ownedBy, uid, interests, lbtype } = lookbook;
    const { mp_dnsmi } = cookies;

    const eventData: Record<string, string | boolean> = {
      title: t(`${uid}.title`),
      content_owner: ownedBy.name,
      id: uid,
      language: formattedLooks[0]?.language || '',
      page_number: window.location.hash,
      primary_interest: interests[0]?.name + ':' + interests[0].uid,
      type: lbtype,
      dnsmi: mp_dnsmi === '1',
    };

    emitPageViewEvent(eventData, searchParams);
  };

  useEffect(() => {
    if (lookBook.lookBookId && lookBook.itemList[lookBook.lookBookId]) {
      emitViewEvent(lookBook.itemList[lookBook.lookBookId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookBook]);

  const redirectToPrevPage = () => {
      window.history.back();
  };

  useEffect(() => {
    const { itemList } = lookBook;
    const uid =
      formattedLooks[slideId]?.lookbookUid || formattedLooks[slideId]?.uid;
    const activeLookBook = itemList[uid];

    const fistLookbookSlideIndex = formattedLooks.findIndex((item) => {
      return item.lookbookUid === uid;
    });

    const nextHashIndex =
      slideId + (fistLookbookSlideIndex === -1 ? 0 : fistLookbookSlideIndex);

    const nextHash = `#${nextHashIndex}`;

    if (formattedLooks.length && !isRecircleSlideActive) {
      if (uid) {
        const pathName = LOOKBOOK_ROUTE_PATH.replace(':id', uid).replace(
          ':title?',
          getWithOutSpecialChars(activeLookBook.title.en)
        );

        if (hash !== nextHash) {
          navigate(
            {
              hash: nextHash,
              pathname: pathName,
              search: searchParams.toString(),
            },
            {
              replace: false,
            }
          );
        }
      }
    }

    //   TODO: the same outbrain
    if (isRecircleSlideActive) {
      if (hash !== nextHash && hash !== '') {
        navigate(
          {
            hash: `#${slideId + 1}`,
            search: searchParams.toString(),
          },
          {
            replace: getIsHashEmpty(),
          }
        );
      } else if (hash === '') {
        navigate(
          {
            hash: `#0`,
            search: searchParams.toString(),
          },
          {
            replace: true,
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedLooks.length]);

  const resetState = () => {
    setIsBackButtonAvailable(false);
    setSlideId(0);
    setAdData(null);
    setIsInitAdConfigSetUp(false);
    setHeight(0);
    setDocumentHeight(0);
    setCurrentLookbookRecirc([]);
    lookBookDispatch({ type: actionList.reset });
  };

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  const getRecircLookBookList = async () => {
    try {
      const { items } =
        await lookBookApiService.getDailyLookbookList(LATEST_SCHEDULE);

      setIsRecircNotFound(false);

      if (items.length === 0) {
        setIsRecircNotFound(true);
      }
      setCurrentLookbookRecirc(items);
    } catch (e: any) {
      setIsRecircNotFound(true);
      console.log(e);
    }
  };

  const onRecircSelected = ({ uid, title }: IDailyLookBook) => {
    lookBookDispatch({ type: actionList.reset });
    lookBookDispatch({ type: actionList.setLookBookId, payload: uid });
    setCurrentLookbookRecirc([]);
    navigate(
      {
        pathname: LOOKBOOK_ROUTE_PATH.replace(':id', uid).replace(
          ':title?',
          getWithOutSpecialChars(title.en)
        ),
        search: searchParams.toString(),
        hash: '#0',
      },
      { replace: false }
    );
    fetchLookBook(uid);
  };

  useEffect(() => {
    const { itemList, lookBookId } = lookBook;
    const activeLookBook = itemList[lookBookId];

    if (
      isRecircleSlideActive &&
      activeLookBook &&
      !currentLookbookRecirc.length
    ) {
      getRecircLookBookList();
    }
  }, [isRecircleSlideActive, lookBook, currentLookbookRecirc]);

  const onSlideSlideScrolled = useCallback(() => {
    emitGTMEvent(slideId, eventTypes.dtScroll);
  }, [slideId, emitGTMEvent]);

  useEffect(() => {
    const lookBookId = params.id;
    if (lookBookId) {
      lookBookDispatch({ type: actionList.setLookBookId, payload: lookBookId });
    }
  }, [params.id]);

  const closeSubscribe = useCallback(() => {
    const visitList = localstorage?.get(STORE_LAST_MONTH_USER_VISITS) || [];
    localstorage?.set(VISITS_DURING_DECLINE_SUBSCRIPTION, visitList.length);

    setIsShowSubscribe(false);
  }, []);

  const onSubscribeSubmit = useCallback(
      async ({ name, signups }: ISubscribeFormData) => {
        try {
          await talkbackApiService.updateTalkback({ name, signups });
          localstorage?.set(STORE_IS_REGISTRED, true);
        } catch (e: any) {
          console.error(e);
        }
        setIsShowSubscribe(false);
      },
      []
  );

  useEffect(() => {
    const visitList = localstorage?.get(STORE_LAST_MONTH_USER_VISITS) || [];
    const visitsOnDecline =
        localstorage?.get(VISITS_DURING_DECLINE_SUBSCRIPTION) || 0;
    const isRegistered = localstorage?.get(STORE_IS_REGISTRED);

    // since users can visit the site and never see the recirculation page, we want to
    // display if it is their 4th visit or higher and then reset the counter
    if (
        !isRegistered &&
        visitList.length >= 4 &&
        (
          (visitList.length - visitsOnDecline) % 4 === 0 ||
          visitList.length - visitsOnDecline > 4
        )
    ) {
      setIsShowSubscribe(true);
    }
  }, []);

  return (
    <div className="lookbook">

      { isRecircleSlideActive && isEmailSubscribeActive && formattedLooks && Object.values(formattedLooks).length && slideId === formattedLooks.length? (
          <Subscribe
              isVisible={isShowSubscribe}
              onSubmit={onSubscribeSubmit}
              onClose={closeSubscribe}
          />
      ) : null }

      {isBackButtonAvailable ? (
        <button className="lookbook__back-button" onClick={redirectToPrevPage}>
          <Icon type={IconTypes.back} />
        </button>
      ) : null}

      {formattedLooks && Object.values(formattedLooks).length ? (
        <Carousel
          isShowAdSlide={isInterstitialAdAvailable}
          isAutoplay={isAutoplayAvailable}
          afterChange={afterChange}
          adSlideFirstPosition={adSlideFirstPosition}
          lookBook={lookBook}
          slideId={slideId}
          isRecircNotFound={isRecircNotFound}
          currentLang={currentLang}
          backgroundImage={backgroundImage}
          backgroundType={backgroundType}
          isDotsWithBackground={isDotsWithBackground}
          beforeChange={beforeSlideChange}
          formattedLooks={formattedLooks}
          isDotsWithTitle={isNextTitleVisible}
          height={carouselHeight}
          adData={slideAdData}
          doNotTrack={doNotTrack}
          isInitAdConfigSetUp={isInitAdConfigSetUp}
          goToNextSlide={goToNextSlide}
          recircList={currentLookbookRecirc}
          interestMap={interestMap}
          isRecircSlideActive={isRecircleSlideActive}
          onRecircSelected={onRecircSelected}
          onSwipeOut={redirectToPrevPage}
          onSlideScrolled={onSlideSlideScrolled}
        />
      ) : (
        <LookbookSkeleton />
      )}
    </div>
  );
};

export default Lookbook;
