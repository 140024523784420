import { PropsWithChildren } from 'react';

import { IImageData } from '../../../pages/Root/services/api/interfaces.ts';

export enum cutoutSize {
  lg = 'lg',
}

export interface ICutout extends PropsWithChildren {
  text?: string;
  imageList: IImageData[];
  size?: cutoutSize;
  imageClassName?: string;
  contentClassName?: string;
}