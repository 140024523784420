import { BridgeAction, BridgeData, BridgeEvent, DTCMBridge } from './client-bridge-defs.ts';

export const createMockBridge = (): DTCMBridge => ({
  onEvent: (event: BridgeEvent, extras?: string | object) => {
    console.log(`Mock onEvent: ${event}`, extras);
  },
  performAction: (action: BridgeAction, payloadJson: string) => {
    console.log(`Mock performAction: ${action}`, payloadJson);
  },
  getData: (data: BridgeData): string => {
    console.log(`Mock getData: ${data}`);
    return JSON.stringify({ mock: true, data });
  },
  getSupportedActions: (): string => {
    console.log('Mock getSupportedActions');
    return JSON.stringify(['SHARE_URL', 'OPEN_PAGE']);
  },
  saveData: (tag: string, payloadJson: string) => {
    console.log(`Mock saveData: ${tag}`, payloadJson);
  },
  log: (message: string) => {
    console.log(`Mock log: ${message}`);
  },
  getBridgeVersion: (): string => {
    console.log('Mock getBridgeVersion');
    return 'mock-1.0';
  },
});
