import { CSSProperties, FC, MouseEvent, useMemo } from 'react';

import { ISingleCardProps, IWrapperProps } from './interfaces';
import Image from '../../../../../../components/Image/Image';
import FotoscapeLink from '../../../../../../components/FotoscapeLink/FotoscapeLink';
import TextParser from '../../../../components/TextParser/TextParser';

import './index.scss';
import eventBus from '../../../../../../utils/eventsbus.ts';
import { BridgeInternalEvents } from '../../../../../../moment/bridge/bridge-internal-event-defs.ts';

const Wrapper: FC<IWrapperProps> = ({
  link,
  className,
  styleList,
  children,
  state,
  onClick
}) => {
  // By default, we can't work with mouse move on link el.
  const onMouseDown = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
  };

  if (link) {
    return (
      <FotoscapeLink
        className={className}
        style={styleList}
        to={link}
        state={state}
        onMouseDown={onMouseDown}
        onClick={onClick}
      >
        {children}
      </FotoscapeLink>
    );
  }

  return (
    <div className={className} style={styleList}>
      {children}
    </div>
  );
};

const SingleCard: FC<ISingleCardProps> = ({
  title,
  interest,
  imgList,
  description,
  descriptionLineCount = 2,
  logoURL,
  link,
  owner,
  classList,
  uid,
  interestId
}) => {
  const styleList = useMemo(() => {
    return {
      '--descriptionLineCount': descriptionLineCount,
    } as CSSProperties;
  }, [descriptionLineCount]);

  const className = useMemo(() => {
    const list = ['single-card', 'card-animation'];

    if (classList) {
      list.push(classList);
    }

    return list.join(' ');
  }, [classList]);

  const handleSingleCardClick = () => {
    eventBus.emit(BridgeInternalEvents.LINK_CLICK, {
      id: uid,
      interestId: interestId || ''
    })
  }

  return (
    <Wrapper className={className} styleList={styleList} link={link} onClick={handleSingleCardClick}>
      <>
        <div className="single-card__image-wrapper card-animation__image-wapper">
          <Image
            className="single-card__img card-animation__image"
            alt="story"
            imageList={imgList}
          />
          {logoURL ? (
            <Image
              className="single-card__logo"
              alt="logo"
              src={logoURL}
            ></Image>
          ) : null}
        </div>
        <div className="single-card__content">
          {interest ? (
            <div className="single-card__interest label--m">{interest}</div>
          ) : null}
          <div className="single-card__title card-animation__text f-headline--m f-headline--m--bold">
            {title}
          </div>
          {description ? (
            <div className="single-card__description card-animation__text">
              <TextParser
                text={description}
                option={{ paragraphClass: 'paragraph--xs' }}
              />
            </div>
          ) : null}

          {owner ? (
            <div className="single-card__owner paragraph--xs">{owner}</div>
          ) : null}
        </div>
      </>
    </Wrapper>
  );
};

export default SingleCard;
