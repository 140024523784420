import { FC } from 'react';

import StorySmallSkeleton from '../StorySmallSkeleton/StorySmallSkeleton.tsx';

const OutbrainSkeleton: FC = () => {
  return (
    <div className="outbrain-skeleton">
      <StorySmallSkeleton />
      <StorySmallSkeleton />
      <StorySmallSkeleton />
      <StorySmallSkeleton />
    </div>
  )
}

export default OutbrainSkeleton;