import { API } from './api.ts';
import { AxiosInstance, AxiosRequestConfig, AxiosStatic } from 'axios';

export class ClientApi extends API {

  private instance: AxiosInstance;

  // ADD interface based on fotoscape API
  constructor(axios: AxiosStatic, config: any) {
    super()
    this.instance = axios.create({ baseURL: config.baseURL });
    this.initInterceptors(this.instance);
  }

  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.instance.get(url, config);
  }

  put<T = any>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.instance.put(url, data, config);
  }

}
