
/***
 * Note - the json files are hardcoded here for 2 reasons -
 * 1. On Client side we can't iterate directories
 * 2. Even if we could iterate, we want the bundler to optimize.
 */

import feed from './FeedLayoutConfiguration.json'
import games from './GamesLayoutConfiguration.json'
import galleries from './GalleriesLayoutConfiguration.json'

const feeds = [
  feed,
  games,
  galleries,
]


export interface LayoutConfiguration {
  type?: string,
  component?: string,
  settings?: Record<string, string>,
}


export interface LayoutConfigurationPage {
  schedule: string
  path: string
  title: string
  description: string
  layoutConfiguration: LayoutConfiguration[]
}

export const feedConfigurationPages = (): LayoutConfigurationPage[] => {
  return feeds as LayoutConfigurationPage[]
}
