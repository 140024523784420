import React from "react";

import Image from '../../containers/BestImageContainer/BestImageContainer.tsx';
import { IImageData } from '../../../pages/Root/services/api/interfaces.ts';

import "./StorySmall.scss";

interface StorySmallProps {
  imageList: IImageData[];
  category: string;
  title: string;
  publisherLogo: string;
  publisherName: string;
}

const StorySmall: React.FC<StorySmallProps> = ({ imageList, category, title, publisherLogo, publisherName }) => {

  return (
    <div className="story-small">
      <div className="small-story-image-container">
        <Image
          targetWidth={114}
          targetHeight={118}
          alt={title}
          className="story-image-sm"
          sizeHint={'small'}
          images={imageList}
        />
      </div>
      <div className="story-body">
        {publisherLogo && (
          <div className="story-small__logo-wrapper">
            <img
              src={publisherLogo}
              alt={publisherName}
              className="story-small__image"
            />
          </div>
        )}
        <h2 className="story-title">{title}</h2>
        <span className="story-category">{category}</span>
      </div>
    </div>
  );
};

export default StorySmall;
