import './DisplayAd.scss';
import {useTranslation} from "react-i18next";
import React, {FunctionComponent, memo, useCallback, useEffect, useState} from "react";
import {AdConfig, IAdTagResponce} from "../../../pages/Root/services/api/ads/interfaces.ts";
import {BrandGroups, DEFAULT_MOMENT_BRAND_ID, DEFAULT_MOMENT_UTM_SOURCE, defineAdSlot} from "../../utils/ads-util.ts";
import {
    DISPLAY_AD_DESKTOP_AD_MAP_DATA, DISPLAY_AD_MOBILE_AD_MAP_DATA,
    DISPLAY_AD_TABLED_AD_MAP_DATA,
    DISPLAY_AD_TABLED_SMALL_AD_MAP_DATA
} from "./constants.ts";
import { useQuery } from '@tanstack/react-query';
import {generateFetchAdQuery} from "../../net/ad-fetch-query.ts";
import {useSearchParams} from "react-router-dom";
import localstorage from '../../../pages/Root/services/storage/localstorage.ts';
import AdSkeleton from '../AdSkeleton/AdSkeleton.tsx';

export interface DisplayAdProps {
    adUnitId: string,
    adType: 'top'|'bot'
}

const size = [
    ...DISPLAY_AD_DESKTOP_AD_MAP_DATA.adSizeList,
    ...DISPLAY_AD_TABLED_AD_MAP_DATA.adSizeList,
    ...DISPLAY_AD_TABLED_SMALL_AD_MAP_DATA.adSizeList,
    ...DISPLAY_AD_MOBILE_AD_MAP_DATA.adSizeList,
];

const responsiveSize = [
    DISPLAY_AD_DESKTOP_AD_MAP_DATA,
    DISPLAY_AD_TABLED_AD_MAP_DATA,
    DISPLAY_AD_TABLED_SMALL_AD_MAP_DATA,
    DISPLAY_AD_MOBILE_AD_MAP_DATA,
];


export const DisplayAd = (props: DisplayAdProps) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [adLoaded, setAdLoaded] = useState(false);

    const utmSource: string = searchParams.get('utm_source') || DEFAULT_MOMENT_UTM_SOURCE;
    const partner = searchParams.get('partner');
    const brandGroupId: string = partner && Object.keys(BrandGroups).includes(partner) ? BrandGroups[partner] : DEFAULT_MOMENT_BRAND_ID;
    const [isDoNotSell] = React.useState<boolean>(localstorage?.get("MP_DNSI") || false);

    const adQueryConstruct = {
        queryKey: [brandGroupId, utmSource],
        queryFn: generateFetchAdQuery(brandGroupId, utmSource)
    };

    const { data } = useQuery<IAdTagResponce>(adQueryConstruct);

    const renderAd = useCallback((adConfig: AdConfig) => {
        if (adConfig && !isDoNotSell) {
            let adDataType;
            switch(props.adType) {
                case 'top': adDataType = adConfig.topAd; break;
                case 'bot': adDataType = adConfig.bottomAd; break;
                default:
                    console.warn(`Invalid ad type '${props.adType}', defaulting to top`);
                    adDataType = adConfig.topAd;
            }
            console.log(`defining ad slot ${props.adUnitId}`)
            defineAdSlot(adDataType, props.adUnitId, size, responsiveSize);
        }
    }, []);

    useEffect(() => {
        if (data && !adLoaded) {
            renderAd(new AdConfig(data));
            setAdLoaded(true);
        }
    }, [data]);

    return (
      <div className="ad--block">
        {!isDoNotSell && (<div className="ad--title">{t('advertisement')}</div>)}
        <div key="ad" className="feed__ad" id={`ad_unit_${props.adUnitId}`}>
          <AdSkeleton />
        </div>
      </div>
    );
};
