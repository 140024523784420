import { IResponse } from '../interfaces';

export interface IAdTag {
  pubwisePreScript?: string;
  pubwiseScript?: string;
  unitPath: string;
  urlParam?: string;
  urlValue?: string;
}

export interface IAdTagIds {
  adTag: string
}

export interface IAdTagTypes {
  [key: string]: IAdTagIds
}

export interface IAdTags {
  urlParam?: string;
  urlValue?: string;
  adTags: IAdTagTypes;
  prescript?: string;
  script?: string;
}

export interface IAdTagResponce extends IResponse {
  adTag: IAdTag;
  adTags: IAdTags;
}

export class AdConfig {
  topAd: IAdTag | null
  bottomAd: IAdTag | null
  adhesionAd: IAdTag | null
  adTag: IAdTag | null

  constructor(config: IAdTagResponce) {
    if (!config) {
      console.error(`Ad config null`);
      return;
    }
    this.topAd = this.getAdDataByType(config, 'top')
    this.bottomAd = this.getAdDataByType(config, 'bot')
    this.adhesionAd = this.getAdDataByType(config, 'adh')
    this.adTag = config.adTag
  }

  getAdDataByType = (adConfig: IAdTagResponce, adType: 'top'|'bot'|'adh'):IAdTag|null => {
    if (!adConfig) {
      console.error(`Ad config null`);
      return null;
    }
    if (!adType || !adConfig.adTags?.adTags[adType]?.adTag) {
      return null;
    }
    const adTag:IAdTag = {
      urlParam: adConfig.adTags.urlParam,
      urlValue: adConfig.adTags.urlValue,
      unitPath: adConfig.adTags.adTags[adType].adTag,
      pubwisePreScript: adConfig.adTags.prescript,
      pubwiseScript: adConfig.adTags.script
    };
    return adTag;
  }
}