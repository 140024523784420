import { FC } from 'react';
import { Button } from '../../../stories/Button.tsx';

import Cutout from '../Cutout/Cutout.tsx';
import { IImageData } from '../../../pages/Root/services/api/interfaces.ts';

import './GameLarge.scss';

interface IGameLargeProps {
  title: string;
  buttonText: string;
  imageList: IImageData[]
  onClick?: () => void;
}

const GameLarge: FC<IGameLargeProps> = ({ title, buttonText, imageList, onClick,  }) => {
  return (
    <div className="game-large">
      <Cutout imageList={imageList}>
        <div className="game-large__title">{title}</div>
      </Cutout>
      <Button
        className="game-large__button"
        primary
        textColor="#0727E5"
        backgroundColor="#C8D0FB"
        onClick={onClick}
        label={buttonText}
      ></Button>
    </div>
  );
};

export default GameLarge;
