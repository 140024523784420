import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const RESOURCE = {
  en: {
    translation: {
      nextUp: 'Next Up',
      readTheStory: 'Read the story',
      swipeLeftForMore: 'Swipe left for more',
      interestTitle: 'Explore the world in galleries',
      interestspageSubTitle:
        'From top stories to stay informed,<1> to beautiful photo galleries to inspire you.<1>',
      ourLatestPicks: 'Our Latest Picks',
      seeMore: 'See more',
      popularGalleries: 'Popular Galleries',
      discoverTopicsThatInterestYou: 'Discover Topics That Interest You',
      more: 'More',
      thanksForSigningUp: 'Thanks for Signing Up!',
      moreInspiringPhotos:
        'More inspiring photos and stories from FotoScapes are coming to your inbox soon.',
      curatedCollectionsDeliveredtoYourInbox:
        'Curated Collections Delivered to Your Inbox',
      discoverMoreInspiringPhotos:
        'Discover more inspiring photos and stories from FotoScapes.',
      signUp: 'Sign Up',
      footerPPAndTS:
        'I would like to receive top content and other updates from FotoScapes. By signing up, you agree to our <1>Terms of Use</1> and acknowledge that you have read our <3>Privacy Policy</3>.',
      everydayLifestyle: 'Everyday lifestyle for curious people.',
      followUs: 'Follow Us',
      galleries: 'Galleries',
      sneakers: 'Sneakers',
      celebrityHomes: 'Celebrity Homes',
      games: 'Games',
      termsOfService: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      managePrivacy: 'Manage Privacy',
      doNotSellMyPersonalInformation: 'Do Not Sell My Personal Information',
      aboutUs: 'About Us',
      contactUs: 'Contact Us',
      popularTopics: 'Popular Topics',
      home: 'Home',
      'fashion&Sneakers': 'Fashion & Sneakers',
      travelDestinations: 'Travel Destinations',
      close: 'Close',
      gameTitle: 'Playable Games',
      gameSubtitle:
        'Kick back and enjoy some of our favorite playable games. Each one only takes a few minutes to explore. We add games weekly, so check back regularly to improve your high scores and try out new games.',
      footerTerms:
        'Yes! I’d like to receive occasional communications from Fotoscapes. By submitting this form, I agree to this <1>privacy policy</1>',
      stories: 'Stories',
      fotoSCAPESSExclusive: 'FotoSCAPES Exclusive Stories',
      diveDeep: 'Dive deep into our most noteworthy topics',
      youMayLike: 'You May Like',
      relatedStories: 'Related Stories',
      lifestyle: 'lifestyle',
      culture: 'culture',
      travel: 'travel',
      entertainment: 'entertainment',
      latest: 'latest',
      digitalTurbineTermsOfUse: 'Digital Turbine Terms of Use',
      noticeAtCollectionAndPrivacyPolicy:
        'Notice at Collection and Privacy Policy',
      readyToLevelUp: 'Ready to level up?',
      signUpForOur:
        'Sign up for our newsletter and unlock exclusive, life-changing guides!',
      chooseYourFavorite:
        'Choose your favorite—or select all three—and start transforming your life today:',
      lifeHacks: 'Life Hacks',
      styleTips: 'Style Tips',
      travelGuides: 'Travel Guides',
      emailAddress: 'Email Address',
      enterYourEmail: 'Enter your email',
      bySubscriber:
        'By subscribing, you agree to our <1>Terms of Use</1> and <3>Privacy Policy</3>.',
      submit: 'Submit',
      notNow: 'Not now',
      haveIdeasOn: 'Have ideas on how we can improve?',
      sendThemOurWay: 'Send them our way!',
      ourTeamIsDedicated: 'Our team is dedicated to improving your experience.',
      sendUsAMessage: 'Send us a message',
      letUsKnow: 'Let us know how we’re doing',
      yourOpinionMatters: 'Your opinion matters!',
      feedback: 'Feedback',
      recommendedForYou: 'Recommended For You.',
      do_not_sell_title: 'DO NOT SHARE OR SELL MY PERSONAL INFORMATION & LIMIT THE USE OF MY SENSITIVE PERSONAL INFORMATION',
      intro_paragraph1: 'As detailed in our <1>privacy policy</1>, we allow our Ad monetization partners to collect personal information related to you on our Services. We do not receive monetary consideration for allowing these third parties to collect personal information on our Services, but such collection may be considered as the sale or sharing of personal information. Such personal information may include sensitive personal information (within the meaning of US Privacy Laws), such as geo-location (GPS) data.',
      intro_paragraph2: 'For further details on what personal information related to you is collected by such third parties, please see our <1>privacy policy</1>.',
      notices_description: 'Accordingly, we provide these Notices of Right to Opt-out of Sale/Sharing and Right Limit (collectively: "<strong>Notices</strong>") that describe your rights under applicable federal and state laws and regulations of the United States governing the protection of privacy and personal data ("<strong>US Privacy Laws</strong>") to:',
      opt_out_description: 'opt-out of the sale or sharing (within the meaning of US Privacy Laws) of personal information related to you by us and our third-party Ad monetization partners; and,',
      limit_use_description: 'limit our use of Sensitive Personal Information (within the meaning of US Privacy Laws) only to specified purposes permitted under US Privacy Laws.',
      notices_explanation: 'These Notices also explain how you can exercise these rights and enable you to do so.',
      rights_description: 'Description of the rights:',
      rights_under_us_laws: 'Under US State Privacy Laws, consumers have, among others, the following rights:',
      right_to_opt_out: 'Right to Opt-out',
      opt_out_explanation: 'You have the right, at any time, to direct us not to sell or share the consumer’s personal information.',
      right_to_limit_use: 'Right to Limit the Use',
      limit_use_explanation1: 'You have',
      limit_use_explanation2: 'the right, at any time, to direct us to limit our use of sensitive personal information related to you, and direct our Ad monetization partners to do the same, only to the following purposes:',
      service_performance: 'To perform the Services that we provide you;',
      business_purposes: 'For the following business purposes (within the meaning of US Privacy Laws):',
      security_and_integrity: 'Helping to ensure our or the Services’ security and integrity, to the extent that the use of sensitive personal information related to you by us is reasonably necessary and proportionate to achieve this purpose.',
      short_term_use: 'For short-term, transient use, including, but not limited to, non-personalized advertising shown as part of your current interaction with us or our Services, provided that we do not disclose such sensitive personal information related to another third party and we do not use such information to build a profile about you or otherwise alter your experience outside your current interaction with us.',
      performing_services: 'Performing services on our behalf, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing financing, providing analytic services, providing storage, or providing similar services on our behalf.',
      quality_and_safety: 'Undertaking activities to verify or maintain the quality or safety of our Service, and to improve, upgrade, or enhance the Services.',
      other_purposes: 'Any other purpose permitted, now or in the future, by US Privacy Laws or regulations issued pursuant thereto.',
      exercising_rights: 'Exercising your rights',
      opt_out_instructions: 'To exercise your Right to Opt-out, please click the "Do Not Sell or Share" button below. Once you click on the button, you will see a "check mark" confirming that we have received your "Do Not Sell or Share" request and have acted accordingly.',
      limit_use_instructions: 'To exercise your Right to Limit the Use, please click the "Limit the use of sensitive personal information related to me" button below. Once you click on the button, you will see a "check mark" confirming that we have received your "Limit the use of sensitive personal information related to me" request and have acted accordingly.',
      reauthorization: 'If you decide to exercise your Rights to Opt-out and Limit the Use, we may after twelve (12) months ask you to reauthorize the sale or sharing of personal information related to you, or the use of sensitive personal information related to you for purposes that go beyond those detailed above. For more details, please see our <1>Notice at Collection and Privacy Policy</1>.',
      do_not_sell_label: 'Do not sell or Share personal information related to me',
      do_not_sell_caption: 'This confirms your "do not sell my information" request. Requests typically take about 24 hours to activate.',
      tosOptIn: 'Moment utilizes cellular data. By activating The Moment app, I confirm that I am 13+ years of age and have read and agree to the <1>Terms of Service</1>',
      getStarted: 'Get Started',
      optInHeading: 'The world’s latest news captured <1>in the moment.</1>'
    },
  },
  es: {
    translation: {
      nextUp: 'Siguiente',
      readTheStory: 'Leer Más',
      swipeLeftForMore: 'Desliza a la izquierda',
      interestTitle: 'Explora el mundo en galerías',
      interestspageSubTitle:
        'Desde noticias destacadas para mantenerte <1>informado hasta hermosas galerías de fotos para inspirarte</1>',
      ourLatestPicks: 'Nuestras últimas selecciones',
      seeMore: 'Ver más',
      popularGalleries: 'Galerías populares',
      discoverTopicsThatInterestYou: '',
      more: 'Más',
      thanksForSigningUp: '¡Gracias por registrarte!',
      moreInspiringPhotos:
        'Pronto llegarán a su bandeja de entrada más fotos e historias inspiradoras de FotoScapes.',
      curatedCollectionsDeliveredtoYourInbox:
        'Colecciones seleccionadas enviadas a su bandeja de entrada.',
      discoverMoreInspiringPhotos:
        'Descubra más fotografías e historias inspiradoras de Fotoscapes',
      signUp: 'Inscribirse',
      footerPPAndTS:
        'Me gustaría recibir el mejor contenido y otras actualizaciones de FotoScapes. Al registrarse, acepta nuestros <1>Términos</1> de uso y reconoce que ha leído nuestra <3>Política</3> de privacidad.',
      everydayLifestyle: 'Estilo de vida cotidiano para gente curiosa.',
      followUs: 'Síganos',
      galleries: 'Galerías',
      sneakers: 'Zapatillas',
      celebrityHomes: 'Casas de celebridades',
      games: 'Juegos',
      termsOfService: 'Términos de servicio',
      privacyPolicy: 'Política de privacidad',
      managePrivacy: 'Administrar privacidad',
      doNotSellMyPersonalInformation: 'No vendas mi información personal',
      aboutUs: 'Sobre nosotros',
      contactUs: 'Contacta con nosotros',
      popularTopics: 'Temas populares',
      home: 'Hogar',
      'fashion&Sneakers': 'Moda y zapatillas',
      travelDestinations: 'Destinos de viaje',
      close: 'Cerca',
      gameSubtitle:
        'Relájate y disfruta de algunos de nuestros juegos jugables favoritos. Cada uno solo toma unos minutos para explorar. Agregamos juegos semanalmente, así que visítenos periódicamente para mejorar sus puntuaciones más altas y probar juegos nuevos.',
      footerTerms:
        '¡Sí! Me gustaría recibir comunicaciones ocasionales de Fotoscapes. Al enviar este formulario, acepto esta <1>política de privacidad</1>',
      stories: 'Cuentos',
      fotoSCAPESSExclusive: 'Historias exclusivas de FotoSCAPES',
      diveDeep: 'Profundice en nuestros temas más destacados.',
      youMayLike: 'Te podría gustar',
      relatedStories: 'Historias similares',
      lifestyle: 'estilo de vida',
      culture: 'cultura',
      travel: 'viaje',
      entertainment: 'entretenimiento',
      latest: 'novedades',
      digitalTurbineTermsOfUse: 'Términos de Uso de Digital Turbine',
      noticeAtCollectionAndPrivacyPolicy:
        'Aviso de Recopilación y Política de Privacidad',
      readyToLevelUp: '¿Listo para subir de nivel',
      signUpForOur:
        'Suscríbete a nuestro boletín y desbloquea guías exclusivas que cambiarán tu vida!',
      chooseYourFavorite:
        'Elige tu favorito, o selecciona los tres, y comienza a transformar tu vida hoy mismo:',
      lifeHacks: 'Trucos de Vida',
      styleTips: 'Consejos de Estilo',
      travelGuides: 'Guías de viaje',
      emailAddress: 'Dirección de correo electrónico',
      enterYourEmail: 'Introduce tu correo electrónico',
      bySubscriber:
        'Al suscribirte, aceptas nuestros <1>Términos de Uso</1> y <3>Política de Privacidad</3>.',
      submit: 'Enviar',
      notNow: 'No ahora',
      haveIdeasOn: '¿Tienes ideas sobre cómo podemos mejorar',
      sendThemOurWay: '¡Envíanos tus ideas',
      ourTeamIsDedicated:
        'Nuestro equipo está dedicado a mejorar tu experiencia.',
      sendUsAMessage: 'Envíanos un mensaje',
      letUsKnow: 'Haznos saber cómo lo estamos haciendo',
      yourOpinionMatters: '¡Tu opinión importa',
      feedback: 'Comentarios',
      recommendedForYou: 'Recomendado para ti.',
      tosOptIn: "Moment utiliza datos celulares. Al activar la aplicación Moment, confirmo que tengo más de 13 años y he leído y acepto los <1>términos del servicio</1>",
      getStarted: 'Get Started',
      optInHeading: 'SÉ EL PRIMERO en recibir noticias y actualizaciones con nuestra aplicación GRATUITA.',
      do_not_sell_title: 'NO VENDER MI INFORMACIÓN PERSONAL Y LIMITAR EL USO DE MI INFORMACIÓN PERSONAL SENSIBLE',
      intro_paragraph1: 'Como se detalla en nuestra <1>política de privacidad</1>, permitimos la monetización de nuestros anuncios. socios para recopilar información personal relacionada con usted en nuestros Servicios. No recibimos ninguna contraprestación monetaria por permitir que estos terceros recopilen información personal en nuestros Servicios, pero dicha recopilación puede considerarse como la venta o el intercambio de información personal. Dicha información personal puede incluir información personal confidencial (dentro del significado de las leyes de privacidad de EE. UU.), como datos de ubicación geográfica (GPS).',
      intro_paragraph2: 'Para obtener más detalles sobre qué información personal relacionada con usted recopilan dichos terceros, consulte nuestra <1>política de privacidad</1>.',
      notices_description: 'En consecuencia, proporcionamos estos Avisos de derecho a excluirse de la venta/compartición y límite de derechos (colectivamente: "<strong>Avisos</strong>") que describen sus derechos según las leyes y regulaciones federales y estatales aplicables del Estados Unidos que rige la protección de la privacidad y los datos personales ("<strong>Leyes de Privacidad de EE. UU.</strong>") a:',
      opt_out_description: 'optar por no vender o compartir (dentro del significado de las leyes de privacidad de EE. UU.) información personal relacionada con usted por parte nuestra y nuestros socios externos de monetización de anuncios; y,',
      limit_use_description: 'limitar nuestro uso de información personal confidencial (dentro del significado de las leyes de privacidad de EE. UU.) solo a fines específicos permitidos según las leyes de privacidad de EE. UU.',
      notices_explanation: 'Estos Avisos también explican cómo puede ejercer estos derechos y le permiten hacerlo.',
      rights_description: 'Descripción de los derechos:',
      rights_under_us_laws: 'Según las leyes de privacidad estatales de EE. UU., los consumidores tienen, entre otros, los siguientes derechos:',
      right_to_opt_out: 'Derecho a excluirse',
      opt_out_explanation: 'Tiene derecho, en cualquier momento, a indicarnos que no vendamos ni compartamos la información personal del consumidor.',
      right_to_limit_use: 'Derecho a limitar el uso',
      limit_use_explanation1: 'Tiene',
      limit_use_explanation2: 'el derecho, en cualquier momento, a indicarnos que limitemos nuestro uso de información confidencial información personal relacionada con usted y solicitar a nuestros socios de monetización de anuncios que hagan lo mismo, únicamente para los siguientes fines:',
      service_performance: 'Para realizar los Servicios que le brindamos;',
      business_purposes: 'Para los siguientes fines comerciales (dentro del significado de las leyes de privacidad de EE. UU.):',
      security_and_integrity: 'Ayudar a garantizar nuestra seguridad e integridad o la de los Servicios, en la medida en que el uso de información personal confidencial relacionada con usted por nuestra parte sea razonablemente necesario y proporcionado para lograr este propósito.',
      short_term_use: 'Para uso transitorio a corto plazo, que incluye, entre otros, publicidad no personalizada que se muestra como parte de su interacción actual con nosotros o nuestros Servicios, siempre que no revelemos dicha información personal confidencial relacionada con otro tercero y no utilizamos dicha información para crear un perfil sobre usted ni alterar su experiencia fuera de su interacción actual con nosotros.',
      performing_services: 'Realizar servicios en nuestro nombre, incluido el mantenimiento o servicio de cuentas, brindar servicio al cliente, procesar o cumplir pedidos y transacciones, verificar la información del cliente, procesar pagos, brindar financiamiento, brindar servicios analíticos, brindar almacenamiento o brindar servicios similares en nuestro nombre.',
      quality_and_safety: 'Realizar actividades para verificar o mantener la calidad o seguridad de nuestro Servicio, y para mejorar, actualizar o perfeccionar los Servicios.',
      other_purposes: 'Cualquier otro propósito permitido, ahora o en el futuro, por las leyes de privacidad de EE. UU. o las regulaciones emitidas de conformidad con las mismas.',
      exercising_rights: 'Ejercicio de tus derechos',
      opt_out_instructions: 'Para ejercer su derecho de exclusión voluntaria, haga clic en el botón "No vender ni compartir" que aparece a continuación. Una vez que haga clic en el botón, verá una "marca de verificación" que confirma que hemos recibido su solicitud de "No vender ni compartir" y que hemos actuado en consecuencia.',
      limit_use_instructions: 'Para ejercer su derecho a limitar el uso, haga clic en el botón "Limitar el uso de información personal confidencial relacionada conmigo" que aparece a continuación. Una vez que haga clic en el botón, verá una "marca de verificación" que confirma que hemos recibido su solicitud "Limitar el uso de información personal confidencial relacionada conmigo" y que hemos actuado en consecuencia.',
      reauthorization: 'Si decide ejercer sus derechos de exclusión voluntaria y limitación de uso, es posible que, después de doce (12) meses, le solicitemos que vuelva a autorizar la venta o el intercambio de información personal relacionada con usted, o el uso de información personal confidencial relacionada con usted para fines que van más allá de los detallados anteriormente. Para obtener más detalles, consulte nuestro <1>Aviso en recopilación y política de privacidad</1>.',
      do_not_sell_label: 'No venda ni comparta información personal relacionada conmigo',
      do_not_sell_caption: 'Esto confirma su solicitud de "no vender mi información". Las solicitudes de "No vender" suelen tardar 24 horas en activarse.'
    },
  },
  pt: {
    translation: {
      nextUp: 'Seguinte',
      readTheStory: 'Leia Mais',
      swipeLeftForMore: 'Deslize para a esquerda',
      interestTitle: 'Explore o mundo nas galerias',
      interestspageSubTitle:
        'De histórias em alta para manter-se informado à lindas galerias de fotos para lhe inspirar',
      ourLatestPicks: 'Nossas últimas escolhas',
      seeMore: 'Veja mais',
      popularGalleries: 'Galerias Populares',
      discoverTopicsThatInterestYou: 'Descubra Tópicos que lhe Interessam',
      more: 'Mais',
      thanksForSigningUp: 'Obrigado por se Registrar',
      moreInspiringPhotos:
        'Mais fotos inspiradores e histórias do FotoScapes vão ser enviadas ao seu email',
      curatedCollectionsDeliveredtoYourInbox:
        'Coleções selecionadas, enviadas diretamente ao seu email',
      discoverMoreInspiringPhotos:
        'Descubra mais fotos inspiradoras e histórias do FotoScapes',
      signUp: 'Registre-se',
      footerPPAndTS:
        'Eu gostaria de receber conteúdos em alta e outras atualizações do FotoScapes. Ao se registrar, você aceita os nossos <1>Termos de Uso</1> e confirma que leu nossas <3>Políticas de Privacidade</3>',
      everydayLifestyle: 'Estilo de vida diário para pessoas curiosas',
      followUs: 'Nos siga',
      galleries: 'Galerias',
      sneakers: 'Tênis',
      celebrityHomes: 'Casas das Celebridades',
      games: 'Jogos',
      termsOfService: 'Termos de Uso',
      privacyPolicy: 'Política de Privacidade',
      managePrivacy: 'Configure a sua Privacidade',
      doNotSellMyPersonalInformation: 'Não venda meus dados pessoais',
      aboutUs: 'Sobre nós',
      contactUs: 'Contate-nos',
      popularTopics: 'Tópicos Populares',
      home: 'Casa',
      'fashion&Sneakers': 'Moda & Tênis',
      travelDestinations: 'Destinos de Viagens',
      close: 'Fechar',
      gameTitle: 'Jogos',
      gameSubtitle:
        'Descanse e aproveite alguns dos nossos jogos favoritos. Cada um leva apenas alguns minutos para explorar. Nós adicionamos jogos semanalmente, então confira regularmente para bater seu recorde e experimentar novos jogos',
      footerTerms:
        'Sim! Eu gostaria de receber ocasionalmente comunicações do FotoScapes. Ao enviar esse formulário, eu aceito a <1>Política de Privacidade</1>',
      stories: 'Histórias',
      fotoSCAPESSExclusive: 'Histórias Exclusivas do FotoSCAPES',
      diveDeep: 'Se aprofunde nos nossos tópicos mais importantes',
      youMayLike: 'Você vai gostar',
      relatedStories: 'Histórias Relacionadas',
      lifestyle: 'estilo de vida',
      culture: 'cultura',
      travel: 'viagem',
      entertainment: 'entretenimento',
      latest: 'novidades',
      digitalTurbineTermsOfUse: 'Termos de Uso da Digital Turbine',
      noticeAtCollectionAndPrivacyPolicy:
        'Aviso de Coleta e Política de Privacidade',
      readyToLevelUp: 'Pronto para subir de nível?',
      signUpForOur:
        'Inscreva-se na nossa newsletter e desbloqueie guias exclusivos que mudam vidas!',
      chooseYourFavorite:
        'Escolha seu favorito — ou selecione os três — e comece a transformar sua vida hoje:',
      lifeHacks: 'Dicas de Vida',
      styleTips: 'Dicas de Estilo',
      travelGuides: 'Guias de Viagem',
      emailAddress: 'Endereço de E-mail',
      enterYourEmail: 'Digite seu e-mail',
      bySubscriber:
        'Ao se inscrever, você concorda com nossos <1>Termos de Uso</1> e <3>Política de Privacidade</3>.',
      submit: 'Enviar',
      notNow: 'Agora não',
      haveIdeasOn: 'Tem ideias de como podemos melhorar?',
      sendThemOurWay: 'Envie para nós!',
      ourTeamIsDedicated:
        'Nossa equipe está dedicada a melhorar sua experiência.',
      sendUsAMessage: 'Envie-nos uma mensagem',
      letUsKnow: 'Conte-nos como estamos indo',
      yourOpinionMatters: 'Sua opinião é importante!',
      feedback: 'Feedback',
      recommendedForYou: 'Recomendado para você.',
      tosOptIn: "Moment utiliza dados do celular. Ao ativar o aplicativo Moment, confirmo que tenho mais de 13 anos de idade e que li e concordo com os <1>Termos de Serviço</1>",
      getStarted: 'Get Started',
      optInHeading: 'As últimas notícias do mundo capturadas <1>nesse momento.</1>',
      do_not_sell_title: 'Não Venda Minhas Informações Pessoais',
      intro_paragraph1: 'Nossa Declaração de Privacidade descreve quais informações pessoais coletamos de você. De acordo com a lei de privacidade, você tem o direito de nos instruir a não compartilhar ou "vender" suas informações (o “direito de opt-out”).',
      intro_paragraph2: 'Para obter mais detalhes, consulte nossa <1>Declaração de privacidade</1>.', // Adjusted to match provided PT content
      notices_description: 'Portanto, fornecemos estes Avisos de Direito de Exclusão de Venda/Compartilhamento e Direito de Limitação (coletivamente: "<strong>Avisos</strong>") que descrevem seus direitos sob as leis e regulamentos federais e estaduais aplicáveis dos Estados Unidos que regem a proteção de privacidade e dados pessoais ("<strong>Leis de Privacidade dos EUA</strong>") para:',
      opt_out_description: 'excluir-se da venda ou compartilhamento (no sentido das Leis de Privacidade dos EUA) de informações pessoais relacionadas a você por nós e nossos parceiros terceirizados de monetização de anúncios; e,',
      limit_use_description: 'limitar nosso uso de Informações Pessoais Sensíveis (no sentido das Leis de Privacidade dos EUA) apenas a propósitos específicos permitidos pelas Leis de Privacidade dos EUA.',
      notices_explanation: 'Estes Avisos também explicam como você pode exercer esses direitos e permitem que você o faça.',
      rights_description: 'Descrição dos direitos:',
      rights_under_us_laws: 'Sob as Leis de Privacidade Estaduais dos EUA, os consumidores têm, entre outros, os seguintes direitos:',
      right_to_opt_out: 'Direito de Exclusão',
      opt_out_explanation: 'Você tem o direito, a qualquer momento, de nos instruir a não vender ou compartilhar suas informações pessoais.',
      right_to_limit_use: 'Direito de Limitar o Uso',
      limit_use_explanation1: 'Você tem',
      limit_use_explanation2: 'o direito, a qualquer momento, de nos instruir a limitar nosso uso de informações pessoais sensíveis relacionadas a você, e instruir nossos parceiros de monetização de anúncios a fazer o mesmo, apenas para os seguintes propósitos:',
      service_performance: 'Para realizar os Serviços que fornecemos a você;',
      business_purposes: 'Para os seguintes propósitos comerciais (no sentido das Leis de Privacidade dos EUA):',
      security_and_integrity: 'Ajudar a garantir a segurança e integridade nossa ou dos Serviços, na medida em que o uso de informações pessoais sensíveis relacionadas a você por nós seja razoavelmente necessário e proporcional para alcançar este propósito.',
      short_term_use: 'Para uso transitório de curto prazo, incluindo, mas não limitado a, publicidade não personalizada exibida como parte de sua interação atual conosco ou nossos Serviços, desde que não divulguemos tais informações pessoais sensíveis relacionadas a outro terceiro e não utilizemos essas informações para criar um perfil sobre você ou alterar sua experiência fora de sua interação atual conosco.',
      performing_services: 'Realizar serviços em nosso nome, incluindo manutenção ou atendimento de contas, fornecimento de atendimento ao cliente, processamento ou cumprimento de pedidos e transações, verificação de informações do cliente, processamento de pagamentos, fornecimento de financiamento, fornecimento de serviços analíticos, fornecimento de armazenamento ou fornecimento de serviços similares em nosso nome.',
      quality_and_safety: 'Realizar atividades para verificar ou manter a qualidade ou segurança de nosso Serviço, e para melhorar, atualizar ou aprimorar os Serviços.',
      other_purposes: 'Qualquer outro propósito permitido, agora ou no futuro, pelas Leis de Privacidade dos EUA ou regulamentos emitidos de acordo com elas.',
      exercising_rights: 'Exercendo seus direitos',
      opt_out_instructions: 'Para exercer seu Direito de Exclusão, clique no botão "Não Venda" abaixo. Após clicar no botão, você verá uma "marca de seleção" confirmando que ativamos sua solicitação "Não Venda".',
      limit_use_instructions: 'Para exercer seu Direito de Limitar o Uso, clique no botão "Limitar o uso de informações pessoais sensíveis relacionadas a mim" abaixo. Após clicar no botão, você verá uma "marca de seleção" confirmando que recebemos sua solicitação "Limitar o uso de informações pessoais sensíveis relacionadas a mim" e agimos de acordo.',
      reauthorization: 'Se você decidir exercer seus Direitos de Exclusão e Limitar o Uso, poderemos, após doze (12) meses, pedir que você reautorize a venda ou compartilhamento de informações pessoais relacionadas a você, ou o uso de informações pessoais sensíveis relacionadas a você para propósitos que vão além dos detalhados acima. Para mais detalhes, consulte nossa <1>Declaração de Privacidade</1>.',
      do_not_sell_label: 'Não Venda',
      do_not_sell_caption: 'Isso confirma sua solicitação "não venda minhas informações". Solicitações de "não venda" normalmente levam cerca de 24 horas para ativar.'
    },
  },
  de: {
    translation: {
      nextUp: 'Als Nächstes',
      readTheStory: 'Mehr Lesen',
      swipeLeftForMore: 'Wischen Sie nach links',
    },
  },
  fr: {
    translation: {
      nextUp: 'Prochain',
      readTheStory: 'Lire La Suite',
      swipeLeftForMore: 'Balayez vers la gauche',
    },
  },
  hi: {
    translation: {
      nextUp: 'अगला',
      readTheStory: 'और पढ़ें',
      swipeLeftForMore: ' बाएँ स्वाइप करें। (Baayein swipe karein)',
    },
  },
  it: {
    translation: {
      nextUp: 'Prossimo',
      readTheStory: 'Leggi Di Più',
      swipeLeftForMore: 'Scorri a sinistra.',
    },
  },
  tl: {
    translation: {
      nextUp: 'Susunod',
      readTheStory: 'Magbasa Pa',
      swipeLeftForMore: 'I-swipe sa kaliwa',
    },
  },
};

export const PRIMARY_LANG_LIST = ['en', 'es', 'pt'];

export const SUPPORTED_LANGS = [
  ...PRIMARY_LANG_LIST,
  'de',
  'fr',
  'hi',
  'it',
  'pt',
  'tl',
];

export const initLocale = (lang: string) => {
  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: RESOURCE,
      lng: lang.split('-')[0],
      supportedLngs: SUPPORTED_LANGS,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};

export const getTranslation = (value: Record<string, string>) => {
  const lang = resolvedLanguage();
  return value[lang] || value.en || '';
};

export const resolvedLanguage = (langFallback?: string) => {
  return i18n.resolvedLanguage || i18n.language || langFallback || 'en';
}
