import { EnhancedLightboxItem, LightboxItem } from '../../net/softbox-types.ts';
import { IInterestResponse } from '../../../pages/Root/services/api/interest/interfaces.ts';
import { ReactNode } from 'react';
import StoryCarousel from '../../components/StoryCarousel/StoryCarousel.tsx';
import { getTranslation as _t } from '../../../i18n.ts';
import StoryMedium from '../../components/StoryMedium/StoryMedium.tsx';
import StorySmall from '../../components/StorySmall/StorySmall.tsx';
import RowMultipleContainer from '../../containers/RowMultipleContainer/RowMultiplierContainer.tsx';
import { LayoutConfiguration } from '../LayoutConfiguration/layout-configuration-factory.ts';
import GamesTile from '../../components/GamesTile/GamesTile.tsx';
import GameLarge from '../../components/GameLarge/GameLarge.tsx';
import { NavigateOptions, To } from 'react-router-dom';

const constructRowMultiplierChildren = (
  settings: Record<string, string>,
  items: EnhancedLightboxItem[],
  interests: IInterestResponse,
  ClickerElement: React.FC,
  clickerPropsBuilder: (item:EnhancedLightboxItem) => Record<string, any>
) => {
  const output = [];
  if (settings.rowComposition) {
    for (const rowItem of settings.rowComposition) {
      output.push(
        createFeedComponent({ settings: {}, component: rowItem }, items, interests, ClickerElement, clickerPropsBuilder).component
      );
    }
  }
  return output;
};
export const createFeedComponent = (
  slot: LayoutConfiguration,
  items: EnhancedLightboxItem[],
  interests: IInterestResponse,
  ClickerElement: React.FC<any>,
  clickerPropsBuilder: (item:EnhancedLightboxItem) => Record<string, any>
) : {
  component: React.ReactNode
  item?: EnhancedLightboxItem
} => {

  if (items.length === 0) {
    return { component: <></>, item: undefined }
  }

  const componentType = slot.component;
  const { settings } = slot;

  let component: ReactNode;

  let item: EnhancedLightboxItem;

  switch (componentType) {
    case 'StoryCarousel':
      item = items.shift()!;
      component = (
        <StoryCarousel
          key={item.uid}
          title={_t(item.title)}
          description={_t(item.summary)}
          imageList={item.images}
          publisherLogo={item.brandLogo}
          publisherName={item.publishOn}
        />
      );
      break;
    case 'StoryMedium':
      item = items.shift()!;
      component = (
        <StoryMedium
          key={item.uid}
          title={_t(item.title)}
          description={_t(item.summary)}
          imageList={item.images}
          tags={translateInterests(item.interests, interests)}
          publisherLogo={item.brandLogo}
          publisherName={item.publishOn}
        />
      );
      break;
    case 'StorySmall':
      item = items.shift()!;
      component = (
        <StorySmall
          publisherLogo={item.brandLogo}
          publisherName={item.publishOn}
          key={item.uid}
          title={_t(item.title)}
          imageList={item.images}
          category={translateInterests(item.interests, interests).join(' ')}
        />
      );
      break;
    case 'GamesTile':
      item = items.shift()!;
      component = (<GamesTile buttonText={'Play Now'} imageList={item.images} />)
      break;
    case 'GameLarge':
      item = items.shift()!;
      component = (<GameLarge title={_t(item.title)} buttonText={'Play Now'} imageList={item.images} />)
      break;
    case 'RowMultiple':
      component = (
        <RowMultipleContainer>
          {...constructRowMultiplierChildren(settings!, items, interests, ClickerElement, clickerPropsBuilder)}
        </RowMultipleContainer>
      );
      break;
    default:
      component = <></>;
  }

  if (item) {
    component = (
      <ClickerElement {...clickerPropsBuilder(item!)}>
        {component}
      </ClickerElement>
    )
  }

  return { component, item };
};

const translateInterests = (target: string[], interests: IInterestResponse) => {
  const output = [];
  for (let i = 0; i < target.length; i++) {
    const interest = interests.interests.find((interest) => {
      return interest.uid === target[i];
    });
    if (interest) {
      output.push(_t(interest.name));
    }
  }
  return output.length > 0 ? [output[0]] : output;
};
