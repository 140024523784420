// src/components/AsyncImage/AsyncImage.tsx
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./AsyncImage.scss";

interface AsyncImageProps {
  src: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  staticSized?: boolean
}

export const AsyncImage = ({ src, alt = "", width = "100%", height = "100%", className, staticSized = false }: AsyncImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const imgRef = useRef<HTMLImageElement>(null);

  // Check if image is already loaded (e.g., cached) on mount
  useEffect(() => {
    if (imgRef.current?.complete) {
      setTimeout(() => {
        setIsLoaded(true);
      })

    }
  }, [src]); // Re-run if src changes

  const containerClass = useMemo(
    () => {return ["aimage__container", className].filter(Boolean).join(" ")},
    [className]
  );
  const imageClass = useMemo(
    () => {return ["aimage", isLoaded ? "aimage--visible" : "aimage--loading"].join(" ")},
    [isLoaded]
  );

  const imageHeight = useMemo(() => {
    return  staticSized ? height : '100%'
  }, [staticSized, height]);

  const containerStyleList = useMemo(() => {
    return {
      width: staticSized ? width : '100%',
      height: imageHeight,
      minHeight: imageHeight,
    };
  }, [staticSized, imageHeight, width]);

  return (
    <div
      className={containerClass}
      style={containerStyleList}
    >
      {!isLoaded && (
        <div className="aimage__shimmer" style={{ width, height }} />
      )}
      <img
        className={imageClass}
        ref={imgRef}
        src={src}
        alt={alt}
        width={width}
        height={imageHeight}
        onLoad={() => {
          setTimeout(() => {
            setIsLoaded(true);
          }, 3_000);
        }}
      />
    </div>
  );
};

export default AsyncImage;
