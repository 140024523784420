import React from 'react';
import './ActivityIndicator.scss';

const ActivityIndicator: React.FC = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
};

export default ActivityIndicator;
