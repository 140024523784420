import {
  CSSProperties,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import InterestCarousel from '../../../../components/InterestCarousel/InterestCarousel';
import Footer from '../../../../../../components/Footer/Footer';
import { INTEREST_HEIGHT, MOBILE_INTEREST_HEIGHT } from '../Category/constants';
import { useResize } from '../../../../hooks';
import SingleCard from '../../components/SingleCard/SingleCard';
import { getTranslation } from '../../../../../../i18n';
import {
  IServerDataContext,
  ServerDataContext,
} from '../../../../../../server-side-logic/ServerDataContext.ts';
import { InterestContext } from '../../../../ContextWrapper/WithInterest/InterestContext.ts';
import { GAMES_SCHEDULE } from '../../constants.ts';
import lookBookApiService from '../../../../services/api/lookBook';
import { getInterestBackgroundImg } from '../../../../utils';

import './index.scss';

const Games: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useResize();
  const { gamesData: serverGamesData } =
    useContext<IServerDataContext>(ServerDataContext);
  const { interestMap } = useContext(InterestContext);
  const [gameList, setGameList] = useState(serverGamesData?.gameList || []);

  const interest = useMemo(() => {
    if (gameList.length) {
      const interestGameId = gameList[0].interests[0];
      return interestMap?.get(interestGameId);
    }

    return null;
  }, [gameList, interestMap]);

  const sectionHeight = useMemo(() => {
    if (isMobile) {
      return MOBILE_INTEREST_HEIGHT;
    }

    return INTEREST_HEIGHT;
  }, [isMobile]);

  const styleList = useMemo(() => {
    const backgroundImage = interest ? getInterestBackgroundImg(interest) : '';

    return {
      '--background-image': `url(${backgroundImage})`,
      height: sectionHeight,
    } as CSSProperties;
  }, [interest, sectionHeight]);

  useEffect(() => {
    if (gameList.length === 0) {
      lookBookApiService
        .getDailyLookbookList(GAMES_SCHEDULE)
        .then((data) => {
          if (data.items.length) {
            setGameList(data.items);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [gameList]);

  return (
    <div className="games">
      {/*TODO: Move to new component*/}
      <div
        className="games__container interest-background  interest-background--with-img interest-background--e1f1GsP"
        style={styleList}
      >
        <div className="interest-background__item" />

        <h1 className="games__title">{t('gameTitle')}</h1>

        <h2 className="games__subtitle paragraph--m">{t('gameSubtitle')}</h2>

        <InterestCarousel
          isSmallView={false}
          isMobile={false}
          itemList={gameList}
          renderItem={(item) => {
            const { images, summary, title, owner, brandLogo, sourceLink } =
              item;

            return (
              <SingleCard
                uid={item.uid}
                interestId={item.interests[0]}
                link={sourceLink}
                classList="interest-carousel__cover-look"
                title={getTranslation(title)}
                imgList={images}
                descriptionLineCount={4}
                description={getTranslation(summary)}
                owner={owner}
                logoURL={brandLogo}
              />
            );
          }}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Games;
