import React, {useCallback, useEffect} from "react";
import {useSearchParams} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import './MainFeed/FeedLayout.scss'
import {LOOKBOOK_PARAMETERS_TO_COPY} from "../../pages/Root/pages/Lookbook/contants.ts";
import {
    BrandGroups,
    cleanUpAds,
    DEFAULT_MOMENT_BRAND_ID,
    DEFAULT_MOMENT_UTM_SOURCE,
    initAds
} from "../utils/ads-util.ts";
import {generateFetchAdQuery} from "../net/ad-fetch-query.ts";
import {IAdTagResponce} from "../../pages/Root/services/api/ads/interfaces.ts";
import {loadAdScripts} from "../../pages/Root/utils/ads.ts";
import localstorage from '../../pages/Root/services/storage/localstorage.ts';

export const AdInitLayout = ({props, children}) => {
    const [searchParams] = useSearchParams();

    const utmSource: string = searchParams.get('utm_source') || DEFAULT_MOMENT_UTM_SOURCE;
    const partner = searchParams.get('partner');
    const brandGroupId: string = partner && Object.keys(BrandGroups).includes(partner) ? BrandGroups[partner] : DEFAULT_MOMENT_BRAND_ID;
    const [isDoNotSell] = React.useState<boolean>(localstorage?.get("MP_DNSI") || false);

    const adQueryConstruct = {
        queryKey: [brandGroupId, utmSource],
        queryFn: generateFetchAdQuery(brandGroupId, utmSource)
    };

    const { data, isLoading } = useQuery<IAdTagResponce>(adQueryConstruct);

    const initAdParams = useCallback(() => {
        const parameterList = [];
        for (const parameter of LOOKBOOK_PARAMETERS_TO_COPY) {
            const parameterValue = searchParams.get(parameter);
            if (parameterValue) {
                parameterList.push({ parameter, parameterValue });
            }
        }
        initAds(parameterList);
    }, [searchParams]);

    useEffect(() => {
        initAdParams();

        return () => {
            cleanUpAds();
        }
    }, [initAdParams, cleanUpAds]);

    useEffect(() => {
        if (data && !isDoNotSell) {
            loadAdScripts(data.adTag)
        }
    }, [data, isLoading]);

    return (<div id="ad--init">{children}</div>);
};
