import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './DoNotSell.scss';
import localstorage from '../../../pages/Root/services/storage/localstorage.ts';
import { useBridge } from '../../bridge/bridge-provider.tsx';
import { BridgeEvent } from '../../bridge/client-bridge-defs.ts';

const DoNotSell: React.FC = () => {
  const { t } = useTranslation();

  const [isDoNotSell, setDoNotSell] = React.useState<boolean>(
    localstorage?.get('MP_DNSI') || false
  );

  const bridge = useBridge();

  const doNotSellRef = useRef();

  useEffect(() => {
    localstorage?.set('MP_DNSI', isDoNotSell);
    if (doNotSellRef.current !== undefined && doNotSellRef.current !== isDoNotSell) {
      bridge.onEvent(BridgeEvent.DO_NOT_SELL_STATE_CHANGED, {
        doNotSell: isDoNotSell,
      });
    }
    doNotSellRef.current = isDoNotSell;
  }, [isDoNotSell]);

  return (
    <main className="legal-do-not-sell">
      <header className="dnsmi__header">
        <h1 className="dnsmi__title">{t('do_not_sell_title')}</h1>
      </header>
      <section className="dnsmi__body">
        <p>
          <Trans i18nKey="intro_paragraph1">
            As detailed in our{' '}
            <a href="https://www.digitalturbine.com/notice-at-collection-and-privacy-policy-cm/">
              privacy policy
            </a>
            , we allow our Ad monetization partners to collect personal
            information related to you on our Services. We do not receive
            monetary consideration for allowing these third parties to collect
            personal information on our Services, but such collection may be
            considered as the sale or sharing of personal information. Such
            personal information may include sensitive personal information
            (within the meaning of US Privacy Laws), such as geo-location (GPS)
            data.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="intro_paragraph2">
            For further details on what personal information related to you is
            collected by such third parties, please see our{' '}
            <a href="https://www.digitalturbine.com/notice-at-collection-and-privacy-policy-cm/">
              privacy policy
            </a>
            .
          </Trans>
        </p>
        <p>
          <Trans i18nKey="notices_description">
            Accordingly, we provide these Notices of Right to Opt-out of
            Sale/Sharing and Right Limit (collectively: "
            <strong>Notices</strong>") that describe your rights under
            applicable federal and state laws and regulations of the United
            States governing the protection of privacy and personal data ("
            <strong>US Privacy Laws</strong>") to:
          </Trans>
        </p>
        <p>{t('opt_out_description')}</p>
        <p>{t('limit_use_description')}</p>
        <p>{t('notices_explanation')}</p>
        <p>
          <u>{t('rights_description')}</u>
        </p>
        <p>{t('rights_under_us_laws')}</p>
        <p>
          <strong>{t('right_to_opt_out')}</strong>: {t('opt_out_explanation')}
        </p>
        <p>
          <strong>{t('right_to_limit_use')}</strong>:{' '}
          <em>{t('limit_use_explanation1')}</em> {t('limit_use_explanation2')}
        </p>
        <ul>
          <li>{t('service_performance')}</li>
          <li>{t('business_purposes')}</li>
          <li>{t('security_and_integrity')}</li>
          <li>{t('short_term_use')}</li>
          <li>{t('performing_services')}</li>
          <li>{t('quality_and_safety')}</li>
          <li>{t('other_purposes')}</li>
        </ul>
        <p>
          <strong>{t('exercising_rights')}</strong>
        </p>
        <p>{t('opt_out_instructions')}</p>
        <p>{t('limit_use_instructions')}</p>
        <p>
          <Trans i18nKey="reauthorization">
            If you decide to exercise your Rights to Opt-out and Limit the Use,
            we may after twelve (12) months ask you to reauthorize the sale or
            sharing of personal information related to you, or the use of
            sensitive personal information related to you for purposes that go
            beyond those detailed above. For more details, please see our{' '}
            <a href="https://www.digitalturbine.com/notice-at-collection-and-privacy-policy-cm/">
              Notice at Collection and Privacy Policy
            </a>
            .
          </Trans>
        </p>
      </section>
      <section className="dnsmi__form">
        <label
          className="dnsmi__checkbox-label"
          htmlFor="dnsmi__checkbox"
          role="checkbox"
          tabIndex={0}
        >
          <input
            id="dnsmi__checkbox"
            tabIndex={-1}
            type="checkbox"
            checked={isDoNotSell}
            data-gtm-event-type="ccpa"
            data-gtm-event-category="ccpa"
            data-gtm-event-action="do not sell"
            data-gtm-event-label="toggle on"
            onChange={(event) => {
              return setDoNotSell(event.target.checked);
            }}
          />
          <span>{t('do_not_sell_label')}</span>
        </label>
        {isDoNotSell && (
          <aside className="dnsmi__checkbox-caption">
            {t('do_not_sell_caption')}
          </aside>
        )}
      </section>
    </main>
  );
};

export default DoNotSell;
