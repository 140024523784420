import { FC } from 'react';

import Image from '../../../../../../components/Image/Image';
import { IPressCardProps } from './interfaces';
import FotoscapeLink from '../../../../../../components/FotoscapeLink/FotoscapeLink';

import './index.scss';
import eventBus from '../../../../../../utils/eventsbus.ts';
import { BridgeInternalEvents } from '../../../../../../moment/bridge/bridge-internal-event-defs.ts';

const PressCard: FC<IPressCardProps> = ({
  link,
  title,
  subtitle,
  imageList,
  uid,
  interestId
}) => {

  const handleSingleCardClick = () => {
    eventBus.emit(BridgeInternalEvents.LINK_CLICK, {
      id: uid,
      interestId: interestId || ''
    })
  }

  return (
    <FotoscapeLink className="press-card card-animation" to={link} onClick={handleSingleCardClick}>
      <div className="press-card__image-wrapper card-animation__image-wapper">
        <Image
          className="press-card__image card-animation__image"
          alt="card"
          imageList={imageList}
        />
      </div>

      <div className="press-card__content">
        <div className="press-card__subtitle label--m">{subtitle}</div>
        <div className="press-card__title card-animation__text f-headline--xm">
          {title}
        </div>
      </div>
    </FotoscapeLink>
  );
};

export default PressCard;
