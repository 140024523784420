import React from "react";
import Image from "../../components/AsyncImage/AsyncImage.tsx";
import { getBestImage } from "../../utils/image-util.ts";
import { useBestImage } from "../../hooks/useBestImage";

interface BestImageContainerProps {
  images: { url?: string; link?: string; width: number; height: number }[];
  targetWidth?: number | string; // Static target for SSR
  targetHeight?: number;
  alt?: string;
  dynamic?: boolean; // Toggle client-side ref-based sizing
  staticSized?: boolean
  sizeHint: "large" | "small"
}

export const BestImageContainer = ({
                                     images,
                                     targetWidth = 0, // Default for SSR if not provided
                                     targetHeight = 0,
                                     alt,
                                     sizeHint,
                                     dynamic = !import.meta.env.SSR, // True on client, false on server
                                   }: BestImageContainerProps) => {
  const { bestSrc, imageRef } = useBestImage(images, sizeHint);

  return <Image staticSized ref={dynamic ? imageRef : undefined} src={bestSrc} alt={alt} width={targetWidth} height={targetHeight} />;
};

export default BestImageContainer;
