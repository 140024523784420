import {FC, JSX, useCallback, useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import InterestCarousel from '../../../../components/InterestCarousel/InterestCarousel';
import Footer from '../../../../../../components/Footer/Footer';
import { useResize } from '../../../../hooks';
import { STORY_ROUTE } from '../../../../router/router';
import { getWithOutSpecialChars } from '../../../../utils';
import { IDailyLookBook } from '../../../../services/api/lookBook/interfaces';
import StoryCoverCard from './components/StoryCoverCard/StoryCoverCard';
import { getInterestName } from '../../utils';
import StorySkeletonMain from './components/StorySkeletonMain/StorySkeletonMain';
import SingleCard from '../../components/SingleCard/SingleCard';
import { getTranslation } from '../../../../../../i18n';
import {
  IServerDataContext,
  ServerDataContext,
} from '../../../../../../server-side-logic/ServerDataContext.ts';
import { InterestContext } from '../../../../ContextWrapper/WithInterest/InterestContext.ts';
import lookBookApiService from '../../../../services/api/lookBook';
import {
  DEFAULT_BRAND_GROUP_ID,
  INTEREST_SCHEDULE,
  POPULAR_SCHEDULE,
} from '../../constants.ts';
import adsAPIService from '../../../../services/api/ads';
import { loadAdScripts } from '../../../../utils/ads.ts';

import './index.scss';
import {AdConfig} from "../../../../services/api/ads/interfaces.ts";

const Story: () => JSX.Element = () => {
  const { isMobile } = useResize();
  const { t } = useTranslation();
  const { storyData, commonData } =
    useContext<IServerDataContext>(ServerDataContext);
  const { interestMap } = useContext(InterestContext);
  const params = useParams();

  const [lookBook, setLookBook] = useState(storyData?.lookBook);
  const [popularList, setPopularList] = useState(storyData?.popularList || []);
  const [mayLikeList, setMayLikeList] = useState(storyData?.travelList || []);
  const [adConfig, setAdConfig ] = useState(commonData?.defaultAdData);

  const fetchTravelDailyFotoscapeLookBookList = useCallback(() => {
    lookBookApiService
      .getDailyLookbookList(INTEREST_SCHEDULE.NZf7cr, 'all')
      .then((data) => {
        if (data.items.length) {
          setMayLikeList(data.items);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const fetchPopularDailyFotoscapeLookBookList = useCallback(() => {
    lookBookApiService
      .getDailyLookbookList(POPULAR_SCHEDULE, 'all')
      .then((data) => {
        if (data.items.length) {
          setPopularList(data.items);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const getLookBook = useCallback(async (uid: string) => {
    lookBookApiService
      .getLookBook(uid)
      .then(({ lookbook }) => {
        setLookBook(lookbook);
      })
      .catch((e) => {
        return console.log(e);
      });
  }, []);

  const onStoryItemClick = useCallback(() => {
    setLookBook(null);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (popularList.length === 0) {
      fetchPopularDailyFotoscapeLookBookList();
    }
  }, [fetchPopularDailyFotoscapeLookBookList, popularList.length]);

  useEffect(() => {
    if (mayLikeList.length === 0) {
      fetchTravelDailyFotoscapeLookBookList();
    }
  }, [mayLikeList.length, fetchTravelDailyFotoscapeLookBookList]);

  useEffect(() => {
    if (params.uid && lookBook?.uid !== params.uid) {
      getLookBook(params.uid);
    }
  }, [lookBook, params.uid, getLookBook]);

  useEffect(() => {
    if (!adConfig) {
      adsAPIService
        .getAds(DEFAULT_BRAND_GROUP_ID)
        .then(async (data) => {
          await loadAdScripts(data.adTag);
          setAdConfig(new AdConfig(data));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [adConfig]);

  return (
    <div className="story">
      {lookBook && interestMap ? (
        <div className="story__main">
          <StoryCoverCard
            lookBook={lookBook}
            mayLikeList={mayLikeList}
            interestMap={interestMap}
            adTag={adConfig?.adTag}
          />
        </div>
      ) : (
        <StorySkeletonMain />
      )}
      <div className="story__related">
        <h2 className="related__title">{t('relatedStories')}</h2>

        <div className="related__gallery-wrapper">
          {isMobile ? (
            <div className="related__list">
              <div className="related__list-column">
                {popularList.slice(0, 3).map((item) => {
                  const { title, previews, uid, owner } = item;

                  return (
                    <SingleCard
                      interestId={item.interests[0]}
                      uid={uid}
                      key={uid}
                      imgList={previews}
                      title={getTranslation(title)}
                      owner={owner}
                      link={STORY_ROUTE.path.replace(':uid', uid)}
                    />
                  );
                })}
              </div>
              <div className="related__list-column">
                {popularList.slice(3, 6).map((item) => {
                  const { uid, title, owner, previews } = item;

                  return (
                    <SingleCard
                      key={uid}
                      imgList={previews}
                      title={getTranslation(title)}
                      owner={owner}
                      link={STORY_ROUTE.path.replace(':uid', uid)}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <InterestCarousel
              isSmallView={true}
              isMobile={isMobile}
              itemList={popularList}
              renderItem={({
                brandLogo,
                summary,
                title,
                interests,
                previews,
                uid,
              }: IDailyLookBook) => {
                const interestName = storyData?.interests
                  ? getInterestName(interests[0], storyData.interests)
                  : '';

                return (
                  <div onClick={onStoryItemClick}>
                    <SingleCard
                      title={getTranslation(title)}
                      imgList={previews}
                      logoURL={brandLogo}
                      interest={interestName}
                      description={getTranslation(summary)}
                      descriptionLineCount={2}
                      link={STORY_ROUTE.path
                        .replace(':uid', uid)
                        .replace(':title?', getWithOutSpecialChars(title.en))}
                    />
                  </div>
                );
              }}
            />
          )}
        </div>
      </div>
      <Footer />;
    </div>
  );
};

export default Story;
