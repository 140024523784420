import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import gtm, { getDNSMI } from './utils/gtm';
import { BrowserRouter } from 'react-router-dom';
import { ServerDataContext } from './server-side-logic/ServerDataContext.ts';
import { WithFeatureConfig } from './pages/Root/ContextWrapper/WithFeatureConfig/WithFeatureConfig';
import { initLocale } from './i18n';
import reportWebVitals from './reportWebVitals';
import { collectWebVitalMetrics } from './utils';
import { BridgeProvider } from './moment/bridge/bridge-provider.tsx';

import './index.scss';
import { hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';

if (!getDNSMI()) {
  gtm.init();
}

const container = document.getElementById('root');

const initialLanguage = window.initialLanguage || navigator.language || 'en';
initLocale(initialLanguage);
collectWebVitalMetrics();

if (container) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: (60 * 5 * 1000) + 30_000
      }
    }
  });

  const dehydratedState = window.reactQueryData;
  if (dehydratedState) {
    hydrate(queryClient, dehydratedState);
  }

  ReactDOM.hydrateRoot(
    container,
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ServerDataContext.Provider value={window.serverData}>
            <WithFeatureConfig>
              <App />
            </WithFeatureConfig>
          </ServerDataContext.Provider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  );

  reportWebVitals();
}
