import React from "react";
import "./StoryMedium.scss";
import { IImageData } from '../../../pages/Root/services/api/interfaces.ts';
import Image from '../../containers/BestImageContainer/BestImageContainer.tsx';
import { useViewportWidth } from '../../hooks/useViewportWidth.ts';

interface StoryMediumProps {
  imageList: IImageData[];
  tags: string[];
  title: string;
  description: string;
  publisherLogo?: string;
  publisherName?: string;
}

const StoryMedium: React.FC<StoryMediumProps> = ({ imageList, tags, title, description, publisherLogo, publisherName }) => {


  const vpWidth = useViewportWidth()

  return (
    <div className="story-medium">
      <div className="story-image-container">
        <Image
          targetWidth={"100%"}
          targetHeight={240}
          alt={title}
          sizeHint={"large"}
          className="story-image-med"
          images={imageList}
        />
      </div>
      {publisherLogo && <div className="story-carousel-pub-logo">
        <img src={publisherLogo} alt={publisherName} className="story-image-pub-logo" />
      </div>}
      <h2 className="story-title">{title}</h2>
      <p className="story-description">{description}</p>

      <div className="story-tags">
        {tags.map((tag, index) => (
          <span key={index} className="story-tag">
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

export default StoryMedium;
