import { FC } from 'react';

import SkeletonImage from '../../../pages/Root/components/skeletons/SkeletonImage/SkeletonImage.tsx';
import SkeletonText from '../../../pages/Root/components/skeletons/SkeletonText/SkeletonText.tsx';

import './StorySmallSkeleton.scss';

const StorySmallSkeleton: FC = () => {
  return (
    <div className="story-small-skeleton">
      <SkeletonImage />
      <div className="story-small-skeleton__main">
        <SkeletonText></SkeletonText>
        <SkeletonText isWide isLarge></SkeletonText>
      </div>
    </div>
  );
};

export default StorySmallSkeleton;
