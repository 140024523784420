import './AdhesionAd.scss';
import React, {useCallback, useEffect, useState} from "react";
import {AdConfig, IAdTagResponce} from "../../../pages/Root/services/api/ads/interfaces.ts";
import {BrandGroups, DEFAULT_MOMENT_BRAND_ID, DEFAULT_MOMENT_UTM_SOURCE, defineAdSlot} from "../../utils/ads-util.ts";
import {IAdMapData} from "../../../pages/Root/pages/HomeRoot/interfaces.ts";
import {useSearchParams} from "react-router-dom";
import {generateFetchAdQuery} from "../../net/ad-fetch-query.ts";
import {useQuery} from "@tanstack/react-query";
import localstorage from '../../../pages/Root/services/storage/localstorage.ts';

export interface AdhesionAdProps {
    adUnitId: string
}

export const AdhesionAd = (props: AdhesionAdProps) => {
    const [isVisible, setIsVisible] = useState(true);
    const [searchParams] = useSearchParams();

    const utmSource: string = searchParams.get('utm_source') || DEFAULT_MOMENT_UTM_SOURCE;
    const partner = searchParams.get('partner');
    const brandGroupId: string = partner && Object.keys(BrandGroups).includes(partner) ? BrandGroups[partner] : DEFAULT_MOMENT_BRAND_ID;
    const [isDoNotSell] = React.useState<boolean>(localstorage?.get("MP_DNSI") || false);

    const adQueryConstruct = {
        queryKey: [brandGroupId, utmSource],
        queryFn: generateFetchAdQuery(brandGroupId, utmSource)
    };

    const { data, isLoading, error } = useQuery<IAdTagResponce>(adQueryConstruct);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const getAdSize = useCallback(():IAdMapData => {
        if (window.googletag) {
            const width = window.innerWidth

            if (width >= 1200) {
                return {
                    screenSize: [1193, 0],
                    adSizeList: [[320, 50], [300, 50], [320, 100], [970, 90], [728, 90], [468, 60], 'fluid'],
                };
            } else if (width >= 992) {
                return {
                    screenSize: [970, 0],
                    adSizeList: [[320, 50], [300, 50], [320, 100], [728, 90], [970, 90], [468, 60], 'fluid'],
                };
            } else if (width >= 768) {
                return {
                    screenSize: [684, 0],
                    adSizeList: [[320, 50], [300, 50], [320, 100], [728, 90], [468, 60], 'fluid'],
                };
            } else {
                return {
                    screenSize: [320, 0],
                    adSizeList: [[320, 50], [300, 50], [320, 100], 'fluid'],
                };
            }
        } else {
            return {
                screenSize: [320, 0],
                adSizeList: [[320, 50], [300, 50], [320, 100], 'fluid'],
            };
        }
    }, []);

    const renderAd = useCallback((adConfig:AdConfig) => {
        if (adConfig?.adhesionAd && window.googletag && !isDoNotSell) {
            const responsiveSize:IAdMapData = getAdSize();
            defineAdSlot(adConfig.adhesionAd, props.adUnitId, [responsiveSize.adSizeList], [responsiveSize]);
        }
    }, [props.adUnitId, window.googetag]);

    useEffect(() => {
        if (data) {
            const adConfig:AdConfig = new AdConfig(data);
            renderAd(adConfig);
        }
    }, [renderAd, data, isLoading, error]);

    return (
        <div className="adhesion-ad--block">
            <button onClick={toggleVisibility}><span id="mp-banner-arrow-icon">{isVisible ? '▼' : '▲'}</span></button>
            <div key="ad" className={`adhesion__ad ${isVisible ? '' : 'adhesion__ad--hide'}`} id={`ad_unit_${props.adUnitId}`}></div>
        </div>
    )
}
