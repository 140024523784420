import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FotoscapeLink from '../../../components/FotoscapeLink/FotoscapeLink.tsx';
import { FOOTER_LINK_LIST } from './constants.ts';

import './Footer.scss';

const Footer: FC = () => {
  const { t } = useTranslation();
  return (
    <nav className="moment-footer">
      {FOOTER_LINK_LIST.map(({ text, link }) => {
        return (
          <div className="moment-footer__link-wrapper">
            <FotoscapeLink className="moment-footer__link" to={link}>
              {t(text)}
            </FotoscapeLink>
          </div>
        );
      })}
    </nav>
  );
};

export default Footer;
