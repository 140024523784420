import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

interface IViewportProps extends PropsWithChildren {
  onView: () => void;
  onHidden?: () => void;
  threshold?: number;
}

const InViewport: FC<IViewportProps> = ({ children, onView, threshold, onHidden }) => {
  const wrapperRef = useRef(null);
  const { inViewport } = useInViewport(wrapperRef, {
    threshold
  }, { disconnectOnLeave: true });

  useEffect(() => {
    if(inViewport) {
      onView()
    } else if(onHidden)  {
      onHidden()
    }
  }, [inViewport]);

  return <div ref={wrapperRef}>{children}</div>;
};

export default InViewport;