import {
  CSSProperties,
  FC, JSX,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import i18next from 'i18next';

import FotoscapeLink from '../../../../../../components/FotoscapeLink/FotoscapeLink';
import { getAvailableInterestList, setUpAds } from '../../utils';
import {
  INTEREST_URL,
  LOOKBOOK_ROUTE_PATH,
} from '../../../../router/constants';
import {
  CATEGORY_ROUTE,
  INTERESTS_ROUTE,
  LOOKBOOK_ROUTE,
} from '../../../../router/router';
import { IDailyLookBook } from '../../../../services/api/lookBook/interfaces';
import {
  getInterestBackgroundImg,
  getWithOutSpecialChars,
} from '../../../../utils';
import { LOOKBOOK_PARAMETERS_TO_COPY } from '../../../Lookbook/contants';
import {
  LATEST_DESKTOP_AD_MAP_DATA,
  LATEST_MOBILE_AD_MAP_DATA,
  LATEST_SMALL_TABLED_AD_MAP_DATA,
  LATEST_TABLED_AD_MAP_DATA,
  TRAVEL_DESKTOP_AD_MAP_DATA,
  TRAVEL_MOBILE_AD_MAP_DATA,
  TRAVEL_SMALL_TABLED_AD_MAP_DATA,
  TRAVEL_TABLED_AD_MAP_DATA,
} from './constants';
import InterestCarousel from '../../../../components/InterestCarousel/InterestCarousel';
import Footer from '../../../../../../components/Footer/Footer';
import InterestGallery from '../../../../components/InterestGallery/InterestGallery';
import PressCard from '../../components/PressCard/PressCard';
import { useResize } from '../../../../hooks';
import CardSkeleton from './components/CardSkeleton/CardSkeleton';
import { cardSize } from './components/CardSkeleton/interfaces';
import { getTranslation } from '../../../../../../i18n';
import { IAdParameter } from '../../interfaces';
import Lazy from '../../../../components/Lazy/Lazy';
import CoverCard from './components/CoverCard/CoverCard';
import SingleCard from '../../components/SingleCard/SingleCard';
import Video from '../../../../../Video';
import { useFeature } from '../../../../ContextWrapper/WithFeatureConfig/hooks';
import { emitPageViewEvent } from '../../../../../../utils/gtm';
import {
  IServerDataContext,
  ServerDataContext,
} from '../../../../../../server-side-logic/ServerDataContext';
import {AdConfig, IAdTag} from '../../../../services/api/ads/interfaces.ts';
import { InterestContext } from '../../../../ContextWrapper/WithInterest/InterestContext.ts';
import lookBookApiService from '../../../../services/api/lookBook';
import {
  DEFAULT_BRAND_GROUP_ID,
  HERO_SCHEDULE,
  INTEREST_SCHEDULE,
  LATEST_SCHEDULE,
  POPULAR_SCHEDULE,
} from '../../constants.ts';
import adsAPIService from '../../../../services/api/ads';
import { loadAdScripts } from '../../../../utils/ads.ts';
import Icon from '../../../../../../components/Icon/Icon.tsx';
import { IconTypes } from '../../../../../../components/Icon/constants.ts';
import Feedback from './components/Feedback';

import './index.scss';

const LATEST_AD_ID = 'latest';

const Home: () => JSX.Element = () => {
  const [searchParams] = useSearchParams();
  const { isTabled, isMobile, isDesktop } = useResize();
  const { isVideoAvailable } = useFeature();
  const { t } = useTranslation();
  const { homeData, commonData } =
    useContext<IServerDataContext>(ServerDataContext);
  const { interestMap } = useContext(InterestContext);

  const adDataSeverData = useMemo<IAdTag | null>(() => {
    return commonData?.defaultAdData?.adTag || null;
  }, [commonData]);

  const heroListServerData = useMemo(() => {
    if (homeData?.heroSchedule?.items) {
      return homeData.heroSchedule.items;
    }
    return [];
  }, [homeData]);

  const travelListServerData = useMemo(() => {
    if (homeData?.interestSchedule?.items) {
      return homeData.interestSchedule.items;
    }
    return [];
  }, [homeData]);

  const latestListServerData = useMemo(() => {
    if (homeData?.latestSchedule?.items) {
      return homeData.latestSchedule.items;
    }
    return [];
  }, [homeData]);

  const popularListServerData = useMemo(() => {
    if (homeData?.popularSchedule?.items) {
      return homeData.popularSchedule.items;
    }
    return [];
  }, [homeData]);

  const [adData, setAdData] = useState(adDataSeverData);
  const [popularList, setPopularList] = useState(popularListServerData);
  const [latestList, setLatestList] = useState(latestListServerData);
  const [travelList, setTravelList] = useState(travelListServerData);
  const [heroList, setHeroList] = useState(heroListServerData);

  const interestList = useMemo(() => {
    if (interestMap) {
      return getAvailableInterestList(interestMap).slice(0, 6);
    }

    return [];
  }, [interestMap]);

  const interestTravelSectionType = useMemo(() => {
    const TRAVEL_UID = 'NZf7cr';
    const interest = interestMap.get(TRAVEL_UID);
    const backgroundImg = interest ? getInterestBackgroundImg(interest) : '';

    return {
      uid: TRAVEL_UID,
      backgroundImage: backgroundImg,
    };
  }, [interestMap]);

  const galleryStyleList = useMemo(() => {
    return {
      '--background-image': `url(${interestTravelSectionType.backgroundImage})`,
    } as CSSProperties;
  }, [interestTravelSectionType]);

  const galleryClassList = useMemo(() => {
    return `main-galleries__interest interest-background interest-background--with-img interest-background--${interestTravelSectionType.uid}`;
  }, [interestTravelSectionType]);

  const getInterestName = useCallback(
    (interestUID: string) => {
      const name = interestMap.get(interestUID)?.name;

      if (name) {
        return getTranslation(name);
      }

      return '';
    },
    [interestMap]
  );

  const mainCoverData = useMemo(() => {
    if (heroList.length) {
      const { previews, summary, title, uid, interests } = heroList[0];

      return {
        uid,
        images: previews,
        summary: getTranslation(summary),
        title: getTranslation(title),
        titleData: title,
        interestName: getInterestName(interests[0]),
        interestId: interests[0]
      };
    }

    return null;
  }, [heroList, getInterestName]);

  const availableTravelList = useMemo(() => {
    return travelList.slice(0, isTabled ? 2 : 3);
  }, [travelList, isTabled]);

  const availableLatestList = useMemo(() => {
    return isDesktop ? latestList.slice(0, 3) : latestList.slice(0, 4);
  }, [isDesktop, latestList]);

  const getPressCardData = ({
    title,
    previews,
    uid,
    interests,
  }: IDailyLookBook) => {
    return {
      interests,
      title: title,
      uid,
      subtitle: getInterestName(interests[0]),
      imageList: previews,
    };
  };

  const renderTravelAd = useCallback(() => {
    const size = [
      ...TRAVEL_DESKTOP_AD_MAP_DATA.adSizeList,
      ...TRAVEL_TABLED_AD_MAP_DATA.adSizeList,
      ...TRAVEL_SMALL_TABLED_AD_MAP_DATA.adSizeList,
      ...TRAVEL_MOBILE_AD_MAP_DATA.adSizeList,
    ];

    const responsiveSize = [
      TRAVEL_DESKTOP_AD_MAP_DATA,
      TRAVEL_TABLED_AD_MAP_DATA,
      TRAVEL_SMALL_TABLED_AD_MAP_DATA,
      TRAVEL_MOBILE_AD_MAP_DATA,
    ];

    const parameterList: IAdParameter[] = [];

    for (const parameter of LOOKBOOK_PARAMETERS_TO_COPY) {
      const parameterValue = searchParams.get(parameter);
      if (parameterValue) {
        parameterList.push({ parameter, parameterValue });
      }
    }
    parameterList.push({ parameter: 'pathname', parameterValue: 'home' });

    if (adData) {
      setUpAds(adData, 'travel', size, parameterList, responsiveSize);
    }
  }, [adData, searchParams]);

  const renderLatestAd = useCallback(() => {
    const size = [
      ...LATEST_DESKTOP_AD_MAP_DATA.adSizeList,
      ...LATEST_TABLED_AD_MAP_DATA.adSizeList,
      ...LATEST_SMALL_TABLED_AD_MAP_DATA.adSizeList,
      ...LATEST_MOBILE_AD_MAP_DATA.adSizeList,
    ];

    const responsiveSize = [
      LATEST_DESKTOP_AD_MAP_DATA,
      LATEST_TABLED_AD_MAP_DATA,
      LATEST_SMALL_TABLED_AD_MAP_DATA,
      LATEST_MOBILE_AD_MAP_DATA,
    ];

    const parameterList: IAdParameter[] = [];

    for (const parameter of LOOKBOOK_PARAMETERS_TO_COPY) {
      const parameterValue = searchParams.get(parameter);
      if (parameterValue) {
        parameterList.push({ parameter, parameterValue });
      }
    }
    parameterList.push({ parameter: 'pathname', parameterValue: 'home' });

    if (adData) {
      setUpAds(adData, LATEST_AD_ID, size, parameterList, responsiveSize);
    }
  }, [adData, searchParams]);

  const fetchDailyLookList = async (
    category: string,
    callback: (data: IDailyLookBook[]) => void
  ) => {
    try {
      const { items } = await lookBookApiService.getDailyLookbookList(
        category,
        'all'
      );
      callback(items);
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (heroList.length) {
      const mainView = heroList[0];

      const { title, uid, lbtype, owner, interests } = mainView;
      const eventData: Record<string, any> = {
        title: title.en,
        content_owner: owner,
        id: uid,
        language: i18next.resolvedLanguage,
        primary_interest: interests[0],
        type: lbtype,
      };

      emitPageViewEvent(eventData, searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heroList[0]?.uid]);

  useEffect(() => {
    if (popularList.length === 0) {
      fetchDailyLookList(POPULAR_SCHEDULE, setPopularList);
    }
  }, [popularList]);

  useEffect(() => {
    if (latestList.length === 0) {
      fetchDailyLookList(LATEST_SCHEDULE, setLatestList);
    }
  }, [latestList]);

  useEffect(() => {
    if (travelList.length === 0) {
      fetchDailyLookList(INTEREST_SCHEDULE.NZf7cr, setTravelList);
    }
  }, [travelList]);

  useEffect(() => {
    if (heroList.length === 0) {
      fetchDailyLookList(HERO_SCHEDULE, setHeroList);
    }
  }, [heroList]);

  useEffect(() => {
    if (!adData) {
      adsAPIService
        .getAds(DEFAULT_BRAND_GROUP_ID)
        .then((data) => {
          setAdData(data.adTag);
          if (data && data.adTag) {
            loadAdScripts(data.adTag);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [adData]);

  return (
    <div className="home">
      <main className="home__main">
        <section className="home__main-section">
          <div className="main-section__wrapper">
            <div className="main-section__content">
              <div className="main-section__content-item">
                {mainCoverData ? (
                  <CoverCard
                    interestId={mainCoverData.interestId}
                    lookbookUID={mainCoverData.uid}
                    imageList={mainCoverData.images}
                    title={mainCoverData.title}
                    description={mainCoverData.summary}
                    interestName={mainCoverData.interestName}
                  />
                ) : (
                  <CardSkeleton size={cardSize.large} />
                )}

                <div className="main-section__latest">
                  {isMobile ? (
                    <Lazy
                      className="ad-latest--mobile"
                      onRender={renderLatestAd}
                    >
                      <div id="ad_unit_latest"></div>
                    </Lazy>
                  ) : null}
                </div>

                <Feedback />
              </div>

              <div className="main-section__offers">
                {isVideoAvailable ? (
                  <Video />
                ) : heroList[1] ? (
                  <SingleCard
                    link={LOOKBOOK_ROUTE_PATH.replace(
                      ':id',
                      heroList[1].uid
                    ).replace(
                      ':title?',
                      getWithOutSpecialChars(heroList[1].title.en)
                    )}
                    title={getTranslation(heroList[1].title)}
                    description={getTranslation(heroList[1].summary)}
                    descriptionLineCount={2}
                    logoURL={heroList[1].brandLogo}
                    imgList={heroList[1].previews}
                    interest={getInterestName(heroList[1].interests[0])}
                    interestId={heroList[1].interests[0]}
                    uid={heroList[1].uid}
                  />
                ) : (
                  <CardSkeleton />
                )}

                {heroList[2] ? (
                  <SingleCard
                    link={LOOKBOOK_ROUTE.path
                      .replace(':id', heroList[2].uid)
                      .replace(
                        ':title?',
                        getWithOutSpecialChars(heroList[2].title.en)
                      )}
                    title={getTranslation(heroList[2].title)}
                    description={getTranslation(heroList[2].summary)}
                    descriptionLineCount={2}
                    logoURL={heroList[2].brandLogo}
                    imgList={heroList[2].previews}
                    interest={getInterestName(heroList[2].interests[0])}
                    interestId={heroList[2].interests[0]}
                    uid={heroList[2].uid}
                  />
                ) : (
                  <CardSkeleton />
                )}
              </div>
            </div>
            <Lazy className="main-section__latest" onRender={renderLatestAd}>
              <h2 className="main-section__latest-header f-headline--xxxxl">
                {t('ourLatestPicks')}
              </h2>

              <div className="main-section__latest-container">
                {availableLatestList.length ? (
                  availableLatestList.map((item) => {
                    const { imageList, title, subtitle, uid, interests } =
                      getPressCardData(item);

                    return (
                      <PressCard
                        uid={uid}
                        interestId={interests[0]}
                        key={uid}
                        link={LOOKBOOK_ROUTE.path
                          .replace(':id', uid)
                          .replace(
                            ':title?',
                            getWithOutSpecialChars(title?.en)
                          )}
                        title={getTranslation(title)}
                        subtitle={subtitle}
                        imageList={imageList}
                      />
                    );
                  })
                ) : (
                  <>
                    <CardSkeleton size={cardSize.small} />
                    <CardSkeleton size={cardSize.small} />
                    <CardSkeleton size={cardSize.small} />
                    <CardSkeleton size={cardSize.small} />
                  </>
                )}
              </div>

              {isDesktop || isTabled ? (
                <div className="ad-latest">
                  <div id="ad_unit_latest"></div>
                </div>
              ) : null}
            </Lazy>
          </div>
        </section>

        <section className="home__main-galleries">
          <Lazy
            className={galleryClassList}
            styleList={galleryStyleList}
            onRender={renderTravelAd}
          >
            <div className="interest-background__item" />

            <div className="main-galleries__interest-header">
              <h2 className="main-galleries__interest-title f-headline--xxxxl">
                {t('travelGuides')}
              </h2>
              <FotoscapeLink
                className="main-galleries__next-button f-headline--s"
                to={
                  CATEGORY_ROUTE.path?.replace(
                    ':interestTitle',
                    INTEREST_URL.NZf7cr
                  ) as string
                }
              >
                {t('seeMore')}
                <Icon type={IconTypes.arrowRight} />
              </FotoscapeLink>
            </div>

            <div className="main-galleries__interest-content">
              {!!travelList.length &&
                availableTravelList.map(
                  ({ uid, previews, brandLogo, summary, title, interests }) => {
                    return (
                      <SingleCard
                        key={uid}
                        link={LOOKBOOK_ROUTE.path
                          .replace(':id', uid)
                          .replace(':title?', getWithOutSpecialChars(title.en))}
                        title={getTranslation(title)}
                        description={getTranslation(summary)}
                        descriptionLineCount={2}
                        logoURL={brandLogo}
                        imgList={previews}
                        interest={getInterestName(interests[0])}
                        interestId={interests[0]}
                        uid={uid}
                      />
                    );
                  }
                )}
            </div>

            <div
              className="main-galleries__interest-content-ad"
              id="ad_unit_travel"
            ></div>
          </Lazy>

          <Lazy>
            <section className="main-galleries__calllery-wrapper">
              <h2 className="home__title f-headline--xxxxl">
                {t('popularGalleries')}
              </h2>

              <div className="main-galleries__carousel-wrapper">
                <InterestCarousel
                  isSmallView={true}
                  isMobile={isMobile}
                  itemList={popularList}
                  renderItem={({ previews, title, brandLogo, uid, interests }) => {
                    return (
                      <SingleCard
                        interestId={interests[0]}
                        uid={uid}
                        title={getTranslation(title)}
                        logoURL={brandLogo}
                        imgList={previews}
                        link={LOOKBOOK_ROUTE.path
                          ?.replace(':id', uid)
                          .replace(
                            ':title?',
                            getWithOutSpecialChars(title?.en)
                          )}
                      />
                    );
                  }}
                />
              </div>
            </section>
          </Lazy>
        </section>

        <Lazy>
          <section className="home__interests">
            <h2 className="home__title f-headline--xxxxl">
              {t('discoverTopicsThatInterestYou')}
            </h2>

            <InterestGallery offsetTop={150} interestList={interestList} />

            <FotoscapeLink
              className="home__interests-button label--m"
              to={INTERESTS_ROUTE.path as string}
            >
              {t('more')}
            </FotoscapeLink>
          </section>
        </Lazy>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
