import { createContext } from 'react';
import FeatureFlagsConfig from '../../../../featureflags/fotoscapes/FeatureFlagsConfig.json';

export interface IConfigOption {
  utm_source: string[];
}

interface IAutoplayConfig extends IConfigOption {
  type: string[];
  minLength: number;
}

export interface IFeatureConfig {
  autoscroll: IAutoplayConfig;
  interstitialAd1Position: IConfigOption;
  interstitialAd2Position: IConfigOption;
  lookBookPlayersHeader: IConfigOption;
  coverSlideLargeAd: IConfigOption;
  downloadBlock: IConfigOption;
  isInfinitySlider: IConfigOption;
  emailSubscribe: IConfigOption;
}

interface IFeatureConfigContext {
  config: IFeatureConfig;
  utmSource: string;
}

export const FeatureConfigContext = createContext<IFeatureConfigContext>(
  FeatureFlagsConfig satisfies IFeatureConfig
);
