import React, { useEffect } from 'react';
import { Location, Routes, useLocation } from 'react-router-dom';
import i18next from 'i18next';

import { getRouter } from './utils/router';
import { updateUserVisitsData } from './utils';
import { LOOKBOOK_PARAMETERS_TO_COPY } from './pages/Root/pages/Lookbook/contants';
import { logPageViewMetric } from './tracing/events.ts';

import './index.scss';
import { momentRoutes } from './moment/routes/router-with-moment-v2.tsx';
import { BridgeProvider } from './moment/bridge/bridge-provider.tsx';
import bridgeEventPipe from './moment/bridge/bridge-event-pipe.ts';

bridgeEventPipe.init();

function App() {
  const location = useLocation();

  useEffect(() => {
    updateUserVisitsData();
  }, []);

  const handleLocationChange = (loc: Location) => {
    const pathName = loc.pathname;
    let eventData: Record<string, string> = {
      page_path: pathName,
      language: i18next.language,
    };
    // add needed querystring params to event dat
    try {
      const params = new URLSearchParams(loc.search);
      for (const [key, value] of params) {
        if (LOOKBOOK_PARAMETERS_TO_COPY.includes(key)) {
          eventData[key] = value;
        }
      }
    } catch (e) {
      console.error(`Error parsing querystring params`, e);
    }
    // if lookbook or stories, parse info from URL
    if (pathName.startsWith('/lookbook/')) {
      eventData = {
        ...eventData,
        primary_interest: pathName.split('/')[2],
        page_path: '/lookbook',
        page_number: loc.hash,
      };
    } else if (pathName.startsWith('/stories/')) {
      eventData = {
        ...eventData,
        primary_interest: pathName.split('/')[2],
        page_path: '/stories',
      };
    }
    logPageViewMetric(eventData);
  };

  useEffect(() => {
    handleLocationChange(location);
  }, [location]);

  return (
    <BridgeProvider>
    <div className="fotoscape-app">
      <div id="dialog" data-testid="dialog" />

      <Routes>
        {momentRoutes.map((item) => {
          return getRouter(item);
        })}
      </Routes>
    </div>
    </BridgeProvider>
  );
}

export default App;
