import React, { FC } from 'react';

import Image from '../../../../components/Image/Image';
import fotoSCAPEImg from '/assets/images/FotoSCAPE.svg?url';
import fotoSCAPEDownload from '/assets/images/googleBtn.svg?url';
import { DOWNLOAL_APP_LINK } from './contants';
import { IDownloadProps } from './interfaces';
import CloseButton from './CloseButton';
import FotoscapeLink from '../../../../components/FotoscapeLink/FotoscapeLink';

import './index.scss';

const Download: FC<IDownloadProps> = ({ onClose, onSuccess }) => {
  return (
    <div className="download" data-testid="downloadBlock">
      <CloseButton onClick={onClose} />

      <div className="download__main">
        <div className="download__header">Download</div>
        <Image alt="logo" className="download__logo" src={fotoSCAPEImg} />
        <div className="download__title">Everyday lifestyle</div>
        <div className="download__subtitle">for curious people</div>
        <FotoscapeLink
          to={DOWNLOAL_APP_LINK}
          onClick={onSuccess}
          className="download__google-button"
        >
          <Image src={fotoSCAPEDownload} alt="download" />
        </FotoscapeLink>
      </div>
    </div>
  );
};

export default Download;
