import React from 'react';
import { DisplayAd } from '../../components/DisplayAd/DisplayAd.tsx';
import TouchableOpacityLinker from '../../../components/TouchableOpacityLinker/TouchableOpacityLinker.tsx';
import FotoscapeLink from '../../../components/FotoscapeLink/FotoscapeLink.tsx';
import { adjustFotoscapesLink, EnhancedLightboxItem, LightboxItem } from '../../net/softbox-types.ts';
import { IInterestResponse } from '../../../pages/Root/services/api/interest/interfaces.ts';
import eventBus from '../../../utils/eventsbus.ts';
import { BridgeInternalEvents } from '../../bridge/bridge-internal-event-defs.ts';
import { LayoutConfigurationPage } from '../LayoutConfiguration/layout-configuration-factory.ts';
import { createFeedComponent } from './FeedComponentFactory.tsx';
import { createHeaderComponent } from './HeaderComponentFactory.tsx';
import { useFotoscapeNavigate } from '../../../hooks/navigation.ts';
import Footer from '../../components/Footer/Footer.tsx';
import Outbrain from '../../components/Outbrain/Outbrain.tsx';

interface FeedProps {
  newsItems: EnhancedLightboxItem[];
  interests: IInterestResponse;
  configuration: LayoutConfigurationPage;
}

export const FeedBuilder: React.FC<FeedProps> = ({ newsItems, interests, configuration }) => {
  let adUnitIndex = 0
  const nav = useFotoscapeNavigate()

  if (newsItems.length === 0) return <p>No news available.</p>;

  const renderFeed = () => {
    const feedComponents = configuration.layoutConfiguration.map((slot) => {
      if (slot.type === 'ad') {
        adUnitIndex++;
        const adType = adUnitIndex === 1 ? 'top' : 'bot';
        return (<DisplayAd adUnitId={`feed${adUnitIndex}`} adType={adType}/>)
      } else if (slot.type === 'item') {
        const { component, item } = createFeedComponent(slot, newsItems, interests, TouchableOpacityLinker, (item) =>  {return {
          ClickerEl: FotoscapeLink,
          clickerElProps: {
          to: adjustFotoscapesLink(item.linkToContent),
            className: "moment-link",
            onClick: onItemClicked(item)
        }
        }})
        if (item) {
          return (
              <>{component}</>
          )
        } else {
          return <>{component}</>
        }
      } else if (slot.type === 'header') {
        return createHeaderComponent(slot)
      } else if (slot.type === 'footer') {
          return <Footer />
      } else if (slot.type === 'outbrain') {
        return <Outbrain></Outbrain>
      }
      return null; // Skip if no news item is available
    }).filter(component => {
      return component !== null
    }); // Remove null entries

    return feedComponents;
  };

  return <div className="news-feed">{renderFeed()}</div>;
};


const onItemClicked = (item: LightboxItem) => () => {
  eventBus.emit(BridgeInternalEvents.LINK_CLICK, {
    interestId: item.interests[0],
    id: item.uid,
  })
};
