import { routes } from '../../router/router.tsx';
import { buildMomentRoute, createPathType, MomentRoute } from './types.ts';
import { FeedLayout } from '../layouts/MainFeed/FeedLayout.tsx';
import { generateFetchFeedQuery } from '../net/feed-fetch-query.ts';
import { TesterPage } from '../bridge/bridge-tester.tsx';
import { FeedQueryResult } from '../net/softbox-types.ts';
import { feedConfigurationPages } from '../layouts/LayoutConfiguration/layout-configuration-factory.ts';
import DoNotSell from '../layouts/DoNotSell/DoNotSell.tsx';
import ManagePrivacy from '../layouts/ManagePrivacy/ManagePrivacy.tsx';
import DataRequestForm from '../layouts/DataRequestForm/DataRequestForm.tsx';
import OptInLayout from '../layouts/OptIn/OptIn.tsx';

const feedRoutes = feedConfigurationPages();

const feedMomentRoutes = feedRoutes.map((feedRoute) => {
  const { schedule, path, title, description } = feedRoute;
  const output: MomentRoute<Record<string, string>, FeedQueryResult> = {
    path: createPathType<Record<string, string>>(path),
    title,
    description,
    element: (
      <FeedLayout
        queryFn={generateFetchFeedQuery(schedule)}
        schedule={schedule}
        configuration={feedRoute}
      />
    ),
    prefetchQuery: generateFetchFeedQuery(schedule),
    feedRoute,
  };
  return buildMomentRoute(output);
});

export const BRIDGE_TEST_ROUTE: MomentRoute<Record<string, string>, void> = {
  path: createPathType<Record<string, string>>('/bridge-tester-page'),
  title: 'Bridge Tester',
  description: 'Moment',
  element: <TesterPage />,
  prefetchQuery: undefined,
};

export const OPT_IN_ROUTE: MomentRoute<Record<string, string>, void> = {
  path: createPathType<Record<string, string>>('moment-opt-in'),
  title: 'Moment',
  description: 'Moment',
  element: <OptInLayout />,
  prefetchQuery: undefined,
};

export const DO_NOT_SELL_ROUTE: MomentRoute<Record<string, string>, void> = {
  path: createPathType<Record<string, string>>('do-not-sell'),
  title: 'Moment | Do not sell my information',
  description: 'Moment | Do not sell my information',
  element: <DoNotSell />,
  prefetchQuery: undefined,
};

export const MANAGE_PRIVACY_ROUTE: MomentRoute<Record<string,string>, void> = {
  path: createPathType<Record<string,string>>('manage-privacy'),
  title: 'Moment | Manage Privacy',
  description: 'Moment | Manage Privacy',
  element: (<ManagePrivacy />),
};

export const DELETE_DATA_REQ_ROUTE: MomentRoute<Record<string,string>, void> = {
  path: createPathType<Record<string,string>>('manage-privacy-delete-data-req'),
  title: 'Moment | Manage Privacy - Request to delete my data',
  description: 'Moment | Manage Privacy  - Request to delete my data',
  element: (<DataRequestForm mode={"delete"} />),
};

export const GIVE_DATA_REQ_ROUTE: MomentRoute<Record<string,string>, void> = {
  path: createPathType<Record<string,string>>('manage-privacy-give-data-req'),
  title: 'Moment | Manage Privacy - Request to give my data',
  description: 'Moment | Manage Privacy  - Request to give my data',
  element: (<DataRequestForm mode={"give"} />),
};

export const momentRoutes = [
  ...feedMomentRoutes,
  buildMomentRoute(BRIDGE_TEST_ROUTE),
  buildMomentRoute(DO_NOT_SELL_ROUTE),
  buildMomentRoute(MANAGE_PRIVACY_ROUTE),
  buildMomentRoute(DELETE_DATA_REQ_ROUTE),
  buildMomentRoute(GIVE_DATA_REQ_ROUTE),
  buildMomentRoute(OPT_IN_ROUTE),
  ...routes,
];
