import { IInterestResponse } from '../../pages/Root/services/api/interest/interfaces.ts';

export type LightboxStatus = {
  http: number;
  lkbk: string;
};

export type LightboxLanguageText = {
  en: string;
  es?: string;
};

export type LightboxInterest = {
  name: LightboxLanguageText;
  weight: number;
};

export type LightboxInterests = Record<string, LightboxInterest>;

export type LightboxImage = {
  link: string;
  width: number;
  height: number;
};

export type LightboxPreview = LightboxImage & {
  aspect: string;
};

export type LightboxKB = {
  startX: number;
  startY: number;
  startB: number;
  endX: number;
  endY: number;
  endB: number;
};

export type LightboxItem = {
  title: LightboxLanguageText;
  summary: LightboxLanguageText;
  link: string;
  sourceLink: string;
  uid: string;
  lbtype: string;
  interests: string[];
  images: LightboxImage[];
  previews: LightboxPreview[];
  kb: LightboxKB;
  owner: string;
  ownerUID: string;
  brandLogo: string;
  brandLogoDark: string;
  numImages: number;
  boost: number;
  promote: boolean;
  publishOn: string;
  scheduledOn: string;
};

export class EnhancedLightboxItem implements LightboxItem {
  constructor(lt: LightboxItem) {
    Object.assign(this, lt);
  }

  title!: LightboxLanguageText;
  summary!: LightboxLanguageText;
  link!: string;
  sourceLink!: string;
  uid!: string;
  lbtype!: string;
  interests!: string[];
  images!: LightboxImage[];
  previews!: LightboxPreview[];
  kb!: LightboxKB;
  owner!: string;
  ownerUID!: string;
  brandLogo!: string;
  brandLogoDark!: string;
  numImages!: number;
  boost!: number;
  promote!: boolean;
  publishOn!: string;
  scheduledOn!: string;

  get linkToContent(): string {
    switch (this.lbtype) {
      case 'link':
        return this.link;
      case 'article':
        return this.link;
      case 'springboard':
        return this.sourceLink;
    }
    return this.link;
  }
}

export type LightboxRootObject = {
  status: LightboxStatus;
  ver: string;
  interests: LightboxInterests;
  items: LightboxItem[];
};

export type FeedQueryResult = {
  news: LightboxRootObject;
  interests: IInterestResponse;
}

export const adjustFotoscapesLink = (link: string) => {
  try {
    const regex = /^https?:\/\/(staging.)?(fotoscapes|get-moment).com(\/.*)$/;
    const match = link.match(regex);
    if (match) {
      const path = match[3];
      return `${path}`;
    }
  } catch (_:unknown) { /* empty */ }
  return link;
}
