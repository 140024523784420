import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { FeatureConfigContext, IConfigOption } from '../FeatureConfigContext';
import { ALL_PLATFORMS, FeatureFlags, WITHOUT_UTM } from './constants';
import { LOOKBOOK_ROUTE_PATH } from '../../../router/constants.ts';

export const useFeature = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [isAutoplayAvailableByUtmSource, setIsAutoplayAvailableByUtmSource] =
    useState(false);
  const [
    isInterstitialAd1PositionAvailable,
    setIsInterstitialAd1PositionAvailable,
  ] = useState(false);
  const [
    isInterstitialAd2PositionAvailable,
    setIsInterstitialAd2PositionAvailable,
  ] = useState(false);
  const [isLookBookPlayerHeaderAvailable, setIsLookBookPlayerHeaderAvailable] =
    useState(false);
  const [isCoverSlideHasLargeAd, setIsCoverSlideHasLargeAd] = useState(false);
  const [isDownloadAvailable, setIsDownloadAvailable] = useState(false);
  const [isHeaderSubMenuAvailable, setIsHeaderSubMenuAvailable] =
    useState(false);
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [isInfinitySliderAvailable, setIsInfinitySliderAvailable] =
    useState(false);
  const [isEmailSubscribeAvailable, setEmailSubscribeAvailable] =
      useState(false);

  const { config, utmSource } = useContext(FeatureConfigContext);

  const checkIfFeatureAvailable = useCallback(
    (config: IConfigOption) => {
      if (!config?.utm_source) {
        return false;
      }
      return (
        config.utm_source.includes(ALL_PLATFORMS) ||
        config.utm_source.includes(utmSource) ||
        (!utmSource && config.utm_source.includes(WITHOUT_UTM))
      );
    },
    [utmSource]
  );

  useEffect(() => {
    console.debug(`Config: ${JSON.stringify(config)}`);
    if (config) {
      setIsAutoplayAvailableByUtmSource(
        checkIfFeatureAvailable(config.autoscroll)
      );
      setIsInterstitialAd1PositionAvailable(
        checkIfFeatureAvailable(config.interstitialAd1Position)
      );
      setIsInterstitialAd2PositionAvailable(
        checkIfFeatureAvailable(config.interstitialAd2Position)
      );
      setIsCoverSlideHasLargeAd(
        checkIfFeatureAvailable(config.coverSlideLargeAd)
      );
      setIsInfinitySliderAvailable(
        checkIfFeatureAvailable(config.isInfinitySlider)
      );
      setEmailSubscribeAvailable(
        checkIfFeatureAvailable(config.emailSubscribe)
      )
    }
  }, [config, checkIfFeatureAvailable]);

  useEffect(() => {
    const isLookBookPage =
      location.pathname.split('/')[1] === LOOKBOOK_ROUTE_PATH.split('/')[1];

    setIsLookBookPlayerHeaderAvailable(
      (checkIfFeatureAvailable(config.lookBookPlayersHeader) &&
        isLookBookPage) ||
        !isLookBookPage
    );
  }, [location, checkIfFeatureAvailable, config.lookBookPlayersHeader]);

  useEffect(() => {
    const isAvailable =
      checkIfFeatureAvailable(config.downloadBlock) &&
      searchParams.get(FeatureFlags.downloadOverlay) === 'true';

    setIsDownloadAvailable(isAvailable);
    setIsVideoAvailable(searchParams.get(FeatureFlags.videoBlock) === 'true');
  }, [config, checkIfFeatureAvailable, searchParams]);

  useEffect(() => {
    const isLookBookPage =
      location.pathname.split('/')[1] === LOOKBOOK_ROUTE_PATH.split('/')[1];

    setIsHeaderSubMenuAvailable(!isLookBookPage);
  }, [location]);

  const getIsAutoplayAvailable = useCallback(
    (type: string, length: number) => {
      if (isAutoplayAvailableByUtmSource) {
        return (
          config.autoscroll.type?.includes(type) &&
          length >= config.autoscroll.minLength
        );
      }

      return false;
    },
    [isAutoplayAvailableByUtmSource, config]
  );

  return {
    getIsAutoplayAvailable,
    isInterstitialAd1PositionAvailable,
    isInterstitialAd2PositionAvailable,
    isLookBookPlayerHeaderAvailable,
    isCoverSlideHasLargeAd,
    isDownloadAvailable,
    isHeaderSubMenuAvailable,
    isVideoAvailable,
    isInfinitySliderAvailable,
    isEmailSubscribeAvailable,
  };
};
