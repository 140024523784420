import {IAdMapData} from "../../../pages/Root/pages/HomeRoot/interfaces.ts";

export const DISPLAY_AD_DESKTOP_AD_MAP_DATA: IAdMapData = {
    screenSize: [1193, 0],
    adSizeList: [[300, 250]],
};

export const DISPLAY_AD_TABLED_AD_MAP_DATA: IAdMapData = {
    screenSize: [970, 0],
    adSizeList: [
        [728, 90],
        [300, 50],
        [320, 50],
    ],
};

export const DISPLAY_AD_TABLED_SMALL_AD_MAP_DATA: IAdMapData = {
    screenSize: [684, 0],
    adSizeList: [
        [300, 50],
        [320, 50],
    ],
};

export const DISPLAY_AD_MOBILE_AD_MAP_DATA: IAdMapData = {
    screenSize: [320, 0],
    adSizeList: [
        [300, 250],
        [300, 50],
        [320, 50],
    ],
};

export const DEFAULT_DIMENSIONS = [
    [300, 250],
    [320, 100],
    [320, 50]
]
export const DEFAULT_DIMENSIONS_STICKY = [
    [300, 50],
    [320, 50]
]

export const DEFAULT_DIMENSIONS_FLOATING = [
    [0, 0],
    [320, 50]
]
