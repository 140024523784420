import { FC, useEffect, useState } from 'react';

import FotoscapeLink from '../../../components/FotoscapeLink/FotoscapeLink.tsx';
import StorySmall from '../StorySmall/StorySmall.tsx';
import { IImageData } from '../../../pages/Root/services/api/interfaces.ts';
import OutbrainSkeleton from '../OutbrainSkeleton/OutbrainSkeleton.tsx';
import InViewport from '../InViewport/InViewport.tsx';
import { OUTBRAINE_MIN_VIEW_TIME, OUTBRAINE_MIN_VIEW_PART } from './constants.ts';
import { IOutbrainDocument, IOutbrainResponse } from './interfaces.ts';
import TouchableOpacityLinker from '../../../components/TouchableOpacityLinker/TouchableOpacityLinker.tsx';

import './Outbrain.scss';

const Outbrain: FC = () => {
  const [docDataList, setDocDataList] = useState<IOutbrainDocument[]>([]);
  const [, setBlockViewTime] = useState<
    Record<string, { date: number; isEmitted: boolean }>
  >({});

  const callBackFuncObj = (data: IOutbrainResponse) => {
    setDocDataList(data.doc.slice(0, 4));
  };

  const outbrainCallbackError = (e: IOutbrainDocument) => {
    console.error(e);
  };

  const getConfig = () => {
    const reqDat = {
      permalink: 'http://www.mobileposse.com/VRZ/MOMENT/EN',
      widgetId: 'JS_9',
      installationKey: 'MOBIL10GC607HEAPB660KCI89',
    };

    window.OBR?.extern.callRecs(reqDat, callBackFuncObj, outbrainCallbackError);
  };

  const onItemInView = (outbrainDocItem: IOutbrainDocument) => {
    setBlockViewTime((prevState) => {
        return {
          ...prevState,
          [outbrainDocItem.pc_id]: {
            date: new Date().getTime(),
            isEmitted: prevState[outbrainDocItem.pc_id]?.isEmitted || false,
          },
        };
    });

    setTimeout(() => {
      setBlockViewTime((prevState) => {
          const currentItem = prevState[outbrainDocItem.pc_id];

          if (currentItem?.date && !currentItem.isEmitted) {
            const isShouldEmit =
              new Date().getTime() - new Date(currentItem?.date).getTime() >=
              OUTBRAINE_MIN_VIEW_TIME;

            if (isShouldEmit) {
              window.OBR.extern.callViewed(outbrainDocItem['on-viewed'][0]);
            }

            return {
              ...prevState,
              [outbrainDocItem.pc_id]: {
                ...currentItem,
                isEmitted: isShouldEmit,
              },
            };
          }

        return prevState;
      });
    }, OUTBRAINE_MIN_VIEW_TIME);
  };

  const onHidden = (outbrainDocItem: IOutbrainDocument) => {
    setBlockViewTime((prevState) => {
      if (prevState[outbrainDocItem.pc_id]) {
        return {
          ...prevState,
          [outbrainDocItem.pc_id]: {
            ...prevState[outbrainDocItem.pc_id],
            date: 0,
          },
        };
      }

      return prevState;
    });
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <div className="outbrain" style={{ position: 'relative' }}>
      <div className="outbrain__title">Sponsored by Outbrain</div>

      {docDataList.length ? (
        docDataList.map((item) => {
          const { pc_id, url, content, source_name, thumbnail } = item;
          const image: IImageData = {
            url: thumbnail.url,
            width: thumbnail.width,
            height: thumbnail.height,
          };

          return (
            <InViewport
              threshold={OUTBRAINE_MIN_VIEW_PART}
              onView={() => onItemInView(item)}
              onHidden={() => onHidden(item)}
            >
              <TouchableOpacityLinker
                ClickerEl={FotoscapeLink}
                clickerElProps={{ to: url, className: "moment-link" }}>
                <StorySmall
                  title={content}
                  category={source_name}
                  imageList={[image]}
                ></StorySmall>
              </TouchableOpacityLinker>
            </InViewport>
          );
        })
      ) : (
        <OutbrainSkeleton />
      )}
    </div>
  );
};

export default Outbrain;
