
export type InternalEvent = {
  name: string;
  extras: Record<string, string>;
  timestamp: number;
}

export type EventCallback = (e: InternalEvent) => void

export interface EventBus {
  events: Record<string, EventCallback[]>;

  on(eventName: string, callback: EventCallback): void;

  off(eventName: string, callback: EventCallback): void;

  emit(eventName: string, extras?: Record<string, string>): void;
}

const eventBus: EventBus = {
  events: {} as Record<string, EventCallback[]>,

  // Register a listener for an event
  on(eventName: string, callback: EventCallback) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);
  },

  // Remove a listener for an event
  off(eventName: string, callback: EventCallback) {
    if (this.events[eventName]) {
      this.events[eventName] = this.events[eventName].filter((cb) => {
        return cb !== callback;
      });
    }
  },

  // Emit an event with data to all listeners
  emit(eventName: string, extras?: Record<string, string>) {
    if (this.events[eventName]) {
      this.events[eventName].forEach((callback) => {
        return callback({
          name: eventName,
          extras: extras || {},
          timestamp: Date.now(),
        });
      });
    }
  },
};

export default eventBus;
