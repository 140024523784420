import { getApiMetrics, getFullURL } from './utils.ts';
import {
  logFotoscapeApiStatusMetric,
  logFotoscapesApiResponseTimeMetric,
} from '../../../../tracing/events.ts';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

export abstract class API {

  public abstract get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  public abstract put<T = any>(url: string, data: any, config?: AxiosRequestConfig): Promise<T>

  protected initInterceptors(instance: AxiosInstance) {
    instance.interceptors.request.use((request) => {
      return {
        ...request,
        meta: {
          requestStartedAt: new Date().getTime(),
        },
      };
    });

    instance.interceptors.response.use(
      async (response) => {
        // console.debug(
          // `Got response from ${getFullURL(response)} status ${response.status}`
        // );

        const { url, responseCode, responseTime } = getApiMetrics(
          response,
          (response.config as any).meta.requestStartedAt
        );
        logFotoscapeApiStatusMetric({ url, responseCode });
        logFotoscapesApiResponseTimeMetric(responseTime, { url });
        return response.data;
      },
      (error) => {
        console.debug(
          `Got response from ${getFullURL(error.response)} status ${
            error.response.status
          }`
        );

        const { url, responseCode, responseTime } = getApiMetrics(
          error.response,
          (error.response.config as any).meta.requestStartedAt
        );
        logFotoscapeApiStatusMetric({ url, responseCode });
        logFotoscapesApiResponseTimeMetric(responseTime, { url });
        return error;
      }
    );
  }
}
