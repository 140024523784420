import React, { PropsWithChildren } from 'react';
import './RowMultiplierContainer.scss';

type RowMultipleContainerProps = PropsWithChildren

const RowMultipleContainer: React.FC<RowMultipleContainerProps> = ({ children }) => {
  return (
    <div className="column-container">
      {children}
    </div>
  );
};

export default RowMultipleContainer;
