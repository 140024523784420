import React, { useState, useEffect } from 'react';
import { useBridge } from './bridge-provider.tsx';
import { BridgeAction, BridgeData, BridgeEvent } from './client-bridge-defs.ts';
import { addBridgeEventListener, removeBridgeEventListener } from './client-bridge.ts';

export const TesterPage: React.FC = () => {
  const bridge = useBridge(); // Always returns a valid bridge (mock or real)
  const [supportedActions, setSupportedActions] = useState<string[]>([]);
  const [getDataOutput, setGetDataOutput] = useState<string>('');
  const [selectedAction, setSelectedAction] = useState<BridgeAction | ''>('');
  const [receivedEvents, setReceivedEvents] = useState<string[]>([]);
  useEffect(() => {
    const actions = bridge.getSupportedActions();
    if (Array.isArray(actions)) {
      setSupportedActions(actions);
    } else {
      alert(`bridge.getSupportedActions returned a non-array object - ${actions}`);
    }
  }, [bridge]);

  useEffect(() => {
    const handleBridgeEvent = (eventName: string, extras: string) => {
      setReceivedEvents(prev => [...prev, `Event: ${eventName}, Extras: ${extras}`]);
    };
    addBridgeEventListener(handleBridgeEvent);
    return () => removeBridgeEventListener(handleBridgeEvent);
  }, []);

  const handleGetData = () => {
    const data = bridge.getData(BridgeData.AD_DATA);
    setGetDataOutput(JSON.stringify(data, null, 2));
  };

  const handleSaveData = () => {
    bridge.saveData('testTag', { key: 'value' });
    alert('saveData called with tag "testTag" and payload { key: "value" }');
  };

  const handlePerformAction = () => {
    if (selectedAction) {
      bridge.performAction(selectedAction, { actionPayload: 'test' });
      alert(`Performed action: ${selectedAction}`);
    } else {
      alert('Please select an action');
    }
  };

  const handleOnEvent = () => {
    bridge.onEvent(BridgeEvent.AD_LOAD, JSON.stringify({ adId: 'mock-123' }));
    alert('Triggered event: AD_LOAD');
  };

  const simulateAndroidEvent = () => {
    const dtcmWeb = (window as any).DTCMWeb;
    if (dtcmWeb && dtcmWeb.onBridgeEvent) {
      dtcmWeb.onBridgeEvent('MOCK_EVENT', JSON.stringify({ source: 'android' }));
    } else {
      alert('DTCMWeb not available - simulating event failed');
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', color: 'white' }}>
      <h1>Bridge Tester Page</h1>

      {/* Bridge Version */}
      <section>
        <h2>Bridge Version</h2>
        <p>{bridge.getBridgeVersion()}</p>
      </section>

      {/* Supported Actions */}
      <section>
        <h2>Supported Actions</h2>
        <ul>
          {supportedActions.map(action => (
            <li key={action}>{action}</li>
          ))}
        </ul>
      </section>

      {/* getData Section */}
      <section>
        <h2>Get Data</h2>
        <button onClick={handleGetData}>Call getData(AD_DATA)</button>
        <pre style={{ background: '#2f2424', padding: '10px', marginTop: '10px' }}>
          {getDataOutput || 'No data yet'}
        </pre>
      </section>

      {/* saveData Trigger */}
      <section>
        <h2>Save Data</h2>
        <button onClick={handleSaveData}>Call saveData()</button>
      </section>

      {/* performAction */}
      <section>
        <h2>Perform Action</h2>
        <select
          value={selectedAction}
          onChange={e => setSelectedAction(e.target.value as BridgeAction)}
          style={{ marginRight: '10px' }}
        >
          <option value="">Select Action</option>
          {supportedActions.map(action => (
            <option key={action} value={action}>{action}</option>
          ))}
        </select>
        <button onClick={handlePerformAction}>Perform Action</button>
      </section>

      {/* onEvent Trigger */}
      <section>
        <h2>Trigger Event</h2>
        <button onClick={handleOnEvent}>Trigger AD_LOAD Event</button>
      </section>

      {/* Simulate Android Event */}
      <section>
        <h2>Simulate Android Event</h2>
        <button onClick={simulateAndroidEvent}>Simulate Event from Android</button>
        <div style={{ marginTop: '10px' }}>
          <h3>Received Events</h3>
          {receivedEvents.length > 0 ? (
            <ul>
              {receivedEvents.map((event, index) => (
                <li key={index}>{event}</li>
              ))}
            </ul>
          ) : (
            <p>No events received yet</p>
          )}
        </div>
      </section>
    </div>
  );
};
