import {IAdTagResponce} from "../../pages/Root/services/api/ads/interfaces.ts";
import {DEFAULT_BRAND_GROUP_ID} from "../../pages/Root/pages/HomeRoot/constants.ts";
import {LRUCache} from "lru-cache";

const DEFAULT_CKEY = 'fb529d256155b9c6';

const cache = new LRUCache<string, any>({
    max: 1024,
    ttl: 5 * 60 * 1000,
});

export const generateFetchAdQuery = (brandGroupId: string = DEFAULT_BRAND_GROUP_ID, utmSource: string= 'no source') => {
    return async () => {
        const key = JSON.stringify({
            brandGroupId,
            utmSource,
        })

        if (cache.has(key)) {
            return cache.get(key);
        }
        const res = await fetch(
            `https://fotoscapes.com/wp/v1/brandgroup/${brandGroupId}/adtags?ckey=${DEFAULT_CKEY}&param=utm_source&value=${utmSource}`
        );

        const output:IAdTagResponce = await res.json();
        if (res.ok && output.adTag) {
            cache.set(key, output);
        }

        return output satisfies IAdTagResponce
    };
};

export const fetchAdQuery = (brandGroupId: string = DEFAULT_BRAND_GROUP_ID, utmSource: string= 'no source') => {
    return async () => {
        const res = await fetch(
            `https://fotoscapes.com/wp/v1/brandgroup/${brandGroupId}/adtags?ckey=${DEFAULT_CKEY}&param=utm_source&value=${utmSource}`
        );

        return await res.json() satisfies IAdTagResponce
    };
};