import SectionHeader from '../../components/SectionHeader/SectionHeader.tsx';
import { LayoutConfiguration } from '../LayoutConfiguration/layout-configuration-factory.ts';

export const createHeaderComponent = (slot: LayoutConfiguration) => {
  switch (slot.component) {
    case 'SectionHeader':
      return <SectionHeader text={slot.settings?.text || ''} />;
    default:
      return <></>;
  }
};
